import React, { useContext } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { GridColumn, H3, H4, GridRow, Footnote, withDefaultMedia } from "notes";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { StoreContext, Loader } from "Components";
import { Reward } from "./Reward";

export const EventRewards = withDefaultMedia(
  ({ matches, eventId, rewards, pastReward, ...props }) => {
    const { Store } = useContext(StoreContext);
    const [event, loading, error] = useDocumentDataOnce(
      Store.firestore().doc(`events/${eventId}`)
    );

    if (loading) {
      return (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      );
    }

    const eventDate = DateTime.fromJSDate(event.startDate.toDate());
    const formattedDate = eventDate.toFormat("MMM d, yyyy");

    return (
      <Container past={pastReward} noShrink {...props}>
        <Header past={pastReward} yCenter>
          <ArtistImage
            background={`${process.env.REACT_APP_ASSETS_PATH}/${event.assets?.preshowBanner?.path}`}
          />
          <Details stretch>
            <H3>{event.title}</H3>
            <Footnote>
              {event.subtitle} {matches.small ? <br /> : "•"} {formattedDate}
            </Footnote>
          </Details>
          {rewards[0].supportLevel?.selectedIcon && (
            <SupportLevel color={rewards[0].supportLevel.textColor}>
              <SupportImage
                src={`${process.env.REACT_APP_ASSETS_PATH}/${rewards[0].supportLevel.selectedIcon}`}
              />
              <SupportTitle
                weight={rewards[0].supportLevel.titleWeight}
                color={rewards[0].supportLevel.titleColor}
              >
                {rewards[0].supportLevel.title}
              </SupportTitle>
            </SupportLevel>
          )}
        </Header>
        <RewardsContainer past={pastReward}>
          {rewards.map((reward) => (
            <Reward item={reward} />
          ))}
        </RewardsContainer>
      </Container>
    );
  }
);

const LoadingContainer = styled(GridColumn)`
  position: relative;
  flex-grow: 1;
`;

const Container = styled(GridColumn)`
  background-color: #ffffff;
  position: relative;
  & + & {
    margin-top: 40px;
  }
`;

const Details = styled(GridColumn)`
  ${H3} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 900;
  }
  & > ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${H3} {
      text-align: center;
      margin-bottom: 8px;
    }
    & > ${Footnote} {
      text-align: center;
      margin-bottom: 16px;
    }
  }
`;

const Header = styled(GridRow)`
  border: ${(props) =>
    props.past ? 0 : `1px solid ${props.theme.palette.gray.lighter}`};
  ${(props) =>
    props.past &&
    `border-bottom: 1px solid ${props.theme.palette.gray.lightest}`};
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding: ${(props) => (props.past ? "24px 24px 24px 0" : "24px 40px")};
  @media only screen and ${(props) => props.theme.media.small} {
    border: 1px solid ${(props) => props.theme.palette.gray.lighter};
    flex-direction: column;
    padding: 24px;
  }
`;

const ArtistImage = styled(GridRow)`
  background-image: url(${(props) => props.background});
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
  flex-shrink: 0;
  margin-right: 24px;
  width: 40px;
  height: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const RewardsContainer = styled(GridColumn)`
  border-right: ${(props) =>
    props.past ? 0 : `1px solid ${props.theme.palette.gray.lighter}`};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-left: ${(props) =>
    props.past ? 0 : `1px solid ${props.theme.palette.gray.lighter}`};
  border-radius: ${(props) => (props.past ? 0 : "0 0 4px 4px")};
  padding: 24px 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    border: 1px solid ${(props) => props.theme.palette.gray.lighter};
    border-top: none;
    padding: 24px;
  }
`;

const SupportLevel = styled(GridRow)`
  align-items: center;
  background-color: ${(props) => props.theme.palette.orange.primary};
  border-radius: 14px;
  color: ${(props) => props.color};
  height: 28px;
  padding: 0 12px;
`;

const SupportImage = styled.img`
  width: 16px;
  height: 16px;
`;

const SupportTitle = styled(H4)`
  color: ${(props) => props.color};
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  margin-left: 8px;
`;
