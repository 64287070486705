import React, { createContext, useContext, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useObject } from "react-firebase-hooks/database";
import { StoreContext } from "Components";

export const TimeContext = createContext();
export const TimeConsumer = TimeContext.Consumer;
export const TimeProvider = ({ children }) => {
  const { Store } = useContext(StoreContext);
  const [serverOffset, setServerOffset] = useState(0);
  const [userOffset, setUserOffset] = useState(0);
  const [localTime, setLocalTime] = useState(Date.now());
  const time = new DateTime.fromMillis(localTime + serverOffset + userOffset);

  useEffect(() => {
    Store.database()
      .ref("/.info/serverTimeOffset")
      .once("value")
      .then(
        function stv(data) {
          setServerOffset(data.val());
        },
        function (err) {
          return err;
        }
      );
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(Date.now());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <TimeContext.Provider value={{ time, setUserOffset }}>
      {children}
    </TimeContext.Provider>
  );
};
