import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Modal,
  H3,
  P,
  ButtonPrimary,
  ButtonSecondary,
  GridRow,
  GridColumn,
} from "notes";

export const UpdateEmail = ({ open, setOpen, email }) => {
  const history = useHistory();
  const handleClose = () => {
    setOpen({ open: false, email: "" });
  };

  return (
    <ModalStyle size="small" open={open} setOpen={handleClose}>
      <Container stretch>
        <H3>Update Email Address</H3>
        <P>
          Updating your email will require verification. Once verified you will
          be asked to log back in.
        </P>
      </Container>
      <Actions>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ButtonPrimary
          onClick={() => {
            history.push("/account/update/email", {
              email: email,
              prevPath: "/account",
            });
            handleClose();
          }}
        >
          Update and Verify
        </ButtonPrimary>
      </Actions>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  margin-top: 8.589vw;
  min-height: 282px;
  & > svg {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 112px;
    max-width: 296px;
    padding: 24px;
  }
`;

const Container = styled(GridColumn)`
  text-align: center;
  ${H3} {
    margin-bottom: 16px;
  }
`;

const Actions = styled(GridRow)`
  justify-content: space-between;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: stretch;
    flex-direction: column;
  }
`;

const CancelButton = styled(ButtonSecondary)`
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 8px;
    order: 2;
  }
`;
