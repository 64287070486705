import React, { useContext } from "react";
import styled from "styled-components";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { GridRow, GridColumn, P, H1, H4, ButtonPrimary, Footnote } from "notes";
import {
  HeaderSecondary,
  ModalContext,
  StoreContext,
  PaymentContext,
  FooterBlock,
  withProtectedRoute,
} from "Components";
import { CreditCard, Transaction } from "./Components";

export const Billing = withProtectedRoute(() => {
  const { user, Store } = useContext(StoreContext);
  const { toggleAddCardModal, toggleRemoveCardModal } = useContext(
    ModalContext
  );

  const { savedCards } = useContext(PaymentContext);

  const [transactions, loading, error] = useCollectionDataOnce(
    Store.firestore()
      .collection("transactions")
      .where("uid", "==", user?.uid)
      .orderBy("created", "desc")
  );

  if (loading || !user) {
    return null;
  }

  const attendedEvents = transactions.map(
    (transaction) => transaction.paymentItem.eventId
  );
  const uniqueEvents = [...new Set(attendedEvents)];

  let transactionsByEvent = [];

  uniqueEvents.forEach((eventId) => {
    let filteredT = transactions.filter(
      (item) => item.paymentItem.eventId === eventId
    );
    transactionsByEvent.push({ eventId: eventId, paymentItems: filteredT });
  });

  return (
    <Wrapper>
      <HeaderSecondary banner />
      <Cards>
        <Container>
          <PageHeader>
            <H1>Billing And Transactions</H1>
          </PageHeader>
          <Title>Credit Cards</Title>
          <GridColumn columns={4}>
            {savedCards.length > 0 ? (
              savedCards.map(({ brand, last4, exp_month, exp_year }) => (
                <CreditCard
                  brand={brand}
                  last4={last4}
                  month={exp_month}
                  year={exp_year}
                  onClick={() =>
                    toggleRemoveCardModal({
                      open: true,
                      card: { brand, last4, exp_month, exp_year },
                    })
                  }
                />
              ))
            ) : (
              <P>You don’t have a card saved to your account.</P>
            )}
            <AddButton
              stretch={savedCards.length > 0 ? true : false}
              onClick={() => toggleAddCardModal(true)}
            >
              {savedCards.length > 0 ? "Replace Card" : "Add A Credit Card"}
            </AddButton>
          </GridColumn>
        </Container>
      </Cards>
      <Transactions stretch>
        <Container>
          <Title>Transactions</Title>
          {transactionsByEvent.length > 0 ? (
            transactionsByEvent.map((transaction) => (
              <Transaction {...transaction} />
            ))
          ) : (
            <Footnote>No transactions to show...</Footnote>
          )}
        </Container>
      </Transactions>

      <FooterBlock />
    </Wrapper>
  );
});

const Wrapper = styled(GridColumn)`
  width: 100%;
  min-height: 100vh;
`;

const PageHeader = styled(GridRow)`
  margin-bottom: 40px;
`;

const Container = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  max-width: 1016px;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Cards = styled(GridRow)`
  background-color: #fafafa;
  border-bottom: 2px solid ${(props) => props.theme.palette.gray.lighter};
  padding: 24px 0 56px 0;

  @media only screen and ${(props) => props.theme.media.large} {
    padding: 40px 0 72px 0;
  }
`;

const Transactions = styled(GridRow)`
  padding: 40px 0 72px 0;
`;

const Title = styled(H4)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 22px;
`;

const AddButton = styled(ButtonPrimary)`
  margin-top: 24px;
  width: ${(props) => (props.stretch ? "100%" : "184px")};
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
  }
`;
