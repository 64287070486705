import React from "react";
import styled from "styled-components";
import { H1, GridRow, GridColumn, Link, P } from "notes";
import {
  Accordion,
  CustomParser as parser,
  HelpProvider,
  HelpContext,
  HeaderSecondary,
  FooterBlock,
  LoaderInline,
} from "Components";
import _ from "lodash";

export default () => (
  <GridColumn>
    <HeaderSecondary banner />
    <Container>
      <PageHeader>
        <H1>Help and FAQS</H1>
      </PageHeader>
      <HelpProvider>
        <HelpContext.Consumer>
          {({ helpReady, helpSections, helpTopics, helpFilterInput }) => (
            <>
              <div style={{ width: "94%", maxWidth: "392px" }}>
                {helpFilterInput("Search for a question or issue by keyword")}
              </div>
              {helpReady ? (
                _.keys(helpSections).length > 0 ? (
                  <>
                    <Sections>
                      {_.keys(helpSections).map((key) => (
                        <SectionLink href={`#${key}`}>
                          {helpSections[key].title}
                        </SectionLink>
                      ))}
                    </Sections>
                    {_.keys(helpSections).map((key) => {
                      const { title, topics } = helpSections[key];

                      return (
                        <>
                          <a name={key} />
                          <SectionHeader>{title}</SectionHeader>
                          {topics.map((topicId) => {
                            const title = _.get(
                              helpTopics,
                              `${topicId}.title`,
                              ""
                            );
                            const content = _.get(
                              helpTopics,
                              `${topicId}.content`,
                              ""
                            );
                            return title !== "" && content !== "" ? (
                              <Accordion title={title}>
                                <SectionContentWrapper>
                                  {parser(content)}
                                </SectionContentWrapper>
                              </Accordion>
                            ) : null;
                          })}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <NotFound>
                    We were unable to locate any matches for your search
                    criteria.
                  </NotFound>
                )
              ) : (
                <div style={{ marginTop: "60px", textAlign: "center" }}>
                  <P>Loading Help...</P>
                  <LoaderInline />
                </div>
              )}
            </>
          )}
        </HelpContext.Consumer>
      </HelpProvider>
    </Container>
    <FooterBlock />
  </GridColumn>
);

const NotFound = styled.div`
  margin-top: 12px;
  color: #596266;
  font-size: 17px;
  line-height: 27px;
`;

const Wrapper = styled(GridColumn)`
  width: 100%;
`;

const PageHeader = styled(GridRow)`
  margin-bottom: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-left: 0;
  }
`;

const Container = styled(GridColumn)`
  width: 100%;
  padding: 40px 0 96px 20px;
  margin: 0 auto;
  max-width: 1016px;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 40px;
  }
`;

const SectionLink = styled(Link)`
  ${(props) => props.theme.fonts.default};
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.purple};
  display: inline-block;
  text-align: center;
  padding: 10px;
`;

const SectionHeader = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.fonts.default};
  font-weight: 700;
  font-size: 17px;
  line-height: 27px;
  margin-top: 24px;
`;

const Sections = styled.div``;

const SectionContentWrapper = styled.div`
  padding-top: 24px;
`;
