import React, { useEffect } from "react";
import { Routes } from "Routes";
import { ThemeProvider } from "styled-components";
import { MAXTheme } from "notes";
import {
  StoreProvider,
  PaymentProvider,
  TimeProvider,
  MessageProvider,
  NotificationProvider,
} from "Components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

export const customTheme = {
  ...MAXTheme,
  colors: {
    ...MAXTheme.colors,
    facebook: "#1877F2",
    snapchat: "#FFFC00",
    transactionFill: "#f6f9fc",
    golden: "#FF8C00",
  },
  palette: {
    ...MAXTheme.palette,
    blue: {
      lighter: "#BCDBE6",
      lightest: "#EBFAFF",
    },
  },
};

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <StoreProvider>
        <PaymentProvider>
          <ThemeProvider theme={customTheme}>
            <MessageProvider>
              <NotificationProvider>
                <TimeProvider>
                  <Routes />
                </TimeProvider>
              </NotificationProvider>
            </MessageProvider>
          </ThemeProvider>
        </PaymentProvider>
      </StoreProvider>
    </Elements>
  );
};

export default App;
