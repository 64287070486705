import React, { useState } from "react";
import { P } from "notes";
import { Shipping } from "Components";
import {
  FieldContainer,
  FieldLabel,
  EditAction,
  PurpleText,
  LinkStyle,
  Actions,
} from "../Name";
import { fireAnalytics } from "services";

export const EditShipping = ({ defaultValue, onSubmit, ...props }) => {
  const [editMode, setEditMode] = useState(false);

  const handleSubmit = (fields) => {
    onSubmit(fields);
    setEditMode(false);
    fireAnalytics("add_shipping_info", {});
  };

  return (
    <FieldContainer yCenter editMode={editMode}>
      {(!!defaultValue || editMode) && (
        <FieldLabel>Shipping Address</FieldLabel>
      )}
      {editMode ? (
        <Shipping
          defaultValue={defaultValue}
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmit}
          actions={({ onCancel, onSubmit }) => (
            <Actions>
              <LinkStyle onClick={onCancel}>Cancel</LinkStyle>
              <LinkStyle onClick={onSubmit}>Apply</LinkStyle>
            </Actions>
          )}
        />
      ) : !defaultValue ? (
        <>
          <PurpleText onClick={() => setEditMode(true)}>
            Add Shipping Address
          </PurpleText>
          {/* <InfoIcon
            indicator
            name="Information"
            onClick={() => setEditMode(true)}
          /> */}
        </>
      ) : (
        <>
          <P>
            {defaultValue.addressLine1}
            <br />
            {defaultValue.addressLine2}
            {defaultValue.addressLine2 && <br />}
            {!!defaultValue.city
              ? defaultValue.city + ", " + defaultValue.state
              : defaultValue.state}{" "}
            {defaultValue.zipCode}
            <br />
            {defaultValue.country}
          </P>

          <EditAction name="Edit" onClick={() => setEditMode(true)} />
        </>
      )}
    </FieldContainer>
  );
};
