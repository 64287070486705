import React, { useContext } from "react";
import styled from "styled-components";
import { useCollection } from "react-firebase-hooks/firestore";
import { GridRow, GridColumn, Subheader } from "notes";
import { StoreContext } from "Components";
import { Package } from "./Package";

export const Packages = ({ eventId, smallView, ...props }) => {
  const { Store } = useContext(StoreContext);
  const [tickets, loading, error] = useCollection(
    Store.firestore()
      .collection("payment_items")
      .where("eventId", "==", eventId)
      .where("type", "==", "ticket")
      .where("status", "==", "public")
      .orderBy("amount", "desc")
  );

  if (loading) {
    return null;
  }

  let updatedTickets = tickets.docs
    .map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    .reverse();

  if (updatedTickets.length < 2) {
    return null;
  }

  return (
    <Wrapper {...props} smallView={smallView}>
      <Subheader>Pricing and Packages</Subheader>
      <Container nextLine={updatedTickets.length > 4} smallView={smallView}>
        {updatedTickets.map((ticket) => (
          <Package
            nextLine={updatedTickets.length > 4}
            smallView={smallView}
            {...ticket}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn).attrs({ as: "div" })`
  ${(props) => props.smallView && `margin: 0 16px 16px 16px;`}
  ${Subheader} {
    margin-top: 40px;
    margin-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media only screen and ${(props) => props.theme.media.small} {
      margin-top: 24px;
    }
  }
`;

const Container = styled(GridRow)`
  margin-left: -8px;
  ${(props) => (props.smallView || props.nextLine) && `flex-wrap: wrap;`}
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-wrap: wrap;
  }
`;
