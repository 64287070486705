import React from "react";
import styled from "styled-components";
import { GridColumn, H4 } from "notes";

export const PurchasedBadge = ({ text, ...props }) => {
  return (
    <PurchaseBanner yCenter {...props}>
      <PurchasedText>{text}</PurchasedText>
    </PurchaseBanner>
  );
};

const PurchaseBanner = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.orange.primary};
  flex-grow: 1;
  max-width: 5.263vw;
  position: relative;
  width: 100%;

  @media only screen and ${(props) => props.theme.media.large} {
    max-width: 56px;
  }
`;

const PurchasedText = styled(H4)`
  color: #ffffff;
  font-size: 2.35vw;
  font-weight: 600;
  white-space: nowrap;
  line-height: 5.263vw;
  position: absolute;
  top: 50%;
  transform: rotateZ(-90deg) translateX(-50%);
  transform-origin: 0 0;

  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 25px;
    line-height: 56px;
  }
`;
