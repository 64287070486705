import React, { createContext, useState, useContext } from "react";
import { LoginModal } from "./LoginModal";
import { ReportUser } from "./ReportUser";
import { SignUpModal } from "./SignUpModal";
import { ConfirmModal } from "./ConfirmModal";
import { MusicianModal } from "./MusicianModal";
import { MusicianSuccess } from "./MusicianSuccess";
import { AddCardModal } from "./AddCard";
import { Lightbox } from "./Lightbox";
import { RemoveCardModal } from "./RemoveCard";
import { HelpModal } from "./Help";
import { StoreContext } from "Components/StoreProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { NotificationModal } from "./Notification";
import { UpdateEmail } from "./UpdateEmail";
import { VideoModal } from "./VideoModal";

export const ModalContext = createContext();
export const ModalConsumer = ModalContext.Consumer;
const initReportUser = { open: false, content: "", onReport: undefined };
export const ModalProvider = ({ children, ...props }) => {
  const { Store, user } = useContext(StoreContext);
  const [loginModal, toggleLoginModal] = useState(false);
  const [reportUser, toggleReportUser] = useState(initReportUser);
  const [signUpModal, toggleSignUpModal] = useState(false);
  const [confirmModal, toggleConfirmModal] = useState({
    open: false,
    items: {},
  });
  const [musicianForm, toggleMusicianForm] = useState(false);
  const [packageModal, togglePackageModal] = useState({
    open: false,
    images: [],
  });
  const [userNameSet] = useDocumentData(
    Store.firestore().doc(`usernames/${user?.uid}`)
  );
  const [musicianSuccess, toggleMusicianSuccess] = useState(false);
  const [addCardModal, toggleAddCardModal] = useState(false);
  const [removeCardModal, toggleRemoveCardModal] = useState({
    open: false,
    card: {},
  });
  const [helpModal, toggleHelpModal] = useState(false);
  const [notificationModal, toggleNotificationModal] = useState({
    open: false,
  });
  const [updateEmail, toggleUpdateEmail] = useState({
    open: false,
  });

  const [modal, displayModal] = useState({});

  const handleClose = () => {
    displayModal({});
  };

  return (
    <ModalContext.Provider
      value={{
        loginModal,
        toggleLoginModal,
        toggleReportUser,
        toggleSignUpModal,
        toggleConfirmModal,
        toggleMusicianForm,
        toggleMusicianSuccess,
        togglePackageModal,
        toggleAddCardModal,
        removeCardModal,
        toggleRemoveCardModal,
        confirmModal,
        helpModal,
        toggleHelpModal,
        notificationModal,
        toggleNotificationModal,
        toggleUpdateEmail,
        displayModal,
        modal,
      }}
      {...props}
    >
      <VideoModal
        open={!!modal?.videoModal}
        setOpen={handleClose}
        path={modal?.videoModal?.path}
        autoplay={modal?.videoModal?.autoplay}
      />
      <LoginModal open={loginModal} setOpen={toggleLoginModal} />
      <ReportUser
        open={reportUser.open}
        message={reportUser.content}
        onReport={reportUser.onReport}
        setOpen={() => toggleReportUser(initReportUser)}
      />
      <SignUpModal open={signUpModal} setOpen={toggleSignUpModal} />
      {user && !userNameSet?.isUserDefined && (
        <ConfirmModal
          open={confirmModal.open}
          setOpen={() => toggleConfirmModal({ open: false, items: {} })}
        />
      )}
      <MusicianModal open={musicianForm} setOpen={toggleMusicianForm} />
      <MusicianSuccess open={musicianSuccess} setOpen={toggleMusicianSuccess} />
      <Lightbox
        images={packageModal.images}
        open={packageModal.open}
        setOpen={togglePackageModal}
      />
      <AddCardModal open={addCardModal} setOpen={toggleAddCardModal} />
      <RemoveCardModal
        open={removeCardModal.open}
        setOpen={toggleRemoveCardModal}
      />
      <HelpModal open={helpModal} setOpen={toggleHelpModal} />
      <NotificationModal open={notificationModal.open} />
      <UpdateEmail
        open={updateEmail.open}
        email={updateEmail.email}
        setOpen={toggleUpdateEmail}
      />
      {children}
    </ModalContext.Provider>
  );
};
