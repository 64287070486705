import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { Api } from "modules";
import {
  HeaderSecondary,
  FooterBlock,
  ShowRating,
  Survey,
  ModalContext,
  NotificationContext,
  SupportLevels,
} from "Components";
import {
  GridColumn,
  GridRow,
  Icon,
  Input,
  ButtonPrimary,
  withDefaultMedia,
  Modal,
  TertiaryMenu,
  H4,
  Pill,
} from "notes";
import { Chat } from "./Chat";
import { Leaderboard, Vote } from "../";
import { ArtistDescription } from "Routes/Event/Components/PreShow/Blocks";
import { useHistory } from "react-router-dom";
import { GiftIcon } from "Images";
import { SupportTiers } from "../SupportTiers";
import { TimeContext } from "Components";
import { fireAnalytics } from "services";

const standardViews = [
  { icon: <Icon form name="Message" />, label: "Chat" },
  { icon: <Icon form name="SetList" />, label: "Encore" },
  { icon: <Icon form name="Trophy" />, label: "Leaderboard" },
];

const rewardsMenu = { icon: <GiftIcon />, label: "Rewards" };

const Heartbeater = () => {
  const { time } = useContext(TimeContext);
  useEffect(() => {
    if (time.second === 0) {
      fireAnalytics("heartbeat_1min", {
        event_category: "heartbeats",
      });
    }
  }, [time]);

  return null;
};

export const Show = withDefaultMedia(
  ({ matches, show, postShow, onDonate, eventClosed }) => {
    const [hideChat, setHideChat] = useState(false);
    const [view, setView] = useState(standardViews[0]);
    const [ratingModal, setRatingModal] = useState(false);
    const [initialRating, setInitialRating] = useState(0);
    const [amount, setAmount] = useState(5);
    const containerRef = useRef(null);
    const handleRating = (value) => {
      setInitialRating(value);
      setRatingModal(true);
    };
    const handleReport = async (eventId, chatId) => {
      Api.flagChat(eventId, chatId)
        .then(({ data }) => {
          console.log("Report success");
        })
        .catch((error) => {
          console.error(`Got error: ${error.code} - ${error.message}`);
        });
    };
    const { toggleReportUser } = useContext(ModalContext);
    const handleReportUser = (chat, eventId) => {
      toggleReportUser({
        open: true,
        content: chat.content,
        onReport: () => handleReport(eventId, chat.key),
      });
    };
    const hideSidebar = hideChat || eventClosed;
    const views = show.hideEncore
      ? [standardViews[0], null, standardViews[2]]
      : standardViews;
    return (
      <Wrapper>
        {matches.large && <HeaderSecondary inverse />}
        {!postShow && <Heartbeater />}
        <Container>
          <VideoActionContainer>
            <LeftVideo extend={hideSidebar} ref={containerRef}>
              <VideoContainer>
                <VideoInner>
                  {!postShow ? (
                    <Video id={show.videoId} />
                  ) : (
                    <ShowRating
                      show={show}
                      value={initialRating}
                      setValue={(value) => handleRating(value)}
                    />
                  )}
                </VideoInner>
              </VideoContainer>
              {!eventClosed && (
                <Actions yCenter>
                  <Support columns={4}>
                    <SupportInput>
                      <Input
                        style={{ minWidth: "104px" }}
                        value={amount}
                        onChange={setAmount}
                        leftIcon={<Icon form name="USD" />}
                        placeholder=""
                      />
                    </SupportInput>
                    <SupportButton onClick={() => onDonate(amount)}>
                      Show Your Support
                    </SupportButton>
                  </Support>
                  {!show.hideEncore && (
                    <EncoreButton onClick={() => setView(views[1])}>
                      <Icon form name="SetList" />
                      Vote For Encore
                    </EncoreButton>
                  )}
                  <ExpandButton
                    onClick={() => setHideChat(!hideChat)}
                    name={hideChat ? "DefaultPlayer" : "LargePlayer"}
                  />
                </Actions>
              )}
            </LeftVideo>
          </VideoActionContainer>
          <ArtistInfo extend={hideSidebar}>
            <ArtistDescription showView show={show} />
          </ArtistInfo>
          {!eventClosed && (
            <SideBarContainer hide={matches.large && hideSidebar}>
              <MobileContainer>
                <div
                  style={{
                    display: view === views[0] ? "block" : "none",
                    flexGrow: 1,
                    overflowY: "auto",
                  }}
                >
                  <Chat
                    id={show.id}
                    flaggedOnClick={(chat) => handleReportUser(chat, show.id)}
                    votingClickHandler={() => setView(views[1])}
                    leaderboardClickHandler={() => setView(views[2])}
                  />
                </div>

                {view === views[1] && (
                  <div
                    style={{
                      display: "block",
                      flexGrow: 1,
                      overflowY: "auto",
                    }}
                  >
                    <Vote />
                  </div>
                )}
                {view === views[2] && (
                  <Leaderboard id={show.id} onDonate={onDonate} />
                )}
                {view === rewardsMenu && <SupportTiers onDonate={onDonate} />}
              </MobileContainer>
              <MobileMenu options={views} setView={setView} view={view} />
            </SideBarContainer>
          )}
        </Container>
        {matches.large && (
          <FooterBlock
            style={{ paddingRight: hideSidebar ? 0 : "392px" }}
            secondary
          />
        )}
        <ModalStyle size="full" open={ratingModal} setOpen={setRatingModal}>
          <ScrollContainer id="scroll-container">
            <CloseIcon
              platform
              name="Close"
              onClick={() => setRatingModal(false)}
            />
            <Survey
              show={show}
              initialRating={initialRating}
              onSuccess={() => setRatingModal(false)}
            />
          </ScrollContainer>
        </ModalStyle>
      </Wrapper>
    );
  }
);

const Video = React.memo(({ id }) => {
  const html = `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/${id}?autoplay=1&amp;app_id=122963" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`;
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
});

const LeftVideo = styled.div`
  flex-grow: 1;
  transition: width ease 200ms;
  @media only screen and ${(props) => props.theme.media.large} {
    width: calc(100% - 416px);
    ${(props) =>
      props.extend &&
      `
      width: calc(100% - 0px)
    `}
  }
`;

const MobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 56px;
  display: flex;
  overflow-y: auto;
  @media only screen and ${(props) => props.theme.media.large} {
    position: static;
    height: 100%;
  }
`;

const MobileMenu = withDefaultMedia(({ options, view, setView, matches }) => {
  const { newMessageCount } = useContext(NotificationContext);
  const { toggleNotificationModal } = useContext(ModalContext);
  const [showNewMessages, setShowNewMessages] = useState(false);

  useEffect(() => {
    let intv;
    if (newMessageCount > 0) {
      setShowNewMessages(true);
      intv = window.setTimeout(() => {
        setShowNewMessages(false);
      }, 5000);
    }
    return () => {
      if (intv) window.clearTimeout(intv);
    };
  }, [newMessageCount]);

  const menuItems = [
    {
      key: "notifications",
      icon: (
        <NotificationRow yCenter>
          <NotificationIcon tertiary name="Email" /> Messages{" "}
          <NotificationCount>{newMessageCount}</NotificationCount>
        </NotificationRow>
      ),
      label: "",
    },
    {
      key: "help",
      icon: <Icon indicator name="Questions" />,
      label: "Help",
    },
    {
      key: "home",
      icon: <RemoveIcon form name="Remove" />,
      label: "Leave Show",
    },
  ];

  const history = useHistory();
  const handleMenu = (key) => {
    switch (key) {
      case "help":
        history.push("/help");
        break;
      case "notifications":
        setShowNewMessages(false);
        toggleNotificationModal({ open: true });
        break;
      default:
        history.push("/");
    }
  };

  return (
    <>
      <MenuSpacer />
      <MenuContainer>
        {options.map((option) =>
          option ? (
            <MenuOption
              active={view === option}
              onClick={() => setView(option)}
            >
              {option.icon} <H4>{option.label}</H4>
            </MenuOption>
          ) : null
        )}
        {!matches.large && (
          <>
            <MenuOption
              active={view === rewardsMenu}
              onClick={() => setView(rewardsMenu)}
            >
              {rewardsMenu.icon} <H4>{rewardsMenu.label}</H4>
            </MenuOption>
            <TertiaryMenu
              offset="0, 0"
              items={menuItems}
              onSelect={(key) => handleMenu(key)}
              trigger={(props) => (
                <MenuOption {...props}>
                  <MoreIcon platform name="More" />
                  <H4>More</H4>
                </MenuOption>
              )}
            />
          </>
        )}
        {showNewMessages && <NewMessagePill label="new message" />}
        {newMessageCount > 0 && !showNewMessages && (
          <NewMessagesIndicator>&bull;</NewMessagesIndicator>
        )}
      </MenuContainer>
    </>
  );
});

const RemoveIcon = styled(Icon)`
  && {
    path {
      fill: ${(props) => props.theme.palette.red.primary};
    }
  }
`;

const NewMessagesIndicator = styled.div`
  width: 16px;
  height: 16px;
  font-size: 52px;
  text-align: center;
  transition: all ease 0.3s;
  position: absolute;
  top: 0;
  right: 16px;
  z-index: 10;
  color: #ff8c00;
  text-shadow: 1px 1px 8px;
`;

const NewMessagePill = styled(Pill)`
  transition: all ease 0.3s;
  background-color: #ff8c00;
  position: absolute;
  bottom: 60px;
  z-index: 10;
  right: 18px;
  text-transform: uppercase;
  &:after {
    position: absolute;
    bottom: -100%;
    height: 0;
    width: 0;
    left: 65%;
    border: 14px solid transparent;
    border-top-color: #ff8c00;
    content: "";
  }
`;

const NotificationRow = styled(GridRow)`
  margin-left: 0 !important;
`;

const NotificationIcon = styled(Icon)`
  margin-right: 7px !important;
`;

const NotificationCount = styled.div`
  background-color: #f9bf78;
  margin-left: 145px !important;
  padding: 1px 9px;
  color: ${(props) => props.theme.colors.black};
  font-size: 12px;
`;

const MenuSpacer = styled.div`
  width: 100%;
  height: 53px;
  flex-shrink: 0;
  order: 3;
  @media only screen and ${(props) => props.theme.media.large} {
    display: none;
  }
`;

const MenuContainer = styled(GridRow).attrs({ as: "div" })`
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-top: 1px solid ${(props) => props.theme.palette.gray.lighter};
  display: flex;
  justify-content: space-around;
  height: 56px;
  padding-top: 9px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  z-index: 10;
  @media only screen and ${(props) => props.theme.media.large} {
    background-color: ${(props) =>
      props.theme.dark ? props.theme.palette.black : "#FAFAFA"};
    border-top: none;
    justify-content: center;
    border-bottom: 2px solid
      ${(props) =>
        props.theme.dark
          ? props.theme.palette.gray.primary
          : props.theme.palette.gray.lightest};
    position: static;
    padding-top: 0;
    height: 46px;
    transition: all ease 2000ms;
  }
`;

const MenuOption = styled(GridRow).attrs({ as: "div" })`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 44px;
  padding: 0 8px;

  svg {
    width: 22px;
    height: 22px;
    margin-right: 0;
    margin-bottom: 4px;
    path {
      fill: ${(props) =>
        props.active
          ? props.theme.palette.black
          : props.theme.palette.gray.lighter};
    }
  }
  ${H4} {
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
    color: ${(props) =>
      props.active
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
  }

  @media only screen and ${(props) => props.theme.media.large} {
    flex-direction: row;
    flex-grow: 1;
    border-bottom: 2px inset
      ${(props) =>
        props.active
          ? props.theme.dark
            ? "#ffffff"
            : props.theme.palette.purple.primary
          : props.theme.dark
          ? props.theme.palette.black
          : "#FAFAFA"};
    padding: 2px 24px 0 24px;
    & + & {
      margin-left: 20px;
    }
    ${H4} {
      font-size: 12px;
      line-height: 18px;
      color: ${(props) =>
        props.active
          ? props.theme.dark
            ? "#ffffff"
            : props.theme.palette.purple.primary
          : props.theme.dark
          ? props.theme.palette.gray.lighter
          : props.theme.palette.black};
      font-weight: 600;
    }
    svg {
      width: 14px;
      height: 14px;
      margin-right: 9px;
      margin-bottom: 0;
      path {
        fill: ${(props) =>
          props.active
            ? props.theme.dark
              ? "#ffffff"
              : props.theme.palette.purple.primary
            : props.theme.dark
            ? props.theme.palette.gray.lighter
            : props.theme.palette.black};
      }
    }
    &:hover {
      background-color: ${(props) =>
        props.active
          ? props.theme.dark
            ? props.theme.palette.black
            : "#FAFAFA"
          : props.theme.dark
          ? props.theme.palette.purple.primary
          : "#F5F5F5"};
      border-bottom: 2px inset
        ${(props) =>
          props.active
            ? props.theme.dark
              ? "#ffffff"
              : props.theme.palette.purple.primary
            : props.theme.dark
            ? props.theme.palette.purple.primary
            : "#FAFAFA"};
      ${H4} {
        color: ${(props) =>
          props.active
            ? props.theme.dark
              ? "#FFFFFF"
              : props.theme.palette.purple.primary
            : props.theme.dark
            ? "#FFFFFF"
            : props.theme.palette.black};
      }
      svg {
        path {
          fill: ${(props) =>
            props.active
              ? props.theme.dark
                ? "#FFFFFF"
                : props.theme.palette.purple.primary
              : props.theme.dark
              ? "#FFFFFF"
              : props.theme.palette.black};
        }
      }
    }
  }

  @media only screen and ${(props) => props.theme.media.small} {
  }
`;

const MoreIcon = styled(Icon)`
  && {
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
  }
`;

const Wrapper = styled(GridColumn)`
  background-color: ${(props) => (props.theme.dark ? "#000" : "#fff")};
  transition: background-color ease 2000ms;
`;

const SupportButton = styled(ButtonPrimary)`
  margin-left: 8px;
  width: 222px;
  @media only screen and ${(props) => props.theme.media.large} {
    width: 222px;
  }
`;

const Container = styled(GridColumn)`
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: flex-start;
  height: 100vh;
  min-height: 100vw;
  @media only screen and ${(props) => props.theme.media.large} {
    padding-bottom: 90px;
    min-height: unset;
    height: unset;
  }
`;

const VideoActionContainer = styled.div`
  width: 100%;
  order: 1;
  display: block;
  max-height: 100%;
  @media only screen and ${(props) => props.theme.media.large} {
    position: relative;
  }
`;

const ArtistInfo = styled(GridColumn)`
  order: 3;
  display: none;
  width: ${(props) => (props.extend ? "100%" : "calc(100% - 416px)")};
  @media only screen and ${(props) => props.theme.media.large} {
    display: block;
    padding: 0 12px;
  }
`;

const VideoContainer = styled(GridColumn)`
  width: 100%;
  position: relative;
`;

const VideoInner = styled(GridColumn)`
  background-color: #222;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .vimeo {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .vimeo-image {
      background-size: cover;
    }
    .vimeo-loading {
      display: none;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    .vimeo-play-button {
    }
  }
`;

const SupportInput = styled(GridRow)`
  width: 104px;
`;

const Support = styled(GridRow)`
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  padding: 0 24px;
  @media only screen and ${(props) => props.theme.media.large} {
    justify-content: flex-start;
    padding: 0;
  }
`;

const ExpandButton = styled(Icon)`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

const SideBarContainer = styled(GridColumn)`
  border-top: 1px solid
    ${(props) =>
      props.theme.dark
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lightest};
  background-color: ${(props) => (props.theme.dark ? "#222222" : "#fafafa")};
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  transition: all ease 2000ms;
  position: relative;
  order: 2;

  @media only screen and ${(props) => props.theme.media.large} {
    border: 1px solid
      ${(props) =>
        props.theme.dark
          ? props.theme.palette.gray.primary
          : props.theme.palette.gray.lightest};
    flex-direction: column-reverse;
    order: unset;
    justify-content: flex-end;
    width: 392px;
    position: fixed;
    top: 48px;
    right: 0;
    height: calc(100vh - 48px);
    bottom: 0;
    z-index: 1;
  }
`;

const EncoreButton = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.palette.teal.primary};
  color: #fff;
  margin-right: 24px;
  &:hover {
    background-color: ${(props) => props.theme.palette.teal.darker};
    color: #fff;
  }
  svg {
    margin-right: 9px;
    path {
      fill: #fff;
    }
  }
`;

const Actions = styled(GridRow)`
  border-bottom: 2px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  height: 72px;
  justify-content: space-between;
  @media only screen and ${(props) => props.theme.media.large} {
    padding-right: 24px;
    margin-left: 12px;
  }
  ${EncoreButton} {
    display: none;
  }
  ${ExpandButton} {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${EncoreButton} {
      display: block;
    }
    ${ExpandButton} {
      display: block;
    }
  }
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 32px;
  z-index: 2;
`;

const ModalStyle = styled(Modal)`
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: inherit;
  & > svg {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;
