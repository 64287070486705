import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, Input } from "notes";
import { FieldMessage } from "Components";

export const DoubleField = ({ onChange, value, field, error = {} }) => {
  const handleText = (value, name) => {
    onChange({ [field.name]: value });
  };

  return (
    <GridColumn>
      <GridRow>
        <FirstName
          onChange={(fieldValue) =>
            handleText({ ...value, [field.names[0]]: fieldValue })
          }
          value={value && value[field.names[0]]}
          placeholder={field.placeholder[0]}
          error={!!error.status && error.status[field.names[0]]}
        />
        <LastName
          onChange={(fieldValue) =>
            handleText({ ...value, [field.names[1]]: fieldValue })
          }
          value={value && value[field.names[1]]}
          placeholder={field.placeholder[1]}
          error={!!error.status && error.status[field.names[1]]}
        />
      </GridRow>
      <FieldMessage active={!!error.status} content={error.message} />
    </GridColumn>
  );
};

const FirstName = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const LastName = styled(Input)`
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
