import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FormWrapper, FormContainer, PurchaseTicket } from "Components";
import {
  H3,
  Label,
  VerifyCode,
  ButtonSecondary,
  ButtonPrimary,
  ErrorMessage,
  P,
  Link,
  GridColumn,
  Subheader,
  Footnote,
  GridRow,
} from "notes";

import { Api } from "modules";
import { LoaderInline, StoreContext } from "Components";
import { Container } from "../Enrollment";

const accountVerificationMessage = `Please check the email address or phone number below to find your 6 digit verification code. The message may take a minute to arrive.`;

const verificationSentMessage = `A one-time verification code has been sent to`;

const verificationResendMessage = "Haven't received your code?";

export const Verify = () => {
  const history = useHistory();
  const { Store, userVerified, setTempVerify } = useContext(StoreContext);
  const { auth } = Store;
  const eventId = localStorage.getItem("eventId");

  const [verifyClear, setVerifyClear] = useState(0);
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(
    history.location && history.location.state ? history.location.state : {}
  );
  const [retries, setRetries] = useState(0);
  const [resendLoading, setResendLoading] = useState(false);
  const verifier = useRef();

  const clearVerify = () => {
    setVerifyClear(verifyClear + 1);
    setCode(null);
  };

  useEffect(() => {
    if (userVerified) {
      !!eventId && action !== "reset"
        ? history.push("/purchase")
        : history.push(url || "/", {
            method,
            source,
            verificationId,
            action,
            code,
          });
    }
  }, [userVerified]);

  let {
    source,
    action,
    url,
    method,
    verificationId,
    confirmationResult,
  } = state;

  const resend = async () => {
    setResendLoading(true);
    clearVerify();
    if (method === "sms") {
      if (!verifier.current) {
        verifier.current = new auth.RecaptchaVerifier("recaptcha-container", {
          size: "invisible",
        });
      }

      const verification = await auth().signInWithPhoneNumber(
        source,
        verifier.current
      );
      setState({
        ...state,
        verificationId: verification.verificationId,
      });
    } else {
      const { data } = await Api.sendVerificationEmail(
        auth().currentUser.email
      );
    }
    setResendLoading(false);
    setRetries(retries + 1);
  };

  if (action === "reset") {
    url = "/reset/password";
  }

  if (!source || !method || !verificationId) {
    history.push("/signup");
    return null;
  }

  const verify = async () => {
    setLoading(true);
    try {
      if (method === "sms") {
        const credential = auth.PhoneAuthProvider.credential(
          verificationId,
          code
        );
        console.log("GOT CREDENTIAL", credential);
        // Only link if on registration path
        if (action !== "reset") {
          await auth().currentUser.linkWithCredential(credential);
        } else {
          const user = await window.confirmationResult.confirm(code);
          console.log("GOT USER", user);
        }
      } else {
        await Api.verifyEmail(verificationId, code, action);
      }
      setTempVerify(true);
    } catch (err) {
      console.log(err);
      clearVerify();
      const { code, message } = err;
      if (code === "auth/invalid-verification-code") {
        setError(
          "Incorrect verification code entered. Please verify and try again."
        );
      } else {
        setError(message);
      }
      setLoading(false);
    }
  };

  return (
    <FormWrapper hideFooter>
      <Container stretch showTicket={!!eventId}>
        <Link
          style={{ position: "absolute", top: "24px", right: "24px" }}
          onClick={() => Store.auth().signOut()}
        >
          Sign Out
        </Link>
        {!!eventId && <PurchaseTicket eventId={eventId} />}
        <FormContainerStyle stretch>
          <Content showTicket={!!eventId}>
            {action !== "reset" && <Subheader>Step 2 of 2</Subheader>}
            <H3>Account Verification</H3>
            <P>{accountVerificationMessage}</P>

            {error && (
              <ErrorMessage style={{ marginBottom: "24px" }} content={error} />
            )}

            <AccountLabel>{verificationSentMessage}</AccountLabel>
            <P>{source}</P>

            <Form>
              <Label>Code</Label>
              <VerifyCode
                size={6}
                key={verifyClear}
                onChange={(val) => {
                  setError(null);
                  setCode(val);
                }}
              />
              <Footnote>
                {verificationResendMessage}{" "}
                <InlineLink onClick={resend} disabled={resendLoading}>
                  Resend
                </InlineLink>
              </Footnote>

              {retries > 0 && (
                <BlueMessage>
                  {action === "reset" || retries < 3 ? (
                    `A new code has been sent and should arrive in the next few
                  minutes…`
                  ) : method === "email" ? (
                    <>
                      It seems like you’re having trouble receiving your
                      verification code via email… Have you checked your spam or
                      trash folders? If you still aren’t receiving our email,
                      try{" "}
                      <Link
                        onClick={() =>
                          history.push("/requestverification", state)
                        }
                        id="submit"
                        style={{ display: "inline-block" }}
                      >
                        verifying by phone number.
                      </Link>
                    </>
                  ) : (
                    <>
                      It seems like you’re having trouble receiving your
                      verification code via sms... If you still aren’t receiving
                      our sms, try{" "}
                      <Link
                        onClick={() =>
                          history.push("/requestverification", {
                            ...state,
                            type: "email",
                          })
                        }
                        id="submit"
                      >
                        verifying by email.
                      </Link>
                    </>
                  )}
                </BlueMessage>
              )}

              <Actions>
                <ButtonSecondary
                  onClick={() => {
                    switch (action) {
                      case "reset":
                        history.push("/reset");
                        break;
                      default:
                        history.push("/requestverification");
                        break;
                    }
                  }}
                >
                  Back
                </ButtonSecondary>
                <ButtonPrimary disabled={loading} onClick={verify}>
                  {loading ? <LoaderInline /> : "Verify"}
                </ButtonPrimary>
              </Actions>
            </Form>
          </Content>
        </FormContainerStyle>
      </Container>
    </FormWrapper>
  );
};

const FormContainerStyle = styled(FormContainer)`
  min-height: 100%;
`;

const Content = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  margin: 40px 0 72px 0;
  padding: 40px;
  position: relative;
  ${Subheader} {
    margin-bottom: 4px;
  }

  ${(props) =>
    props.showTicket &&
    `
    border: none;
    padding: 0;
    margin: 0;
  `};

  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: ${(props) =>
      props.showTicket ? "0px 24px 104px 24px" : "40px 24px 104px 24px"};
  }
`;

const AccountLabel = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-bottom: 4px;
`;

const Form = styled(GridColumn)`
  & > ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    margin-top: 8px;
    margin-bottom: 5px;
  }
`;

const Actions = styled(GridRow)`
  margin-top: 40px;
  justify-content: space-between;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    position: absolute;
    left: 0;
    bottom: 24px;
    padding: 0 24px;
    button {
      min-width: 156px;
    }
  }
`;

const InlineLink = styled(Link)`
  display: inline-block;
  font-size: 12px;
  text-decoration: underline;
`;

const BlueMessage = styled(P)`
  background: #ebfaff;
  border: 1px solid #bcdbe6;
  padding: 12px;
`;
