import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridColumn,
  GridRow,
  ButtonPrimary,
  ButtonSecondary,
  Label,
  Footnote,
  Subheader,
  H3,
  P,
  VerifyCode,
  Link,
} from "notes";
import {
  FormWrapper,
  FormContainer,
  LoaderInline,
  FormMessage,
  FieldMessage,
  MessageContext,
} from "Components";
import { Api } from "modules";

export const ConfirmEmail = ({ location: { state }, ...props }) => {
  const { displayMessage } = useContext(MessageContext);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState("");
  const [resend, setResend] = useState("");
  const [resendLoading, setResendLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = () => {
    if (value.length === 6) {
      setLoading(true);
      Api.changeEmail({
        action: "finalize",
        email: state.newEmail,
        verification: {
          id: !!resend ? resend : state.verification.id,
          code: value,
        },
      })
        .then(({ data }) => {
          if (data?.success) {
            history.push("/login");
            displayMessage({
              color: "green",
              children: "Your email address was successfully updated!",
              timeout: 5000,
            });
          }
        })
        .catch((error) => {
          setErrors(error.message);
        });
    } else {
      setLoading(false);
      setErrors("Incorrect verification code.");
    }
  };

  const handleResend = () => {
    if (state.newEmail) {
      setResendLoading(true);
      Api.changeEmail({ action: "verify", email: state.newEmail })
        .then(({ data }) => {
          if (data.success) {
            setResend(data.verification.id);
            setResendLoading(false);
          }
        })
        .catch((error) => {
          setErrors(error.message);
          setResendLoading(false);
        });
    } else {
      setResendLoading(false);
    }
  };

  return (
    <FormWrapper hideFooter>
      <Container stretch>
        <FormContainer stretch>
          <Content>
            <Subheader>Step 2 of 2</Subheader>
            <H3>Update Account Information</H3>
            <P>
              Please check the email address below to find your 6 digit
              verification code. The message may take a minute to arrive.
            </P>
            <AccountLabel>
              A one-time verification code has been sent to
            </AccountLabel>
            <P>{state.newEmail}</P>
            <Form>
              <Label>Code</Label>
              <VerifyCode
                onChange={(value) => {
                  setValue(value);
                  setErrors("");
                }}
                value={value}
                size={6}
              />
              <FieldMessage active={!!errors} content={errors} />
              <Footnote>
                Haven’t received your code?{" "}
                <Link disabled={resendLoading} onClick={handleResend}>
                  Resend
                </Link>
              </Footnote>
              <FormMessage
                active={!!resend}
                type="info"
                content="A new code has been sent and should arrive in the next few minutes..."
              />
              <Actions>
                <ButtonSecondary onClick={() => history.goBack()}>
                  Back
                </ButtonSecondary>
                <ButtonPrimary disabled={loading} onClick={handleSubmit}>
                  {loading ? <LoaderInline /> : "Verify"}
                </ButtonPrimary>
              </Actions>
            </Form>
          </Content>
        </FormContainer>
      </Container>
    </FormWrapper>
  );
};

const Container = styled(GridColumn)`
  padding-top: 40px;
  width: 100%;
`;

const Content = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  margin: 40px 0 72px 0;
  padding: 40px;
  position: relative;
  width: 100%;
  max-width: 472px;
  ${Subheader} {
    margin-bottom: 4px;
  }

  ${(props) =>
    props.showTicket &&
    `
    border: none;
    padding: 0;
    margin: 0;
  `};

  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: ${(props) =>
      props.showTicket ? "0px 24px 104px 24px" : "40px 24px 104px 24px"};
  }
`;

const AccountLabel = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-bottom: 4px;
`;

const Form = styled(GridColumn)`
  & > ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    margin-top: 8px;
    margin-bottom: 5px;
  }
  a {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
  }
`;

const Actions = styled(GridRow)`
  margin-top: 40px;
  justify-content: space-between;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    position: absolute;
    left: 0;
    bottom: 24px;
    padding: 0 24px;
    button {
      min-width: 156px;
    }
  }
`;
