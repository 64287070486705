import styled from "styled-components";
import { ButtonSecondary } from "notes";

export const ButtonSecondaryWhite = styled(ButtonSecondary)`
  border-color: #ffffff;
  color: #ffffff;
  svg path {
    fill: #ffffff;
  }
  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #222222;
    svg path {
      fill: #222222;
    }
  }
`;
