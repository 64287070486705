import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridColumn, H2, P, H4, ButtonPrimary } from "notes";
import { formatCurrency, ImageCarousel } from "Components";
import { ReactComponent as DefaultTicketImage } from "../TicketGreen.svg";

export const Package = ({
  id,
  eventId,
  label,
  description,
  amount,
  purchaseLabel,
  assets,
  quantityRemaining,
  smallView,
  nextLine,
}) => {
  const history = useHistory();
  const soldOut = quantityRemaining < 1;
  return (
    <Card nextLine={nextLine} xCenter smallView={smallView} disabled={soldOut}>
      {assets?.primary ? (
        <ImageCarousel primary={assets.primary} images={assets.carousel} />
      ) : (
        <DefaultTicketImage />
      )}
      <H2>{label}</H2>
      <Description>{description}</Description>
      <PurchaseContainer stretch yEnd>
        <Price hasLimit={!!quantityRemaining || quantityRemaining === 0}>
          {formatCurrency(amount)}
        </Price>
        {(!!quantityRemaining || quantityRemaining === 0) && (
          <Remaining>
            {quantityRemaining} Ticket{quantityRemaining !== 1 && "s"} Remaining
          </Remaining>
        )}
        <ButtonPrimary
          disabled={soldOut}
          onClick={() => {
            localStorage.setItem("eventId", eventId);
            localStorage.setItem("paymentItemId", id);
            history.push("/purchase");
          }}
        >
          {soldOut ? "Sold Out" : purchaseLabel}
        </ButtonPrimary>
      </PurchaseContainer>
    </Card>
  );
};

const Description = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 15px;
  line-height: 19px;
  text-align: center;
`;

const Price = styled(H4)`
  color: ${(props) => props.theme.palette.black};
  font-weight: 900;
  margin-top: 16px;
  margin-bottom: ${(props) => (props.hasLimit ? 0 : "16px")};
  text-align: center;
`;

const Remaining = styled(P)`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 12px;
  text-align: center;
`;

const Card = styled(GridColumn)`
  background: #ffffff;
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0 1px 8px 0 rgba(0, 0, 0, 0.32)"};
  ${(props) =>
    props.disabled && `border: 2px solid ${props.theme.palette.gray.lightest}`};
  border-radius: 8px;
  padding: 24px;
  position: relative;
  width: 100%;
  min-height: 478px;
  min-width: 0;
  ${H2} {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
  }
  ${(props) =>
    props.smallView ? `margin-bottom: 16px;` : `margin: 0 8px 16px 8px;`}
  ${H2}, ${Description}, ${Price}, ${Remaining}  {
    color: ${(props) =>
      props.disabled
        ? props.theme.palette.gray.lighter
        : props.theme.palette.black};
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) => props.nextLine && `max-width: 240px;`};
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 235px;
    margin-bottom: 16px;
    margin-left: 16px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    min-height: inherit;
    & + & {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const PurchaseContainer = styled(GridColumn)`
  width: 100%;
`;
