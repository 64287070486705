import React, { useState, useContext, useMemo, useEffect } from "react";
import styled from "styled-components";
import { debounce, result } from "lodash";
import {
  GridColumn,
  Icon,
  Footnote,
  P,
  Input,
  ButtonPrimary,
  GridRow,
  Link,
  H3,
  Select,
  Card,
  Label,
} from "notes";
import {
  FieldMessage,
  LoaderInline,
  FormWrapper,
  Payment,
  StoreContext,
  formatCurrency,
} from "Components";
import {
  useCollectionOnce,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";

const StretchWrapper = styled(FormWrapper)`
  align-items: stretch;
  max-width: 500px;
  padding: 0 24px;
  margin: 0 auto;
  & > svg {
    margin: 48px auto 0 auto;
  }
`;

const quantities = [
  {
    id: 1,
    text: "1",
  },
  {
    id: 2,
    text: "2",
  },
  {
    id: 3,
    text: "3",
  },
  {
    id: 4,
    text: "4",
  },
  {
    id: 5,
    text: "5",
  },
  {
    id: 6,
    text: "6",
  },
  {
    id: 7,
    text: "7",
  },
  {
    id: 8,
    text: "8",
  },
  {
    id: 9,
    text: "9",
  },
  {
    id: 10,
    text: "10",
  },
];

export const GiftForm = ({ onComplete, eventId }) => {
  const { Store } = useContext(StoreContext);
  const [shows, setShows] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedShow, setSelectedShow] = useState(eventId);
  const [selectedTicket, setSelectedTicket] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState(quantities[0]);
  const srvTime = useMemo(() => Store.firestore.Timestamp.now(), []);

  const [apiShows, loadingShows, errorShows] = useCollectionOnce(
    Store.firestore()
      .collection("events")
      .where("active", "==", true)
      .where("endDate", ">", srvTime)
      .orderBy("endDate")
      .orderBy("priority")
  );
  const [apiTickets, loading, error] = useCollectionOnce(
    Store.firestore()
      .collection("payment_items")
      .where("eventId", "==", selectedShow?.id || null)
      .where("type", "==", "ticket")
      .where("status", "==", "public")
      .orderBy("amount", "desc")
  );

  useEffect(() => {
    if (!apiShows) {
      return;
    }
    const results = apiShows.docs.map((show) => {
      const { title, subtitle } = show.data();
      return {
        id: show.id,
        text: title,
        subtext: subtitle,
      };
    });
    if (eventId) {
      const selectshow = results.find((event) => event.id === eventId);
      if (selectshow) {
        setSelectedShow(selectshow);
      }
    }
    setShows(results);
  }, [apiShows]);

  useEffect(() => {
    if (!apiTickets) {
      return;
    }
    const results = apiTickets.docs
      .map((ticket) => {
        const { label, description, amount } = ticket.data();
        return {
          id: ticket.id,
          text: `${label} (${formatCurrency(amount)})`,
          subtext: description,
          source: ticket.data(),
        };
      })
      .reverse();
    setSelectedTicket(results[0]);
    setTickets(results);
  }, [apiTickets]);

  const items = selectedTicket
    ? {
        id: selectedTicket.id,
        amount: selectedTicket.source.amount,
        quantity: selectedQuantity.id,
        gift: true,
        label: selectedTicket.text,
      }
    : null;

  return (
    <StretchWrapper>
      <Container>
        <TopContainer>
          <H3>Gift A Show Ticket</H3>
          <SelectLabel>
            Show
            <Select
              placholder={"Choose a show"}
              options={shows}
              selected={selectedShow}
              onChange={setSelectedShow}
            />
          </SelectLabel>
          <SelectLabel>
            Ticket type
            <Select
              placholder={"Select a Ticket"}
              options={tickets}
              selected={selectedTicket}
              onChange={setSelectedTicket}
            />
          </SelectLabel>
          <SelectLabel>
            Ticket quantity
            <Select
              placholder="Choose a quantity"
              options={quantities}
              selected={selectedQuantity}
              onChange={setSelectedQuantity}
            />
          </SelectLabel>
        </TopContainer>
        <BottomContainer>
          {items && (
            <Payment
              style={{ margin: 0 }}
              items={items}
              onComplete={onComplete}
            />
          )}
        </BottomContainer>
      </Container>
    </StretchWrapper>
  );
};

const Container = styled.div`
  border: 1px solid #a6aeb2;
  border-radius: 8.5px;
  margin-bottom: 300px;
  margin-top: 40px;
`;

const TopContainer = styled.div`
  padding: 40px;
`;

const BottomContainer = styled.div`
  background: #f6f9fc;
  border-radius: 0 0 8px 8px;
`;

const SelectLabel = styled(Label)`
  margin-top: 24px;
`;

const Loader = styled(LoaderInline)`
  & {
    top: -15px;
    left: -20px;
  }
`;

const ConfirmButton = styled(ButtonPrimary)`
  margin-top: 40px;
  margin-bottom: 18px;
  & + ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    font-weight: 400;
  }
`;

const sharedStyles = `
  border-width: 2px;
  box-shadow: none;
`;

const StyledInput = styled(Input)`
  ${(props) =>
    props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
  ${(props) =>
    props.isError && `border-color: ${props.theme.palette.red.primary};`};
  ${(props) => (props.isSuccess || props.isError) && sharedStyles};

  &:focus {
    ${(props) =>
      props.isError && `border-color: ${props.theme.palette.red.primary};`};
    ${(props) =>
      props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
    ${(props) => (props.isSuccess || props.isError) && sharedStyles};
  }
`;

const Actions = styled(GridRow)`
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: flex-end;
  }
`;

const LinkStyle = styled(Link)`
  padding: 0 8px;
  height: 40px;
  & + & {
    margin-left: 24px;
  }
`;
