import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StoreContext,
  LoaderInline,
  PaymentContext,
  MessageContext,
} from "Components";
import {
  GridColumn,
  GridRow,
  H3,
  H4,
  Link,
  ButtonPrimary,
  ButtonSecondary,
  Icon,
  P,
} from "notes";
import { Payments } from "./Payments";

export const AddCard = ({ onComplete, ...props }) => {
  const { displayMessage } = useContext(MessageContext);
  const { Store } = useContext(StoreContext);
  const { updatePaymentMethods } = useContext(PaymentContext);
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const [api] = useState(new Payments(stripe, Store));
  const elements = useElements();

  const handleSubmit = () => {
    setProcessing(true);
    const cardEle = elements.getElement(CardElement);
    if (cardEle) {
      api.removeSavedCards().then((result) => {
        if (result.success) {
          api.addPaymentMethod(cardEle);
        } else {
          setProcessing(false);
          displayMessage({
            color: "red",
            children: (
              <GridRow yCenter>
                <ErrorIcon form name="Error" />
                <P style={{ fontWeight: "400" }}>{result.context.message}</P>
              </GridRow>
            ),
            timeout: 5000,
          });
        }
      });
    }
  };

  useEffect(() => {
    api.on("payment_method", (result, api) => {
      if (result.success) {
        updatePaymentMethods();
        displayMessage({
          color: "green",
          children: <P>Card successfully added to your account!</P>,
          timeout: 5000,
        });
        onComplete();
      } else {
        setProcessing(false);
        displayMessage({
          color: "red",
          children: (
            <GridRow yCenter>
              <ErrorIcon form name="Error" />
              <P style={{ fontWeight: "400" }}>{result.context.message}</P>
            </GridRow>
          ),
          timeout: 5000,
        });
      }
    });
  }, []);

  return (
    <Wrapper>
      <Header>
        <H3>Store Credit Card</H3>
        <CancelLink onClick={onComplete}>Cancel</CancelLink>
      </Header>
      <Container>
        <BillingTitle>
          <LockIcon name="Lock" />
          <H4>Credit Card</H4>
        </BillingTitle>
        <CardElement
          options={{
            classes: {
              base: "stripe-credit-card",
              complete: "stripe-credit-card--complete",
              focus: "stripe-credit-card--focus",
              invalid: "stripe-credit-card--invalid",
            },
            style: {
              base: {
                fontFamily: '"Open Sans", sans-serif',
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
            disabled: false,
          }}
        />
        <ButtonContainer yEnd>
          <CancelButton onClick={onComplete}>Cancel</CancelButton>
          <SaveButton disabled={processing} onClick={handleSubmit}>
            {processing ? <LoaderInline /> : "Save Card"}
          </SaveButton>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  width: 100%;
`;

const Container = styled(GridColumn)`
  height: 100%;

  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px 24px 24px;
  }
`;

const ButtonContainer = styled(GridRow)`
  margin-top: 24px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 16px;
  }
`;

const CancelLink = styled(Link)`
  display: none;
  height: 40px;
  padding: 0 8px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: flex;
  }
`;

const CancelButton = styled(ButtonSecondary)`
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const SaveButton = styled(ButtonPrimary)`
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
  }
`;

const BillingTitle = styled(GridRow)`
  margin-bottom: 16px;
  width: 100%;
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-size: 14px;
    font-weight: 700;
    flex-grow: 1;
  }
`;

const LockIcon = styled(Icon)`
  margin-right: 4px;
  height: 22px;
  width: 22px;
  path {
    fill: ${(props) => props.theme.palette.black};
  }
`;

const Header = styled(GridRow)`
  width: 100%;
  ${H3} {
    margin-bottom: 24px;
    text-align: left;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
    justify-content: space-between;
    height: 64px;
    padding: 8px 24px 0 24px;
    align-items: center;
    margin-bottom: 24px;
    ${H3} {
      color: ${(props) => props.theme.palette.gray.lighter};
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 0;
    }
  }
`;

export const ErrorIcon = styled(Icon)`
  margin-right: 12px;
`;
