import React, { useState, useReducer } from "react";
import styled from "styled-components";
import {
  Label,
  GridColumn,
  GridRow,
  Icon,
  Input,
  Select,
  ButtonPrimary,
  ButtonSecondary,
} from "notes";
import { reducer, validate, Api } from "modules";
import { statesData, countryCodes } from "Data";
import {
  rewardSchemaUSA,
  rewardSchemaNotUSA,
  accountSchemaUSA,
  accountSchemaNotUSA,
} from "./schema";
import { FieldMessage, Field, NativeSelect } from "Components";
import { fireAnalytics } from "services";
import { FormMessage } from "../FormMessage";

const DefaultActions = ({ onCancel, onSubmit }) => (
  <Actions xEnd>
    <Button onClick={onCancel}>Cancel</Button>
    <ButtonPrimary onClick={onSubmit}>Confirm</ButtonPrimary>
  </Actions>
);

export const Shipping = ({
  defaultValue,
  onSubmit,
  onCancel,
  onChange,
  children,
  includeName,
  actions = DefaultActions,
  ...props
}) => {
  const [address, setAddress] = useReducer(reducer, {
    name: {},
    ...defaultValue,
  });
  const [errors, setErrors] = useState({});

  const stateList = statesData.map((item, index) => {
    return { id: ++index, text: item };
  });

  let selectedState =
    address?.state &&
    stateList.find(({ text }) => text.includes(address?.state));

  const countryList = Object.keys(countryCodes).map((item, index) => {
    return { id: ++index, text: countryCodes[item].name };
  });

  let selectedCountry =
    address?.country &&
    countryList.find(({ text }) => text === address?.country);

  const handleChange = (name, value) => {
    setAddress({ [name]: value });
    delete errors[name];
    delete errors["verification"];
    setErrors(errors);
    !!onChange && onChange();
  };

  const handleNameChange = (value) => {
    setAddress({ name: value });
    if (!!value.firstName) {
      delete errors["firstName"];
    }
    if (!!value.lastName) {
      delete errors["lastName"];
    }
    setErrors(errors);
    !!onChange && onChange();
  };

  const handleZip = (value) => {
    if (value.match(/^[0-9\b]+$/) || value === "") {
      handleChange("zipCode", value);
    }
  };

  const isOtherCountry =
    !!selectedCountry && selectedCountry?.text !== "United States";

  const handleSubmit = () => {
    const schema = !isOtherCountry
      ? includeName
        ? rewardSchemaUSA
        : accountSchemaUSA
      : includeName
      ? rewardSchemaNotUSA
      : accountSchemaNotUSA;

    const isError = validate(address, schema);
    if (isError) {
      setErrors(isError);
    } else {
      if (!isOtherCountry) {
        Api.verifyAddress({
          street: address.addressLine1,
          street2: address.addressLine2,
          city: address.city,
          state: address.state,
          zipcode: address.zipCode,
        }).then((response) => {
          if (response.length > 0) {
            if (!includeName) delete address["name"];
            !!onSubmit && onSubmit(address);
          } else {
            setErrors({ verification: true });
          }
        });
      } else {
        if (!includeName) delete address["name"];
        !!onSubmit && onSubmit(address);
      }
      fireAnalytics("add_shipping_info", {});
    }
  };

  const handleCancel = () => {
    onCancel();
    setErrors({});
    setAddress(defaultValue);
  };

  return (
    <GridColumn noShrink {...props}>
      <FormMessage
        active={!!errors.verification}
        content="This does not appear to be a valid address. Please review the fields below before attempting your submission again."
        style={{ marginTop: "16px" }}
      />
      <Fields>
        {includeName && (
          <>
            <Label>Recipient's Name</Label>
            <Field
              field={{
                type: "double",
                name: "name",
                names: ["firstName", "lastName"],
                placeholder: [
                  "First or given name...",
                  "Last or family name...",
                ],
              }}
              onChange={({ name }) => handleNameChange(name)}
              value={address?.name}
              error={{
                status:
                  errors?.firstName || errors?.lastName
                    ? {
                        firstName: errors?.firstName,
                        lastName: errors?.lastName,
                      }
                    : null,
                message: "This is a required field and cannot be blank.",
              }}
            />
          </>
        )}

        <Label>Country</Label>
        <NativeSelect
          onChange={(e) => {
            handleChange("country", e.target.value);
            setAddress({ state: "", zipCode: "" });
            setErrors({});
          }}
          options={countryList}
          value={{ value: selectedCountry?.text }}
          placeholder="Select an option..."
          error={errors.country}
          style={{ cursor: "default" }}
          name="Country"
        />
        <FieldMessage
          active={!!errors.country}
          content="This is a required field and cannot be blank."
        />

        <Label>Address</Label>
        <Input
          onChange={(value) => handleChange("addressLine1", value)}
          value={address?.addressLine1}
          placeholder="Address Line 1..."
          error={errors.addressLine1}
        />
        <FieldMessage
          active={!!errors.addressLine1}
          content="This is a required field and cannot be blank."
        />
        <Label>Apartment, suite, etc. (optional)</Label>
        <Input
          onChange={(value) => handleChange("addressLine2", value)}
          value={address?.addressLine2}
          placeholder="Address Line 2..."
        />

        <Label>City</Label>
        <Input
          onChange={(value) => handleChange("city", value)}
          value={address?.city}
          placeholder="City..."
          error={errors.city}
        />
        <FieldMessage
          active={!!errors.city}
          content="This is a required field and cannot be blank."
        />

        <Label>State or province</Label>
        {isOtherCountry ? (
          <Input
            onChange={(value) => handleChange("state", value)}
            value={address?.state}
            placeholder="State or province..."
            error={errors.state}
          />
        ) : (
          <NativeSelect
            onChange={(e) => handleChange("state", e.target.value)}
            options={stateList}
            value={{ value: selectedState?.text }}
            placeholder="Select an option..."
            error={errors.state}
            style={{ cursor: "default" }}
            name="State"
          />
        )}
        <FieldMessage
          active={!!errors.state}
          content="This is a required field and cannot be blank."
        />

        <Label>Zip or postal code</Label>
        <Input
          leftIcon={<Icon form name="Marker" />}
          onChange={
            isOtherCountry
              ? (value) => handleChange("zipCode", value)
              : handleZip
          }
          value={address?.zipCode}
          placeholder={isOtherCountry ? "Postal Code..." : "5-digit code..."}
          style={{ width: "184px" }}
          type={isOtherCountry ? "text" : "tel"}
          error={errors.zipCode}
          maxLength={isOtherCountry ? undefined : 5}
          name="ZIP Code"
        />
        <FieldMessage
          active={!!errors.zipCode}
          content="This is a required field and cannot be blank."
        />
      </Fields>
      {children}
      {actions({ onCancel: handleCancel, onSubmit: handleSubmit })}
    </GridColumn>
  );
};

const Fields = styled(GridColumn)`
  max-width: 392px;
  width: 100%;
  ${Label} {
    margin-top: 16px;
    margin-bottom: 4px;
  }
`;

const Actions = styled(GridRow)`
  margin-top: 56px;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

const Button = styled(ButtonSecondary)`
  margin-right: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    order: 2;
    margin-right: 0;
    margin-top: 8px;
  }
`;
