import React, { useState, useContext } from "react";
import styled from "styled-components";
import { StoreContext } from "Components";
import { shirtSizesArray } from "Data";
import { Actions, Form, CancelButton } from "../../Components";
import { H3, P, Checkbox, Label, ButtonPrimary, Select } from "notes";
import { Api } from "modules";

export const ShirtForm = ({ rewardId, userResponse, onComplete }) => {
  const [globalOption, setGlobalOption] = useState(true);
  const [shirtSize, setShirtSize] = useState(userResponse);
  const { user, Store } = useContext(StoreContext);
  const [dirtyState, setDirtyState] = useState(true);

  let defaultSelected =
    shirtSize && shirtSizesArray.find(({ text }) => text.includes(shirtSize));

  const handleClose = () => {
    onComplete(false);
  };

  const handleSubmit = () => {
    if (shirtSize && globalOption) {
      Store.firestore()
        .collection("profiles")
        .doc(user.uid)
        .update({ shirtSize: shirtSize });
    }

    if (shirtSize) {
      Api.updateRewards({
        userRewardId: `${user.uid}#${rewardId}`,
        userResponse: { shirtSize: shirtSize },
        updateAll: globalOption,
      }).then(() => handleClose());
    }
  };

  return (
    <Form>
      <H3>Shirt Size</H3>
      <P>Please let us know what size shirt you would like to receive.</P>
      <Label>Shirt size</Label>
      <Select
        selected={defaultSelected}
        options={shirtSizesArray}
        onChange={(value) => {
          setShirtSize(value.text);
          setDirtyState(true);
        }}
        placeholder="Select an option..."
      />
      {(!userResponse || dirtyState) && (
        <CheckboxStyle
          checked={globalOption}
          onChange={setGlobalOption}
          title="Save this as my default shirt size on Set.Live"
        />
      )}
      <Actions xEnd>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ButtonPrimary onClick={handleSubmit}>Confirm</ButtonPrimary>
      </Actions>
    </Form>
  );
};

const CheckboxStyle = styled(Checkbox)`
  margin-top: 24px;
  margin-bottom: 0;
`;
