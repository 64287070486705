import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import {
  GridColumn,
  Icon,
  Footnote,
  P,
  Input,
  ButtonPrimary,
  GridRow,
  Link,
} from "notes";
import { FieldMessage, LoaderInline } from "Components";
import { Api } from "modules";
import { handleErrors } from "./handleErrors";

const initState = { available: false, type: "", content: "" };

export const EditUsername = ({ onChange, onSuccess, secondary, ...props }) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState(initState);
  const [inputLoading, setInputLoading] = useState(false);
  const [buttonState, setButtonState] = useState({
    disabled: false,
    loading: false,
    confirmed: false,
  });

  const handleResponse = (available) => {
    if (available) {
      return {
        available: true,
        type: "success",
        content: "This name is available!",
      };
    } else {
      return {
        available: false,
        type: "error",
        content: "This name is unavailable.",
      };
    }
  };

  const handleCall = (username) => {
    Api.checkUsername({ action: "check_availability", username: username })
      .then(({ data }) => {
        const message = handleResponse(data.available);
        setMessage(message);
        setInputLoading(false);
        setButtonState({
          disabled: false,
          loading: false,
          confirmed: false,
        });
      })
      .catch((error) => {
        const displayError = handleErrors(error);
        setMessage(displayError);
        setInputLoading(false);
        setButtonState({
          disabled: false,
          loading: false,
          confirmed: false,
        });
        console.error(`Got error: ${error.code} - ${error.message}`);
      });
  };

  const handleCheck = useCallback(
    debounce((value) => handleCall(value), 2000),
    []
  );

  const handleChange = (username) => {
    if (!username.match(/[^\w]|__{2}/g)) {
      setMessage(initState);
      setName(username);
      !!onChange && onChange(username);
      if (username.length > 3) {
        setInputLoading(true);
        setButtonState({
          disabled: true,
          loading: false,
          confirmed: false,
        });
        handleCheck(username);
      } else if (!username) {
        setMessage(initState);
      }
    }
  };

  const handleSubmit = () => {
    if (message.available) {
      setButtonState({ disabled: true, loading: true, confirmed: false });
      Api.checkUsername({ action: "set", username: name })
        .then(({ data }) => {
          if (data.success) {
            !!onSuccess && onSuccess(true);
            setButtonState({
              disabled: true,
              loading: false,
              confirmed: true,
            });
          }
        })
        .catch((error) => {
          setButtonState({
            disabled: false,
            loading: false,
            confirmed: false,
          });
          setMessage({
            available: false,
            type: "error",
            content: "There was an issue with your request, please try again.",
          });
          console.error(`Got error: ${error.code} - ${error.message}`);
        });
    } else {
      setMessage({
        available: false,
        type: "error",
        content: "Your name must be a minimum of 3 characters.",
      });
    }
  };

  return (
    <Container>
      <StyledInput
        isError={message.type.includes("error")}
        isSuccess={message.type.includes("success")}
        value={name}
        onChange={handleChange}
        placeholder="Select a unique name for yourself..."
        rightIcon={
          inputLoading ? (
            <Loader />
          ) : name.length > 3 ? (
            <Icon
              onClick={() => {
                setName("");
                setMessage(initState);
              }}
              form
              name="RemoveCircle"
            />
          ) : undefined
        }
      />
      <Footnote style={{ paddingTop: "12px" }}>
        Must be at least 4 characters in length and cannot include special
        characters.
      </Footnote>
      <FieldMessage
        active={
          message.type.includes("error") || message.type.includes("success")
        }
        type={message.type}
        content={message.content}
      />
      {secondary ? (
        <Actions>
          <LinkStyle onClick={() => onSuccess()}>Cancel</LinkStyle>
          <LinkStyle onClick={() => handleSubmit()}>Apply</LinkStyle>
        </Actions>
      ) : (
        <ConfirmButton
          disabled={buttonState.disabled || message.type === "error"}
          onClick={() => handleSubmit()}
        >
          {buttonState.loading ? (
            <LoaderInline />
          ) : buttonState.confirmed ? (
            "Confirmed!"
          ) : (
            "Confirm Display Name"
          )}
        </ConfirmButton>
      )}
    </Container>
  );
};

const Container = styled(GridColumn)``;

const Loader = styled(LoaderInline)`
  & {
    top: -15px;
    left: -20px;
  }
`;

const ConfirmButton = styled(ButtonPrimary)`
  margin-top: 40px;
  margin-bottom: 18px;
  & + ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    font-weight: 400;
  }
`;

const sharedStyles = `
  border-width: 2px;
  box-shadow: none;
`;

const StyledInput = styled(Input)`
  ${(props) =>
    props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
  ${(props) =>
    props.isError && `border-color: ${props.theme.palette.red.primary};`};
  ${(props) => (props.isSuccess || props.isError) && sharedStyles};

  &:focus {
    ${(props) =>
      props.isError && `border-color: ${props.theme.palette.red.primary};`};
    ${(props) =>
      props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
    ${(props) => (props.isSuccess || props.isError) && sharedStyles};
  }
`;

const Actions = styled(GridRow)`
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: flex-end;
  }
`;

const LinkStyle = styled(Link)`
  padding: 0 8px;
  height: 40px;
  & + & {
    margin-left: 24px;
  }
`;
