import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, H2, P } from "notes";

export const EventDetails = ({ date, time, start, ...props }) => {
  return (
    <ShowDetails yEnd>
      <Column xCenter>
        <BoldLabel>{date}</BoldLabel>
        <Time>
          {time} {start.offsetNameShort}
        </Time>
      </Column>
    </ShowDetails>
  );
};

const ShowDetails = styled(GridRow)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Column = styled(GridColumn)`
  margin-bottom: 24px;
  width: 100%;
`;

const BoldLabel = styled(H2)`
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 32px;
  text-transform: uppercase;
`;

const Time = styled(P)`
  color: ${(props) => props.theme.palette.orange.primary};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
