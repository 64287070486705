import styled from "styled-components";

export const ButtonTransparent = styled.button`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.24);
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: ${(props) => props.theme.fonts.overpass};
  font-size: 15px;
  font-weight: 700;
  justify-content: center;
  line-height: 22px;
  outline: 0;
  padding: 0 12px;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  }
  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.palette.gray.lighter};
  }
`;
