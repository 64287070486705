import React, { useContext, useState, useEffect, useMemo } from "react";
import { PreShow, Show, PaymentSidebar } from "./Components";
import {
  StoreContext,
  formatShow,
  EventProvider,
  EventContext,
  TimeContext,
} from "Components";
import { Duration } from "luxon";
import { ThemeProvider } from "styled-components";
import { customTheme } from "App";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { useObjectVal } from "react-firebase-hooks/database";
import { Helmet } from "react-helmet";

export const Event = ({
  match: {
    params: { event: eventId },
  },
  props,
}) => (
  <EventProvider id={eventId}>
    <EventInternal {...props} />
  </EventProvider>
);
const EventInternal = () => {
  useEffect(() => localStorage.clear(), []);
  const { Store, user, tickets, ticketsLoading } = useContext(StoreContext);
  const { event, loading, error } = useContext(EventContext);
  const { time: nowTime } = useContext(TimeContext);
  const [donateItems, donateLoad, donateError] = useCollectionOnce(
    Store.firestore()
      .collection(`payment_items`)
      .where("eventId", "==", event.id)
      .where("type", "==", "donate")
      .where("status", "==", "public")
      .limit(1)
  );
  const [ticketStatus] = useObjectVal(
    Store.database().ref(`${event.id}/users/${user?.uid}`)
  );

  const [donate, setDonate] = useState();

  const item = useMemo(
    () =>
      donateItems?.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))[0],
    [donateItems]
  );

  const items = useMemo(
    () =>
      donate
        ? {
            quantity: donate,
            ...item,
          }
        : null,
    [donate, item]
  );

  if (!event || loading || ticketsLoading) {
    return null;
  }
  const eventId = event.id;
  const eventTickets = tickets?.find(
    (tic) => tic?.paymentItem?.eventId === eventId
  );
  const formattedShow = formatShow(event);
  const timeToStart = Duration.fromMillis(
    formattedShow.start - nowTime
  ).shiftTo("days", "hours", "minutes", "seconds", "milliseconds");
  const show = {
    ...formattedShow,
    timeToStart,
    id: eventId,
  };

  const handleDonate = (amount) => {
    setDonate(amount);
  };

  const handleDonateComplete = () => {
    setDonate(null);
  };

  const handleDonateCancel = () => {
    setDonate(null);
  };

  const Header = () => (
    <Helmet>
      <meta property="og:title" content={event.artist} />
      <meta property="og:type" content="video.other" />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_ASSETS_PATH}/${event?.assets?.preshowBanner?.path}`}
      />
    </Helmet>
  );

  if (
    !ticketStatus?.ticketed ||
    show.start > nowTime.plus({ minutes: show.preshowTransitionMinutes || 60 })
  ) {
    const theme = { ...customTheme, preShow: true };
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <PreShow
          show={show}
          tickets={eventTickets}
          live={show.start < nowTime}
          ticketsClosed={show.closeTickets < nowTime}
        />
      </ThemeProvider>
    );
  }

  const isDark = show.start < nowTime && show.end > nowTime;

  const theme = { ...customTheme, dark: isDark };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Show
        show={show}
        postShow={show.end < nowTime}
        onDonate={handleDonate}
        eventClosed={show.close < nowTime}
      />
      <PaymentSidebar
        items={items}
        onComplete={handleDonateComplete}
        onCancel={handleDonateCancel}
        modal
      />
    </ThemeProvider>
  );
};
