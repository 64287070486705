import React, { useContext } from "react";
import styled from "styled-components";
import { ButtonTertiary, OrDivider, OpenAuth, StoreContext } from "Components";
import { P, H3, GridColumn, Subheader } from "notes";
import { ManualAuth, FormContainer } from "Components";
import { fireAnalytics } from "services";

const signInMessage = `Sign in to your Set.Live account and enjoy front row seats to concerts around the world from the comfort of your couch!`;

export const Login = ({ onSuccess, onSignUpClick, hasTicket }) => {
  const handleSuccess = (value, method) => {
    onSuccess(value);
    if (value) {
      fireAnalytics("login", { method });
    }
  };

  return (
    <FormContainer>
      <Content hasTicket={hasTicket}>
        <H3>{hasTicket ? "Let’s Get You To The Show!" : "Log In"}</H3>
        <P>{signInMessage}</P>

        <OpenAuth onSuccess={handleSuccess} />

        <OrDivider />

        <ManualAuth onSuccess={handleSuccess} />
      </Content>
      <Footer hasTicket={hasTicket}>
        <Subheader>Don't have a Set.Live Account?</Subheader>
        <ButtonTertiary onClick={onSignUpClick}>Sign Up</ButtonTertiary>
      </Footer>
    </FormContainer>
  );
};

const Content = styled(GridColumn)`
  padding: ${(props) => (props.hasTicket ? 0 : "0 40px")};
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const Footer = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.gray.fill};
  border-top: ${(props) =>
    props.hasTicket
      ? "none"
      : `1px solid ${props.theme.palette.gray.lightest}`};
  margin-top: 24px;
  padding: 24px 40px 40px 40px;
  ${Subheader} {
    align-self: center;
    color: ${(props) => props.theme.palette.black};
    margin-bottom: 16px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
    padding: 24px 24px 40px 24px;
  }
`;
