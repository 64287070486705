import React, { useState, useContext, useEffect } from "react";
import { StoreContext, TimeContext } from "Components";
import { Input, ButtonPrimary } from "notes";
import styled from "styled-components";

export const AdminControls = (props) => {
  const { user } = useContext(StoreContext);

  const { setUserOffset } = useContext(TimeContext);
  const [dateTime, setDateTime] = useState();
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    const checkIfAdmin = async () => {
      try {
        let idTokenResult = await user.getIdTokenResult();
        setShowControls(!!idTokenResult?.claims?.admin);
      } catch (error) {
        console.error(`Got error: ${error.code} - ${error.message}`);
        setShowControls(false);
      }
    };

    if (user) {
      checkIfAdmin();
    } else {
      setShowControls(false);
    }
  }, [user]);

  const handleTimeUpdate = () => {
    let newTime = Date.parse(dateTime);

    if (!isNaN(newTime)) {
      console.log(`Time travelling to: ${dateTime}`);
      return setUserOffset(newTime - Date.now());
    }

    console.log(`Invalid date format for time travel`);
  };

  if (!showControls) {
    return null;
  }

  return (
    <ControlsContainer>
      <InputContainer>
        <Input onChange={(val) => setDateTime(val)} value={dateTime} />
      </InputContainer>
      <ButtonPrimary onClick={handleTimeUpdate}>Set New Time</ButtonPrimary>
    </ControlsContainer>
  );
};

const InputContainer = styled.div`
  float: left;
`;

const ControlsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
`;
