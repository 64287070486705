import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridRow,
  H4,
  ButtonSecondarySmall,
  Link,
  Icon,
  TertiaryMenu,
} from "notes";
import {
  ButtonSignUp,
  StoreContext,
  ModalContext,
  NotificationContext,
} from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { logout } from "services";
import { GiftIcon } from "Images";

const StyledIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const GiftIconStyle = styled(GiftIcon)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const menuItems = [
  {
    key: "account",
    icon: <StyledIcon tertiary name="User" />,
    label: "My Account",
  },
  {
    key: "rewards",
    icon: <GiftIconStyle />,
    label: "My Rewards",
  },
  {
    key: "billing",
    icon: <StyledIcon form name="CreditCard" />,
    label: "Billing and Transactions",
  },
  {
    key: "signout",
    icon: <StyledIcon tertiary name="Exit" />,
    label: "Sign Out",
  },
];

export const HeaderActions = ({
  primary,
  inverse,
  onLogin,
  onSignup,
  onHelp,
}) => {
  const { Store, user } = useContext(StoreContext);
  const { newMessageCount } = useContext(NotificationContext);
  const { notificationModal, toggleNotificationModal } = useContext(
    ModalContext
  );
  const {
    toggleLoginModal,
    toggleSignUpModal,
    helpModal,
    toggleHelpModal,
  } = useContext(ModalContext);
  const [username, loading, error] = useDocumentData(
    Store.firestore().doc(`usernames/${user?.uid}`)
  );

  const history = useHistory();

  if (loading) {
    return null;
  }

  const handleLogout = async () => {
    try {
      history.push("/");
      await logout();
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenu = (key) => {
    if (key === "signout") {
      handleLogout();
    } else {
      history.push(`/${key}`);
    }
  };

  return (
    <Actions xEnd yCenter inverse={inverse}>
      <DesktopButtons>
        {/* <HowItWorksLink onClick={onHelp}>How It Works</HowItWorksLink> */}
        {!user && (
          <>
            <LoginButton onClick={toggleLoginModal}>Log In</LoginButton>
            {!primary && (
              <SignUpDesktop onClick={toggleSignUpModal}>Sign Up</SignUpDesktop>
            )}
          </>
        )}
      </DesktopButtons>
      {!user && !primary && (
        <MobileButtons xEnd>
          <SignUpMobile onClick={() => history.push("/signup")}>
            Sign Up
          </SignUpMobile>
          <UserDivider>|</UserDivider>
          <LoginMobile onClick={() => history.push("/login")}>
            Log In
          </LoginMobile>
          {/*
          <HowItWorksMobile xCenter yCenter onClick={onHelp}>
            <HowItWorksIcon
              onClick={() => history.push("/contact")}
              indicator
              name="Questions"
            />
          </HowItWorksMobile>
          */}
        </MobileButtons>
      )}
      {username && user && (
        <>
          <Messages
            xCenter
            yCenter
            onClick={(e) => {
              toggleNotificationModal(
                notificationModal.open
                  ? {
                      open: false,
                      right: 0,
                      top: 0,
                      fixed: false,
                    }
                  : {
                      open: true,
                      right: window.innerWidth - e.pageX - 25,
                      top: e.pageY + 29,
                      fixed: history.location.pathname.startsWith("/event/"),
                    }
              );
            }}
          >
            <MessageIcon tertiary name="Email" />
            <MessageCount>{newMessageCount}</MessageCount>
            {newMessageCount > 0 && <Pulse />}
          </Messages>
          <TertiaryMenu
            offset="0, 10"
            items={menuItems}
            onSelect={(key) => handleMenu(key)}
            trigger={(props) => (
              <UserButton yCenter {...props}>
                <UserIcon
                  image={user.photoURL || user.photoUrl || false}
                  xCenter
                  yCenter
                >
                  {username.value.charAt(0)}
                </UserIcon>
                <UserName>{username.value}</UserName>
                <Caret indicator name="Dropdown" />
              </UserButton>
            )}
          />
        </>
      )}
      <HelpIcon
        indicator
        name="Questions"
        onClick={() => toggleHelpModal(!helpModal)}
      />
    </Actions>
  );
};

const Messages = styled(GridRow)`
  cursor: pointer;
  height: 40px;
  position: relative;
  margin-right: 12px;
  padding: 0 8px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 20px;
  }
`;

const MessageCount = styled(H4)`
  color: #ffffff;
  font-weight: 600;
  position: relative;
  z-index: 2;
`;

const MessageIcon = styled(Icon)`
  margin-right: 8px;
  position: relative;
  z-index: 2;
`;

const HelpIcon = styled(Icon)`
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 24px;
  }
`;

const UserButton = styled(GridRow).attrs({ as: "div" })`
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0;
  }
`;

const UserIcon = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.orange.primary};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  border-radius: 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-right: 16px;
  overflow: hidden;
  text-transform: uppercase;
  text-indent: ${(props) => (!!props.image ? "-50px" : 0)};
  width: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 7px;
  }
`;

const Caret = styled(Icon)`
  path {
    fill: #fff;
  }
`;

const UserName = styled(H4)`
  color: #fff;
  font-weight: 600;
  margin-right: 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const DesktopButtons = styled(GridRow)`
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const MobileButtons = styled(GridRow)`
  display: none;
  @media only screen and ${(props) => props.theme.media.small} {
    display: flex;
  }
`;

const HowItWorksLink = styled(Link)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  height: 40px;
  margin-right: 24px;
  padding: 0 8px;
  &:hover,
  &:focus {
    color: #fff;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 12px;
    margin-right: 0;
  }
`;

const HowItWorksMobile = styled(GridRow)`
  height: 40px;
  width: 38px;
`;

const HowItWorksIcon = styled(Icon)`
  path {
    fill: #fff;
  }
  height: 16px;
  width: 16px;
`;

const LoginButton = styled(ButtonSecondarySmall)`
  border-color: #fff;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #fff;
    border-color: "#fff";
    color: ${(props) => props.theme.colors.text};
  }
  margin-right: 20px;
`;

const SignUpDesktop = styled(ButtonSignUp)`
  margin-left: 24px;
  margin-right: 20px;
`;

const LoginMobile = styled(Link)`
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  padding: 0 8.5px;
  &:hover,
  &:focus {
    color: #fff;
  }
  margin-right: 10px;
`;

const SignUpMobile = styled(LoginMobile)``;

const UserDivider = styled(LoginMobile)`
  padding: 0;
`;

const Actions = styled(GridRow)`
  height: 40px;
  ${HowItWorksLink} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `color: ${props.theme.palette.black};`};
  }
  ${UserName} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `color: ${props.theme.palette.black};`};
  }
  ${Caret} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `path {
      fill: ${props.theme.palette.black};
    }`}
  }
  ${LoginButton} {
    ${(props) =>
      props.inverse &&
      !props.theme.dark &&
      `color: ${props.theme.palette.black}; border: 2px solid ${props.theme.palette.gray.primary};`}
  }
  ${HelpIcon} {
    path {
      fill: ${(props) =>
        props.inverse
          ? props.theme.dark
            ? "#ffffff"
            : props.theme.palette.black
          : "#ffffff"};
    }
  }
  ${MessageIcon} {
    path {
      fill: ${(props) =>
        props.inverse
          ? props.theme.dark
            ? "#ffffff"
            : props.theme.palette.black
          : "#ffffff"};
    }
  }
  ${MessageCount} {
    color: ${(props) =>
      props.inverse
        ? props.theme.dark
          ? "#ffffff"
          : props.theme.palette.black
        : "#ffffff"};
  }
`;

const Pulse = styled.div`
  border-radius: 50%;
  position: absolute;
  height: 40px;
  width: 40px;
  animation: pulse-orange 2s infinite;
  z-index: 1;

  @keyframes pulse-orange {
    0% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 1);
    }

    70% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 0 15px rgba(255, 140, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }
`;
