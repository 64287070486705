import React from "react";
import styled from "styled-components";
import { GridColumn, H3 } from "notes";
import { FooterBlock } from "Components";
import { LogoSetLiveBlack } from "Images";

export const FormWrapper = ({ children, hideFooter, ...props }) => {
  return (
    <Wrapper xCenter hideFooter={hideFooter} {...props}>
      <SetLiveLogo />
      {children}
      {!hideFooter && <Footer tertiary />}
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  padding-bottom: ${(props) => (props.hideFooter ? 0 : "133px")};
  position: relative;
  width: 100%;
  min-height: 100vh;
  ${H3} {
    line-height: 24px;
  }
`;

const SetLiveLogo = styled(LogoSetLiveBlack)`
  margin-top: 64px;
  height: 36px;
  width: 114px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 48px;
    height: 24px;
    width: 76px;
  }
`;

const Footer = styled(FooterBlock)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`;
