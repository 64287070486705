import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridRow, Icon, H4, TertiaryMenu, withDefaultMedia } from "notes";
import { ButtonLink } from "../ButtonLink";
import { StoreContext, ModalContext, NotificationContext } from "Components";
import { logout } from "services";
import { LogoSetLiveWhite } from "Images";

const StyledIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
`;

const menuItems = [
  {
    key: "account",
    icon: <StyledIcon tertiary name="User" />,
    label: "My Account",
  },
  {
    key: "rewards",
    icon: <StyledIcon tertiary name="Gift" />,
    label: "My Rewards",
  },
  {
    key: "billing",
    icon: <StyledIcon form name="CreditCard" />,
    label: "Billing and Transactions",
  },
  {
    key: "signout",
    icon: <StyledIcon tertiary name="Exit" />,
    label: "Sign Out",
  },
];

export const Header = withDefaultMedia(({ matches }) => {
  const { user, username } = useContext(StoreContext);
  const { newMessageCount } = useContext(NotificationContext);
  const {
    toggleSignUpModal,
    toggleHelpModal,
    toggleNotificationModal,
    notificationModal,
  } = useContext(ModalContext);

  const history = useHistory();

  const handleLogout = async () => {
    try {
      history.push("/");
      await logout();
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenu = (key) => {
    if (key === "signout") {
      handleLogout();
    } else {
      history.push(`/${key}`);
    }
  };

  return (
    <Wrapper>
      <Content yCenter>
        <SetLiveLogo onClick={() => history.push("/")} />
        <Actions>
          {user ? (
            <>
              <StyledButtonLink
                color="white"
                onClick={() => history.push("/rewards")}
              >
                My Rewards
              </StyledButtonLink>
              <Messages
                xCenter
                yCenter
                onClick={(e) => {
                  toggleNotificationModal(
                    notificationModal.open
                      ? {
                          open: false,
                          right: 0,
                          top: 0,
                          fixed: false,
                        }
                      : {
                          open: true,
                          right: window.innerWidth - e.pageX - 25,
                          top: e.pageY + 29,
                          fixed: history.location.pathname.startsWith(
                            "/event/"
                          ),
                        }
                  );
                }}
              >
                <MessageIcon tertiary name="Email" />
                {newMessageCount > 0 && <Pulse />}
              </Messages>
              <TertiaryMenu
                offset="0, 10"
                items={menuItems}
                onSelect={(key) => handleMenu(key)}
                trigger={(props) => (
                  <UserButton yCenter {...props}>
                    <UserIcon
                      image={user.photoURL || user.photoUrl || false}
                      xCenter
                      yCenter
                    >
                      {username?.charAt(0)}
                    </UserIcon>
                    <Caret indicator name="Dropdown" />
                  </UserButton>
                )}
              />
            </>
          ) : (
            <StyledButtonLink
              color="white"
              onClick={
                matches.small
                  ? () => history.push("/signup")
                  : () => toggleSignUpModal(true)
              }
            >
              <Icon tertiary name="User" />
              Log In / Register
            </StyledButtonLink>
          )}
          <HelpIcon
            indicator
            name="Questions"
            onClick={() => toggleHelpModal(true)}
          />
        </Actions>
      </Content>
      <Gradient />
    </Wrapper>
  );
});

const Wrapper = styled(GridRow)`
  position: absolute;
  overflow: hidden;
  top: 0;
  z-index: 100;
  height: 88px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 48px;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.52;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const Content = styled(GridRow)`
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  position: relative;
  width: 100%;
  z-index: 1;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const SetLiveLogo = styled(LogoSetLiveWhite)`
  cursor: pointer;
  height: 36px;
  width: 114px;
  flex-shrink: 0;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 24px;
    width: 76px;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  svg {
    margin-right: 8px;
    path {
      fill: #ffffff;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 12px;
    line-height: 15px;
    padding: 0 4px;
    height: 24px;
  }
`;

const Actions = styled(GridRow)`
  align-items: center;
  & > ${H4} {
    color: #fff;
    font-weight: 600;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    & > ${H4} {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

const Messages = styled(GridRow)`
  cursor: pointer;
  height: 40px;
  position: relative;
  margin-left: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 16px;
  }
`;

const MessageIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  path {
    fill: #fff;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    width: 16px;
    height: 16px;
  }
`;

const MessageCount = styled(H4)`
  color: #ffffff;
  font-weight: 600;
  position: relative;
  z-index: 2;
`;

const UserButton = styled(GridRow).attrs({ as: "div" })`
  cursor: pointer;
  display: flex;
  height: 40px;
  margin-left: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 24px;
    margin-left: 16px;
  }
`;

const UserIcon = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.orange.primary};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  border-radius: 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-right: 8px;
  margin-left: 8px;
  overflow: hidden;
  text-transform: uppercase;
  text-indent: ${(props) => (!!props.image ? "-50px" : 0)};
  width: 32px;
  height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 7.2px;
    margin-right: 4px;
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }
`;

const Caret = styled(Icon)`
  path {
    fill: #fff;
  }
`;

const HelpIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: 24px;
  cursor: pointer;
  path {
    fill: #fff;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 16px;
    width: 14px;
    height: 14px;
  }
`;

const Pulse = styled.div`
  border-radius: 50%;
  position: absolute;
  height: 40px;
  width: 40px;
  animation: pulse-orange 2s infinite;
  z-index: 1;

  @keyframes pulse-orange {
    0% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 1);
    }

    70% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 0 15px rgba(255, 140, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }
`;
