import React, { createContext, useContext, useState, useEffect } from "react";
import { StoreContext } from "Components";
import _ from "lodash";

export const NotificationContext = createContext();
export const NotificationConsumer = NotificationContext.Consumer;

const messagesRef = React.createRef();

export const NotificationProvider = ({ children }) => {
  const [newMessageCount, setNewMessageCount] = useState(0);

  const { Store, user } = useContext(StoreContext);

  const sortMessages = (data) => {
    messagesRef.current = data.sort((a, b) => {
      try {
        return b.createdAt.seconds - a.createdAt.seconds;
      } catch (err) {
        return 0;
      }
    });
    setNewMessageCount(
      messagesRef.current.filter((msg) => !msg?.readAt).length
    );
  };

  const addMessages = (snaps) => {
    if (snaps.docs.length > 0) {
      const newMessages = [];
      snaps.forEach((doc) => {
        const index = _.findIndex(
          messagesRef.current,
          ({ id }) => id === doc.id
        );
        if (index >= 0) {
          messagesRef.current[index] = {
            id: doc.id,
            ...doc.data(),
          };
        } else {
          newMessages.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      sortMessages(messagesRef.current.concat(newMessages));
    }
  };

  const addListener = () => {
    Store.firestore()
      .collection("messages")
      .where("uid", "==", user.uid)
      .where("readAt", "==", null)
      .where("deletedAt", "==", null)
      .onSnapshot(addMessages);
  };

  const markMessagesAsRead = async () => {
    try {
      const newMessages = messagesRef.current.filter((msg) => !msg?.readAt);
      if (!newMessages.length) return;
      const batch = Store.firestore().batch();
      newMessages.map(async (msg) => {
        const ref = Store.firestore().collection("messages").doc(msg.id);
        await batch.update(ref, {
          readAt: Store.firestore.FieldValue.serverTimestamp(),
        });
      });
      await batch.commit();
      sortMessages(
        messagesRef.current.map((msg) =>
          !!msg.readAt ? msg : { ...msg, readAt: Date.now() }
        )
      );
      setNewMessageCount(0);
    } catch (err) {}
  };

  useEffect(() => {
    if (user) {
      messagesRef.current = [];
      const load = async () => {
        const ref = Store.firestore()
          .collection("messages")
          .where("uid", "==", user.uid)
          .where("deletedAt", "==", null);
        const docs = await ref.get();
        let results = [];
        docs.forEach((doc) => {
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        sortMessages(results);
      };
      load();
      addListener();
    } else {
      //setMessages([]);
      messagesRef.current = [];
      setNewMessageCount(0);
    }
  }, [user]);

  const value = {
    messages: messagesRef.current,
    newMessageCount,
    markMessagesAsRead,
  };

  return (
    <NotificationContext.Provider value={{ ...value }}>
      {children}
    </NotificationContext.Provider>
  );
};
