import Joi from "@hapi/joi";
export const rewardSchemaUSA = Joi.object({
  name: Joi.object({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
  }),
  country: Joi.string().min(1).required(),
  addressLine1: Joi.string().min(1).required(),
  city: Joi.string().min(1).max(50).required(),
  state: Joi.string().min(1).required(),
  zipCode: Joi.string().min(5).max(5).required(),
});

export const rewardSchemaNotUSA = Joi.object({
  name: Joi.object({
    firstName: Joi.string().min(1).required(),
    lastName: Joi.string().min(1).required(),
  }),
  country: Joi.string().min(1).required(),
  addressLine1: Joi.string().min(1).required(),
  state: Joi.string().min(1).required(),
});

export const accountSchemaUSA = Joi.object({
  country: Joi.string().min(1).required(),
  addressLine1: Joi.string().min(1).required(),
  city: Joi.string().min(1).max(50).required(),
  state: Joi.string().min(1).required(),
  zipCode: Joi.string().min(5).max(5).required(),
});

export const accountSchemaNotUSA = Joi.object({
  country: Joi.string().min(1).required(),
  addressLine1: Joi.string().min(1).required(),
  state: Joi.string().min(1).required(),
});
