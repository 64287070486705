import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, ButtonPrimary, H3, Subheader, H2, P } from "notes";
import { Duration } from "luxon";
import { formatCurrency, TimeContext } from "Components";
import {
  Image,
  EventDetails,
  Divider,
  PerforatedLine,
  Requests,
} from "./Components";
import { Separator, TicketIcon, SoldOut } from "../Desktop";
import { PackageNotification, AddToCalendar } from "../Components";

export const TicketSmall = ({
  show,
  purchased,
  onActionClick,
  onScrollClick,
  calendarEvent,
  ticketsClosed,
  ...props
}) => {
  const { preShow } = useContext(ThemeContext);
  const { time: nowTime } = useContext(TimeContext);
  const history = useHistory();
  let topPackage = show?.paymentItems.reduce(
    (max, item) => (max.amount > item.amount ? max : item),
    0
  );
  const timeToStart = Duration.fromMillis(show.start - nowTime).shiftTo(
    "minutes",
    "seconds",
    "milliseconds"
  );
  const showPreShowActions =
    show.start > nowTime.plus({ minutes: show.preshowTransitionMinutes || 60 });
  const isPastShow = show.end < nowTime;
  return (
    <TicketContainer {...props}>
      <Image image={show.image}>
        {isPastShow ? (
          <ClosedText>Sorry, This Show Has Ended</ClosedText>
        ) : (
          <EventDetails date={show.date} time={show.time} start={show.start} />
        )}
      </Image>

      <Divider purchased={purchased} />

      <ArtistInfo xCenter purchased={purchased}>
        <ArtistName purchased={purchased}>{show.artist}</ArtistName>
        <StyledHeader>{show.subtitle}</StyledHeader>
      </ArtistInfo>

      <PerforatedLine purchased={purchased} />

      <BottomContainer purchased={purchased}>
        {isPastShow ? (
          <Purchase onClick={() => history.push("/")}>
            View Other Available Shows
          </Purchase>
        ) : (
          <>
            {!ticketsClosed || purchased ? (
              <>
                {!preShow && purchased && !showPreShowActions && (
                  <ShowStarted>
                    {show.start > nowTime
                      ? !timeToStart.minutes < 1
                        ? `Show Starts in ${timeToStart.minutes} Min${
                            timeToStart.minutes === 1 ? "" : "s"
                          }`
                        : `Show Starts in ${timeToStart.seconds} Sec${
                            timeToStart.seconds === 1 ? "" : "s"
                          }`
                      : "Your Show Has Started!"}
                  </ShowStarted>
                )}
                {preShow &&
                  purchased &&
                  (show.requests ? (
                    <Requests
                      content="Vote for your show’s set list"
                      buttonText="Go To Set The Set"
                      onClick={onActionClick}
                    />
                  ) : (
                    <ReturnText>Return here at showtime to watch!</ReturnText>
                  ))}
                {!purchased && (show.paymentItems.length < 2 || !preShow) && (
                  <Price>{show.priceLabel}</Price>
                )}
                {(!purchased || !preShow) && (
                  <Purchase onClick={onActionClick}>
                    {preShow ? (
                      show.paymentItems.length > 1 ? (
                        <>
                          Get Your Ticket {<Separator />}{" "}
                          {formatCurrency(show.minPrice)}
                        </>
                      ) : (
                        "Get Your Front Row Ticket"
                      )
                    ) : !showPreShowActions && purchased ? (
                      <>
                        <TicketIcon tertiary name="Ticket" />
                        Click Here To Join The Show!
                      </>
                    ) : (
                      "View Show Details"
                    )}
                  </Purchase>
                )}
                {purchased && showPreShowActions && (
                  <AddToCalendar event={calendarEvent} />
                )}
                {!purchased && preShow && show.paymentItems.length > 1 && (
                  <PackageNotification
                    image={topPackage.assets.primary?.path}
                    eventId={show.id}
                    text={
                      show.packageIncentive ??
                      "Want exclusive show merch or a VIP experience?"
                    }
                    linkText={"Learn More"}
                    onClick={onScrollClick}
                  />
                )}
              </>
            ) : (
              <>
                <SoldOutStyle xCenter>Sale Has Ended</SoldOutStyle>
                {!preShow && (
                  <Purchase onClick={onActionClick}>View Show Details</Purchase>
                )}
              </>
            )}
          </>
        )}
      </BottomContainer>
    </TicketContainer>
  );
};

const StyledHeader = styled(Subheader)`
  color: ${(props) => props.theme.palette.black};
  font-size: 17px;
  line-height: 22px;
`;

const ClosedText = styled(H2)`
  color: #ffffff;
  font-size: 17px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 24px;
  z-index: 3;
`;

const SoldOutStyle = styled(SoldOut)`
  margin-top: 0;
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 0;
  color: ${(props) =>
    props.theme.preShow
      ? props.theme.palette.gray.lighter
      : props.theme.palette.black};
`;

const TicketContainer = styled(GridColumn)`
  margin: 0 auto;
  width: 100%;
  & + & {
    margin-top: 24px;
  }
`;

const BottomContainer = styled(GridColumn)`
  background-color: ${(props) =>
    props.purchased && !props.theme.preShow
      ? props.theme.palette.orange.lightest
      : "#ffffff"};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px;
  padding-top: 0;
  text-align: center;
  width: 100%;
`;

const Purchase = styled(ButtonPrimary)`
  margin-top: 16px;
  width: 100%;
`;

const Price = styled(H3)`
  font-size: 25px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0;
  margin-top: 16px;
  text-align: center;
  text-transform: uppercase;
`;

export const ArtistInfo = styled(GridColumn)`
  background-color: ${(props) =>
    props.purchased && !props.theme.preShow
      ? props.theme.palette.orange.lightest
      : "#ffffff"};
  border-width: 0;
  border-color: ${(props) => props.theme.palette.gray.lighter};
  min-height: 138px;
  padding: 40px 24px 24px 24px;
  text-align: center;
  width: 100%;
`;

export const ArtistName = styled(H2)`
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 8px;
`;

const ReturnText = styled(P)`
  font-weight: 800;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const ShowStarted = styled(ReturnText)`
  font-size: 25px;
  line-height: 32px;
  margin-top: 16px;
  margin-bottom: 0;
`;
