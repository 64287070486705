import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import {
  GridColumn,
  Icon,
  Footnote,
  P,
  Input,
  ButtonPrimary,
  GridRow,
  Link,
  H3,
} from "notes";
import { FieldMessage, LoaderInline, FormWrapper } from "Components";

const StretchWrapper = styled(FormWrapper)`
  align-items: stretch;
  max-width: 500px;
  padding: 0 24px;
  margin: 0 auto;
  & > svg {
    margin: 48px auto 0 auto;
  }
`;

export const RedeemForm = ({ onSubmit, error, loading }) => {
  const [code, setCode] = useState("");

  const handleInput = (val) => {
    setCode(val.replace(/[^a-zA-Z0-9-_]/g, ""));
  };

  return (
    <StretchWrapper>
      <H3 style={{ margin: "24px" }}>Redeem Promotional Offer</H3>
      <P style={{ margin: "0 0 24px 0" }}>
        Please enter your code below to redeem your ticket!
      </P>
      <InputContainer>
        <StyledInput
          isError={error}
          value={code}
          onChange={handleInput}
          placeholder="Enter code"
        />
      </InputContainer>
      <FieldMessage
        active={error}
        type={"error"}
        content={"That code does not match any existing offers."}
      />
      <ConfirmButton disabled={loading} onClick={() => onSubmit(code)}>
        {loading ? <LoaderInline /> : "Redeem Offer"}
      </ConfirmButton>
    </StretchWrapper>
  );
};

const Container = styled(GridColumn)``;

const InputContainer = styled.div``;

const Loader = styled(LoaderInline)`
  & {
    top: -15px;
    left: -20px;
  }
`;

const ConfirmButton = styled(ButtonPrimary)`
  margin-top: 40px;
  margin-bottom: 18px;
  & + ${P} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 15px;
    font-weight: 400;
  }
`;

const sharedStyles = `
  border-width: 2px;
  box-shadow: none;
`;

const StyledInput = styled(Input)`
  ${(props) =>
    props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
  ${(props) =>
    props.isError && `border-color: ${props.theme.palette.red.primary};`};
  ${(props) => (props.isSuccess || props.isError) && sharedStyles};

  &:focus {
    ${(props) =>
      props.isError && `border-color: ${props.theme.palette.red.primary};`};
    ${(props) =>
      props.isSuccess && `border-color: ${props.theme.palette.green.primary};`};
    ${(props) => (props.isSuccess || props.isError) && sharedStyles};
  }
`;

const Actions = styled(GridRow)`
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: flex-end;
  }
`;

const LinkStyle = styled(Link)`
  padding: 0 8px;
  height: 40px;
  & + & {
    margin-left: 24px;
  }
`;
