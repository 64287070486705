import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridColumn,
  GridRow,
  P,
  H1,
  ButtonPrimary,
  ButtonSecondary,
  Link,
} from "notes";
import {
  FormWrapper,
  StoreContext,
  TicketStub,
  formatCurrency,
  ModalContext,
  AddToCalendar,
} from "Components";
import { Details } from "./Details";
import {
  useCollectionOnce,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { Container } from "../Enrollment";

export const Purchase = () => {
  const id = localStorage.getItem("eventId");
  const selectedTicket = localStorage.getItem("paymentItemId");
  const voucher = localStorage.getItem("voucher");
  const { Store, user, tickets: userTickets } = useContext(StoreContext);
  const lookup = Store.functions().httpsCallable("vouchers-validate");
  const { toggleConfirmModal } = useContext(ModalContext);
  const history = useHistory();
  const [tickets, setTickets] = useState([]);
  const [apiTickets, loading, error] = useCollectionOnce(
    Store.firestore()
      .collection("payment_items")
      .where("eventId", "==", id)
      .where("type", "==", "ticket")
      .where("status", "==", "public")
      .orderBy("amount", "desc")
  );

  const updateTickets = () => {
    if (apiTickets) {
      let newTickets = apiTickets.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .reverse();
      if (newTickets.length)
        selectedTicket
          ? (newTickets.find(({ id }) => id === selectedTicket).selected = true)
          : (newTickets[0].selected = true);
      setTickets(newTickets);
    }
  };

  useEffect(() => {
    if (voucher) {
      if (!tickets.length) {
        (async () => {
          const voucherData = await lookup({ voucher });
          if (voucherData?.data?.success) {
            setTickets([
              {
                id: voucherData.data.paymentItemId,
                ...voucherData.data.paymentItem,
                voucher: voucher,
                selected: true,
              },
            ]);
          } else {
            updateTickets();
          }
        })();
      }
    } else {
      updateTickets();
    }
  }, [apiTickets]);
  const [event, loadingEvent, errorEvent] = useDocumentDataOnce(
    Store.firestore().doc(`events/${id}`)
  );

  if (!id) {
    history.replace("/");
  }

  if (loading || loadingEvent || !tickets.length) {
    return null;
  }

  let minPrice = tickets.reduce((min, { amount }) => {
    return min && min < amount ? min : amount;
  }, null);

  let maxPrice = tickets.reduce((max, { amount }) => {
    return max && max > amount ? max : amount;
  }, null);

  const show = {
    ...event,
    price: `${formatCurrency(minPrice)} ${
      maxPrice && ` - ${formatCurrency(maxPrice)}`
    }`,
  };

  if (!user) {
    history.replace("/signup");
  }

  const handleSuccess = (response) => {
    if (
      response.context.transactions[0].data.paymentItem.type === "gift_ticket"
    ) {
      history.push(`/giftcodes/${response.context.transactions[0].id}`);
    } else {
      toggleConfirmModal({
        open: true,
        items: response.context.transactions[0].data,
      });
      history.push(`/event/${id}`);
    }
  };

  if (userTickets?.find((ticket) => ticket?.paymentItem?.eventId === id)) {
    return (
      <FormWrapper>
        <Duplicate id={id} show={show} />
      </FormWrapper>
    );
  }

  return (
    <FormWrapper hideFooter>
      <FormFlow showTicket>
        <TicketContainer>
          <TicketStub show={show} />
        </TicketContainer>
        <Details
          tickets={tickets}
          setTickets={setTickets}
          onSuccess={(response) => handleSuccess(response)}
        />
      </FormFlow>
    </FormWrapper>
  );
};

const FormFlow = styled(Container)`
  @media only screen and ${(props) => props.theme.media.small} {
    padding-bottom: 0;
  }
`;

const TicketContainer = styled(GridRow)`
  margin-right: 104px;
  max-width: 393px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 0;
    margin-bottom: 40px;
    max-width: inherit;
    padding: 0 24px;
  }
`;

const Duplicate = ({ id, show }) => {
  console.log(show);
  const history = useHistory();
  const calendarInvite = {
    title: `${show.artist} - ${show.subtitle}`,
    description: show.description,
    location: `https://set.live/event/${id}`,
    startTime: new Date(show.start),
    endTime: new Date(show.end),
  };
  return (
    <DupContainer>
      <H1>You Already Have a Ticket!</H1>
      <P>
        It seems you’ve already purchased a ticket to this show. If you have
        additional questions or want to upgrade your ticket, feel free to
        <Link
          style={{ display: "inline", paddingLeft: "4px" }}
          onClick={() => history.push(`/contact`)}
        >
          contact us.
        </Link>
      </P>
      <AddToCalendar
        style={{ width: "100%", marginTop: "12px" }}
        event={calendarInvite}
      >
        Add To Calendar
      </AddToCalendar>
      <ButtonSecondary
        onClick={() => history.push(`/gift/${id}`)}
        style={{ width: "100%", marginTop: "12px" }}
      >
        Purchase A Ticket For Someone Else
      </ButtonSecondary>
      <ButtonPrimary
        onClick={() => history.push(`/event/${id}`)}
        style={{ width: "100%", marginTop: "12px" }}
      >
        Go Back To Concert Details
      </ButtonPrimary>
    </DupContainer>
  );
};

const DupContainer = styled.div`
  border: 1px solid #a6aeb2;
  border-radius: 8.5px;
  max-width: 470px;
  padding: 40px;
  margin: 40px 0;
`;
