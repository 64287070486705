import React from "react";
import styled from "styled-components";
import { H2, Subheader } from "notes";

export const Artist = ({ name, subtitle }) => {
  return (
    <>
      <ArtistNameStyle>{name}</ArtistNameStyle>
      <Subtitle>{subtitle}</Subtitle>
    </>
  );
};

const ArtistNameStyle = styled(H2)`
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 28px;
    line-height: 32px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 3.067vw;
    line-height: 3.374vw;
    margin-bottom: 0.307vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 4px;
  }
`;

const Subtitle = styled(Subheader)`
  color: ${(props) => props.theme.palette.black};
  font-weight: ${(props) => (props.theme.preShow ? 600 : 700)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => (props.theme.preShow ? "1.917vw" : "14px")};
    line-height: ${(props) => (props.theme.preShow ? "2.454vw" : "18px")};
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) => (props.theme.preShow ? "1.917vw" : "1.074vw")};
    line-height: ${(props) => (props.theme.preShow ? "2.454vw" : "1.687vw")};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: ${(props) => (props.theme.preShow ? "25px" : "14px")};
    line-height: ${(props) => (props.theme.preShow ? "32px" : "18px")};
  }
`;
