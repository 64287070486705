import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Login as LoginComponent,
  FormWrapper,
  PurchaseTicket,
  StoreContext,
} from "Components";
import { Container } from "../Enrollment";

export const Login = () => {
  const { Store } = useContext(StoreContext);
  const history = useHistory();
  const eventId = localStorage.getItem("eventId");
  const loginReturn = localStorage.getItem("loginReturn");

  const handleLogin = (success, method) => {
    if (!!success) {
      if (loginReturn) {
        history.replace(loginReturn);
        localStorage.clear("loginReturn");
      } else {
        !!eventId ? history.push("/purchase") : history.push("/");
      }
      Store.analytics().logEvent("login", { method });
    }
  };

  return (
    <FormWrapper>
      <Container xCenter={!eventId} showTicket={!!eventId}>
        {!!eventId && <PurchaseTicket eventId={eventId} />}
        <LoginComponent
          hasTicket={!!eventId}
          onSuccess={handleLogin}
          onSignUpClick={() => history.push("/signup")}
        />
      </Container>
    </FormWrapper>
  );
};
