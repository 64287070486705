import React, { useContext } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { GridColumn } from "notes";
import { StoreContext, EventContext, SupportLevels } from "Components";

export const SupportTiers = React.memo(({ onDonate }) => {
  const {
    Store,
    user: { uid },
  } = useContext(StoreContext);
  const { event } = useContext(EventContext);
  const { id } = event;

  const [total] = useObjectVal(
    Store.database().ref(`${id}/leaderboard/totals/${uid}`)
  );
  if (typeof total !== "number") {
    return null; //TODO: Nicer loading state
  }

  return (
    <GridColumn style={{ width: "100%" }}>
      <SupportLevels onDonate={onDonate} total={total} />
    </GridColumn>
  );
});
