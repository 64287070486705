import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  H3,
  P,
  Checkbox,
  GridColumn,
  GridRow,
  ButtonSecondary,
  ButtonPrimary,
} from "notes";
import { Shipping as Shipping2, StoreContext } from "Components";
import { Api } from "modules";

export const ShippingForm = ({ rewardId, userResponse, onComplete }) => {
  const [defaultAddress, toggleDefaultAddress] = useState(!userResponse);
  const { user, Store } = useContext(StoreContext);
  const [dirtyState, setDirtyState] = useState(false);

  const handleClose = () => {
    onComplete(false);
  };

  const handleSubmit = (address) => {
    if (address) {
      // Set shipping address in user_reward_items
      Api.updateRewards({
        userRewardId: `${user.uid}#${rewardId}`,
        userResponse: { shippingAddress: address },
        updateAll: defaultAddress,
      }).then(() => handleClose());
    }

    if (address && defaultAddress) {
      // Set default shipping address in profile collection
      delete address["name"];
      Store.firestore()
        .collection("profiles")
        .doc(user.uid)
        .update({ shippingAddress: address });
    }
  };

  return (
    <Container>
      <H3>Shipping Address</H3>
      <P>Please let us know where you would like this item shipped.</P>
      <Shipping2
        includeName
        defaultValue={userResponse}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        onChange={() => setDirtyState(true)}
        actions={({ onCancel, onSubmit }) => (
          <Actions xEnd>
            <Button onClick={onCancel}>Cancel</Button>
            <ButtonPrimary onClick={onSubmit}>Confirm</ButtonPrimary>
          </Actions>
        )}
      >
        {(!userResponse || dirtyState) && (
          <CheckboxStyle
            checked={defaultAddress}
            onChange={toggleDefaultAddress}
            title="Save this as my default shipping address for all of my rewards on Set.Live"
          />
        )}
      </Shipping2>
    </Container>
  );
};

const CheckboxStyle = styled(Checkbox)`
  margin-top: 24px;
  margin-bottom: 0;
`;

const Container = styled(GridColumn)`
  & > ${H3}, & > ${P} {
    color: ${(props) => props.theme.palette.black};
  }
  & > ${H3} {
    margin-bottom: 8px;
  }
  & > ${H3} + ${P} {
    margin-bottom: 8px;
  }
`;

const Actions = styled(GridRow)`
  margin-top: 56px;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

const Button = styled(ButtonSecondary)`
  margin-right: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    order: 2;
    margin-right: 0;
    margin-top: 8px;
  }
`;
