import React, { useState } from "react";
import { P } from "notes";
import {
  FieldContainer,
  FieldLabel,
  EditContainer,
  EditAction,
  InfoIcon,
  PurpleText,
} from "../Name";
import { EditUsername as Edit } from "Components";

export const EditUsername = ({ defaultValue }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <FieldContainer yCenter editMode={editMode}>
      {(!!defaultValue || editMode) && <FieldLabel>Display name</FieldLabel>}
      {editMode ? (
        <EditContainer>
          <Edit secondary onSuccess={() => setEditMode(false)} />
        </EditContainer>
      ) : !!defaultValue ? (
        <>
          <P>{defaultValue}</P>
          <EditAction name="Edit" onClick={() => setEditMode(true)} />
        </>
      ) : (
        <>
          <PurpleText onClick={() => setEditMode(true)}>
            Create Display Name
          </PurpleText>
          {/* <InfoIcon
            indicator
            name="Information"
            onClick={() => setEditMode(true)}
          /> */}
        </>
      )}
    </FieldContainer>
  );
};
