import React, { useContext } from "react";
import { EventContext, StoreContext, formatCurrency } from "Components";
import { SupportLevelBar, H4, P } from "notes";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { useObjectVal } from "react-firebase-hooks/database";

const AnimatedSupportLevelBar = animated(SupportLevelBar);

export const LevelProgress = ({ item, initialTotal }) => {
  const { event } = useContext(EventContext);
  const { Store, user } = useContext(StoreContext);
  const newTotal = initialTotal + item.amount * item.quantity;
  const props = useSpring({
    value: newTotal / 100,
    from: { value: initialTotal / 100 },
  });
  const levels = Object.values(event.supportLevels)
    .map(({ amount, title, selectedIcon, unselectedIcon }) => ({
      value: amount / 100,
      label: "",
      selectedIcon: () =>
        selectedIcon ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_PATH}/${selectedIcon}`}
            height="18"
          />
        ) : null,
      unselectedIcon: () =>
        unselectedIcon ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_PATH}/${unselectedIcon}`}
            height="18"
          />
        ) : null,
    }))
    .sort((a, b) => a.value - b.value);

  const initialLevel = Object.keys(event.supportLevels).reduce(
    (maxKey, currKey) => {
      if (
        (event.supportLevels[maxKey]?.amount || -1) <
          event.supportLevels[currKey].amount &&
        initialTotal >= event.supportLevels[currKey].amount
      ) {
        return currKey;
      }
      return maxKey;
    },
    {}
  );

  const newLevel = Object.keys(event.supportLevels).reduce(
    (maxKey, currKey) => {
      if (
        (event.supportLevels[maxKey]?.amount || -1) <
          event.supportLevels[currKey].amount &&
        newTotal >= event.supportLevels[currKey].amount
      ) {
        return currKey;
      }
      return maxKey;
    },
    {}
  );

  return (
    <Container>
      {initialLevel !== newLevel ? (
        <>
          <Header>YOU LEVELED UP!</Header>
          <CustomBadge
            color={event.supportLevels[newLevel]?.titleColor || "#FF8C00"}
          >
            {event.supportLevels[newLevel]?.selectedIcon && (
              <img
                src={`${process.env.REACT_APP_ASSETS_PATH}/${event.supportLevels[newLevel]?.selectedIcon}`}
                style={{ padding: "0 8px 0 0", maxHeight: "18px" }}
              />
            )}
            {event.supportLevels[newLevel]?.title}
          </CustomBadge>
        </>
      ) : (
        <>
          <Header>{event.supportLevels[newLevel]?.title}</Header>
          <IncreaseAmount color="#FF8C00">
            + {formatCurrency(item.amount * item.quantity)}
          </IncreaseAmount>
          <div style={{ width: "100%" }}>
            <AnimatedSupportLevelBar {...props} sections={levels} />
          </div>
        </>
      )}
      <P style={{ color: "#A6AEB2", padding: "24px 0 0 0" }}>
        You've shown <TotalContainer>{formatCurrency(newTotal)}</TotalContainer>{" "}
        of support in total
      </P>
    </Container>
  );
};

const Header = styled(H4)`
  margin: 0 0 24px 0;
  text-align: center;
  font-weight: 800;
`;

const IncreaseAmount = styled(P)`
  color: #fff;
  padding: 12px 24px;
  font-weight: 800;
  background: #ff8c00;
  border-radius: 46px;
`;

const CustomBadge = styled(P)`
  font-weight: 800;
  box-shadow: 0 0 24px 0 ${(props) => props.color};
  background: ${(props) => props.color};
  color: #fff;
  padding: 12px 24px;
  box-sizing: border-box;
  border-radius: 46px;
  display: flex;
  align-items: center;
`;

const TotalContainer = styled.div`
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 4px;
  padding: 4px 8px;
  background-color: #596266;
  font-weight: 900;
  color: #fff;
`;

const Container = styled.div`
  width: calc(100% + 24px);
  background: #f6f9fc;
  box-sizing: border-box;
  padding: 24px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  ${(props) =>
    props.theme.dark &&
    `
    background: #222
  `}
`;
