import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const Wafer = ({ small, ...props }) => {
  return small ? <WaferSmall {...props} /> : <WaferLarge {...props} />;
};

export const WaferLarge = styled(GridRow)`
  color: ${(props) => props.theme.palette.black};
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 72px;
  background-color: #ffbf00;
  border-radius: 36px;
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.24),
    0 0 8px 0 rgba(0, 0, 0, 0.24), 0 8px 24px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 40px;
    height: 40px;
  }
  ${(props) =>
    props.theme.cardTicket?.past &&
    `
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: none;
    color: #ffffff;
  `};
  @media only screen and ${(props) => props.theme.media.small} {
    height: ${(props) => (props.theme.cardTicket.ticket ? "56px" : "24px")};
    width: ${(props) => (props.theme.cardTicket.ticket ? "56px" : "24px")};
    svg {
      width: ${(props) => (props.theme.cardTicket.ticket ? "33px" : "16px")};
      height: ${(props) => (props.theme.cardTicket.ticket ? "33px" : "16px")};
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    height: 5.52vw;
    width: 5.52vw;
    svg {
      width: 3.07vw;
      height: 3.07vw;
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) =>
      !props.theme.cardTicket.ticket &&
      `
    height: 3.2vw;
    width: 3.2vw;
    svg {
      width: 1.86vw;
      height: 1.86vw;
    }
    `};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    height: 72px;
    width: 72px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const WaferSmall = styled(GridRow)`
  color: #ffffff;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  svg {
    width: 16px;
    height: 16px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    height: 3.07vw;
    width: 3.07vw;
    svg {
      width: 1.23vw;
      height: 1.23vw;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    height: 24px;
    width: 24px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;
