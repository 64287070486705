import React, { useContext } from "react";
import styled from "styled-components";
import { GridRow } from "notes";
import { ReactComponent as MagnifyIcon } from "./Magnify.svg";
import { ModalContext } from "Components";

export const ImageCarousel = ({ primary, images = [], ...props }) => {
  const { togglePackageModal } = useContext(ModalContext);
  const carouselArray = images.map(
    ({ path }) => `${process.env.REACT_APP_ASSETS_PATH}/${path}`
  );
  const containerProps = {
    onClick: carouselArray.length
      ? () =>
          togglePackageModal({
            open: true,
            images: carouselArray,
          })
      : undefined,
  };
  return (
    <ImageContainer
      hasCarousel={!!carouselArray.length}
      xCenter
      yCenter
      {...props}
      {...containerProps}
    >
      <PackageImage
        src={`${process.env.REACT_APP_ASSETS_PATH}/${primary.path}`}
      />
      {!!carouselArray.length && <Magnify />}
    </ImageContainer>
  );
};

const ImageContainer = styled(GridRow)`
  margin-bottom: 24px;
  width: 100%;
  height: 164px;
  cursor: ${(props) => (props.hasCarousel ? "pointer" : "default")};
`;

const PackageImage = styled.img`
  height: 100%;
  width: auto;
`;

const Magnify = styled(MagnifyIcon)`
  cursor: pointer;
  position: absolute;
  top: 27px;
  right: 35px;
  z-index: 2;
  path {
    fill: ${(props) => props.theme.palette.gray.lighter};
  }
`;
