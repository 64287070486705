import React from "react";
import styled from "styled-components";
import { TextField } from "notes";
import { FieldWrapper, Caret } from "notes";

const SelectCore = styled(TextField).attrs(() => ({ as: "select" }))`
  background-color: transparent;
  color: ${(props) => props.theme.colors.border};
  padding-right: 30px;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  z-index: 2;

  &:focus {
    border-color: ${(props) => props.theme.colors.border};
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }
  &:disabled {
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.buttonPrimaryTextDisabled};
    pointer-events: none;
  }
`;

const StyledWrapper = styled(FieldWrapper)`
  ${(props) =>
    props.error && `background-color: ${props.theme.colors.inputErrorBg}`};
  ${(props) =>
    props.disabled &&
    `background-color: ${props.theme.colors.buttonPrimaryBgDisabled}`};
`;

const SelectOption = styled.option``;

const SelectedOption = styled.option.attrs({
  disabled: true,
  selected: true,
  value: "",
})``;

export const NativeSelect = ({
  value = false,
  options,
  onChange,
  disabled,
  defaultValue,
  error,
  ...props
}) => {
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    onChange(e, value);
  };
  const { value: rawValue } = value;
  return (
    <StyledWrapper error={error} disabled={disabled}>
      <SelectCore
        defaultValue={defaultValue}
        {...props}
        error={error}
        disabled={disabled}
        onChange={handleChange}
        value={rawValue}
      >
        <SelectedOption>Select an option...</SelectedOption>
        {options.map((option, index) => (
          <SelectOption key={option.id ? option.id : index}>
            {option.text ? option.text : option.label}
          </SelectOption>
        ))}
      </SelectCore>
      <Caret disabled={disabled}>
        <path d="M0 0h10L5 6z" fill="#222" fill-rule="evenodd" />
      </Caret>
    </StyledWrapper>
  );
};
