import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext, withProtectedRoute } from "Components";
import { GiftForm, CompleteView } from "./Components";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const Gift = withProtectedRoute(
  ({
    match: {
      params: { eventId },
    },
  }) => {
    const { Store, user } = useContext(StoreContext);
    const [result, setResult] = useState();
    const history = useHistory();

    const handleComplete = (res) => {
      if (res.success) {
        history.push(`/giftcodes/${res.context.transactions[0].id}`);
      }
    };

    return <GiftForm eventId={eventId} onComplete={handleComplete} />;
  }
);

export const GiftCodes = ({
  match: {
    params: { transactionId },
  },
}) => {
  const { Store, user } = useContext(StoreContext);
  const [transaction] = useDocumentData(
    Store.firestore().doc(`transactions/${transactionId}`)
  );
  if (!transaction) {
    return null;
  }
  return <CompleteView transaction={transaction} />;
};
