import { withDefaultMedia } from "notes";
import React from "react";

export const TicketSVG = withDefaultMedia(({ matches, ...props }) => {
  return matches.small ? (
    <TicketSVGMobile {...props} />
  ) : (
    <TicketSVGDesktop {...props} />
  );
});

export const TicketSVGDesktop = ({ image, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 424 580"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <path
        id="vbgjanqjf"
        d="M380,0 C386.627417,0 392,5.372583 392,12 L392,366 C380.954305,366 372,374.954305 372,386 C372,397.045695 380.954305,406 392,406 L392,536 C392,542.627417 386.627417,548 380,548 L12,548 C5.372583,548 0,542.627417 0,536 L0,406 C11.045695,406 20,397.045695 20,386 C20,374.954305 11.045695,366 0,366 L0,12 C0,5.372583 5.372583,0 12,0 L380,0 Z M54.1882353,384 L32,384 L32,388 L54.1882353,388 L54.1882353,384 Z M87.9529412,384 L65.7647059,384 L65.7647059,388 L87.9529412,388 L87.9529412,384 Z M122.682353,384 L100.494118,384 L100.494118,388 L122.682353,388 L122.682353,384 Z M156.447059,384 L134.258824,384 L134.258824,388 L156.447059,388 L156.447059,384 Z M190.211765,384 L168.023529,384 L168.023529,388 L190.211765,388 L190.211765,384 Z M223.976471,384 L201.788235,384 L201.788235,388 L223.976471,388 L223.976471,384 Z M257.741176,384 L235.552941,384 L235.552941,388 L257.741176,388 L257.741176,384 Z M291.505882,384 L269.317647,384 L269.317647,388 L291.505882,388 L291.505882,384 Z M326.235294,384 L304.047059,384 L304.047059,388 L326.235294,388 L326.235294,384 Z M360,384 L337.811765,384 L337.811765,388 L360,388 L360,384 Z"
      />
      <filter
        id="lpcazgxlb"
        width="114.3%"
        height="110.2%"
        x="-7.1%"
        y="-3.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <path
        id="tquhiwhwm"
        d="M12,0 L380,0 C386.627417,2.64378502e-14 392,5.372583 392,12 L392,16 L392,16 L0,16 L0,12 C-2.58798134e-15,5.372583 5.372583,-5.58920087e-16 12,0 Z"
      />
      <filter
        id="lmnucblik"
        width="101%"
        height="125%"
        x="-.5%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.5 0"
        />
      </filter>
      <path
        id="xyzfsakrc"
        d="M0,531.5 L392,531.5 L392,536.5 C392,543.127417 386.627417,548.5 380,548.5 L12,548.5 C5.372583,548.5 8.11624501e-16,543.127417 0,536.5 L0,531.5 L0,531.5 Z"
      />
      <filter
        id="pvewaojpc"
        width="101%"
        height="123.5%"
        x="-.5%"
        y="-11.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="-4" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        />
      </filter>
    </defs>
    <g fill="none" transform="translate(16 8)">
      <mask id="fogwvzosh" fill="#fff">
        <use href="#vbgjanqjf" />
      </mask>
      <use fill="#000" filter="url(#lpcazgxlb)" href="#vbgjanqjf" />
      <use fill="#222222" href="#vbgjanqjf" />
      <image
        width="548"
        height="548"
        x="-78"
        mask="url(#fogwvzosh)"
        preserveAspectRatio="xMidYMid slice"
        href={image}
      />
      <g fill="#000" mask="url(#fogwvzosh)">
        <use filter="url(#lmnucblik)" href="#tquhiwhwm" />
      </g>
      <g fill="#000" mask="url(#fogwvzosh)">
        <use filter="url(#pvewaojpc)" href="#xyzfsakrc" />
      </g>
    </g>
  </svg>
);

export const TicketSVGMobile = ({ image, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 434"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <path
        id="ukspqnens"
        d="M280,0 C284.418278,0 288,3.581722 288,8 L288,258 C279.163444,258 272,265.163444 272,274 C272,282.731359 278.993901,289.829205 287.685129,289.996964 L288,290 L288,394 C288,398.418278 284.418278,402 280,402 L8,402 C3.581722,402 0,398.418278 0,394 L0,290 C8.836556,290 16,282.836556 16,274 C16,265.268641 9.00609896,258.170795 0.314870665,258.003036 L0,258 L0,8 C0,3.581722 3.581722,0 8,0 L280,0 Z M40.5517241,273 L24,273 L24,275 L40.5517241,275 L40.5517241,273 Z M65.3793103,273 L48.8275862,273 L48.8275862,275 L65.3793103,275 L65.3793103,273 Z M90.2068966,273 L73.6551724,273 L73.6551724,275 L90.2068966,275 L90.2068966,273 Z M115.034483,273 L98.4827586,273 L98.4827586,275 L115.034483,275 L115.034483,273 Z M139.862069,273 L123.310345,273 L123.310345,275 L139.862069,275 L139.862069,273 Z M164.689655,273 L148.137931,273 L148.137931,275 L164.689655,275 L164.689655,273 Z M189.517241,273 L172.965517,273 L172.965517,275 L189.517241,275 L189.517241,273 Z M214.344828,273 L197.793103,273 L197.793103,275 L214.344828,275 L214.344828,273 Z M264,273 L247.448276,273 L247.448276,275 L264,275 L264,273 Z M239.172414,273 L222.62069,273 L222.62069,275 L239.172414,275 L239.172414,273 Z"
      />
      <filter
        id="mvhnbkfuo"
        width="119.4%"
        height="113.9%"
        x="-9.7%"
        y="-5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="8"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur
          in="shadowOffsetOuter2"
          result="shadowBlurOuter2"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <path
        id="qsvtmmjcz"
        d="M0,386 L288,386 L288,394 C288,398.418278 284.418278,402 280,402 L8,402 C3.581722,402 5.41083001e-16,398.418278 0,394 L0,386 L0,386 Z"
      />
      <filter
        id="fyakhqdqs"
        width="101.4%"
        height="125%"
        x="-.7%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="-4" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
        />
      </filter>
      <path
        id="miushbxdh"
        d="M8,0 L280,0 C284.418278,-8.11624501e-16 288,3.581722 288,8 L288,16 L288,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"
      />
      <filter
        id="skhiavgtd"
        width="101.4%"
        height="125%"
        x="-.7%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.5 0"
        />
      </filter>
    </defs>
    <g fill="none" transform="translate(16 8)">
      <mask id="fiwsbukbr" fill="#fff">
        <use href="#ukspqnens" />
      </mask>
      <use fill="#000" filter="url(#mvhnbkfuo)" href="#ukspqnens" />
      <use fill="#222222" href="#ukspqnens" />
      <image
        width="402"
        height="402"
        x="-57"
        mask="url(#fiwsbukbr)"
        preserveAspectRatio="xMidYMid slice"
        href={image}
      />
      <g fill="#000" mask="url(#fiwsbukbr)">
        <use filter="url(#fyakhqdqs)" href="#qsvtmmjcz" />
      </g>
      <g fill="#000" mask="url(#fiwsbukbr)">
        <use filter="url(#skhiavgtd)" href="#miushbxdh" />
      </g>
    </g>
  </svg>
);
