import React, { createContext, useContext, useState, useEffect } from "react";
import { StoreContext } from "Components";
import _ from "lodash";
import { Icon, Input, Label } from "notes";

export const HelpContext = createContext();
export const HelpConsumer = HelpContext.Consumer;

export const HelpProvider = ({ children }) => {
  const [filter, setFilter] = useState("");
  const [topics, setTopics] = useState({});
  const [filteredTopics, setFilteredTopics] = useState({});
  const [sections, setSections] = useState({});
  const [filteredSections, setFilteredSections] = useState({});
  const [ready, setReady] = useState(false);

  const { Store } = useContext(StoreContext);

  useEffect(() => {
    if (!_.keys(topics).length) {
      init();
    }
    return () => {
      setFilter("");
      setFilteredTopics(topics);
    };
  }, []);

  useEffect(() => {
    if (filter.replace(/\s/gi, "") === "" || filter.length < 3) {
      resetTopics(false);
      return;
    }
    _.debounce(filterTopics, 200, { maxWait: 1000 })();
  }, [filter]);

  const init = async () => {
    setReady(false);
    let snap = await Store.firestore()
      .collection("faq")
      .doc("sections")
      .collection("list")
      .orderBy("sort")
      .get();
    let results = {};
    let topicResults = {};
    if (snap.docs) {
      snap.docs.map((s) => {
        try {
          results[s.data().key] = {
            title: s.data().title,
            topics: [],
          };
        } catch (err) {}
      });
      snap = await Store.firestore()
        .collection("faq")
        .doc("topics")
        .collection("list")
        .orderBy("sort")
        .get();
      snap.docs.map((s) => {
        try {
          s.data().sections.forEach((section) => {
            results[section].topics.push(s.id);
          });
          topicResults[s.id] = s.data();
        } catch (err) {}
      });
    }
    setSections(results);
    setFilteredSections(results);
    setTopics(topicResults);
    setFilteredTopics(topicResults);
    setReady(true);
  };

  const resetTopics = (resetFilter = true) => {
    setFilteredSections(sections);
    setFilteredTopics(topics);
    if (resetFilter) {
      setFilter("");
    }
  };

  const helpFilterInput = (label) => (
    <>
      {label && <Label>{label}</Label>}
      <Input
        name="filter"
        value={filter}
        onChange={(value) => setFilter(value)}
        placeholder="Search for a question or topic by keyword"
        style={{ maxHeight: "3em" }}
        leftIcon={<Icon form name="Search" />}
        rightIcon={
          !!filter && filter.length > 0 ? (
            <Icon form name="RemoveCircle" onClick={() => resetTopics()} />
          ) : null
        }
      />
    </>
  );

  const filterTopics = () => {
    const regex = new RegExp(filter, "gi");
    let sectionResults = {};
    let results = {};
    Object.keys(topics).filter((key) => {
      const topic = topics[key];
      if (
        regex.test(_.get(topic, "title", "")) ||
        regex.test(_.get(topic, "content", "")) ||
        regex.test(_.get(topic, "keywords", ""))
      ) {
        topics[key].sections.forEach((section) => {
          if (!sectionResults.hasOwnProperty(section)) {
            sectionResults[section] = {
              title: sections[section].title,
              topics: [],
            };
          }
          sectionResults[section].topics.push(key);
        });
        results[key] = topics[key];
      }
    });
    setFilteredSections(sectionResults);
    setFilteredTopics(results);
  };

  const value = {
    helpFilter: filter,
    setHelpFilter: setFilter,
    helpSections: filteredSections,
    helpTopics: filteredTopics,
    helpFilterInput,
    helpReady: ready,
  };

  return (
    <HelpContext.Provider value={{ ...value }}>{children}</HelpContext.Provider>
  );
};
