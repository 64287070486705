import styled, { keyframes } from "styled-components";
import { Label, H1, ButtonPrimary, ButtonSecondary, Icon, Link } from "notes";
import { LogoSetLiveWhite } from "Images";

export const PageCover = styled.div`
  text-align: center;
  padding-top: 15%;
  position: absolute;
  width: ${window.screen.width}px;
  height: ${window.screen.height}px;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.white};
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1304px;
  height: 100%;
  margin: 0 auto;
  position: relative;
`;

export const FieldLabel = styled(Label)`
  color: #a6aeb2;
`;

export const CameraWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 680px;
  overflow: hidden;
  background-color: #ffffff;
`;

export const HiddenVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  height: 640px;
  visibility: hidden;
`;

export const PlaybackVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 640px;
  width: 360px;
  z-index: -2;
  object-fit: fill;
`;

export const VideoCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
`;

export const CanvasContainer = styled.div`
  position: relative;
  height: 640px;
  width: 360px;
`;

const VideoIcon = styled.div`
  position: absolute;
  font-size: 42px;
  width: 75px;
  height: 75px;
  z-index: 12;
  left: 50%;
  margin-left: -37.5px;
  cursor: pointer;
`;

export const PlayIcon = styled(VideoIcon)`
  top: 50%;
  margin-top: -37.5px;
`;

export const StopIconContainer = styled(VideoIcon)`
  bottom: 30px;
`;

export const StopIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  path {
    fill: #e45c52;
  }
`;

export const StopIconBackground = styled.div`
  z-index: 1;
  top: 25px;
  left: 25px;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  position: absolute;
`;

export const Dimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: #000000;
  opacity: 0.7;
`;

export const CountdownContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: transparent;
`;

export const CountdownTimer = styled.div`
  position: absolute;
  top: 244px;
  left: 0;
  height: 150px;
  text-align: center;
  width: 100%;
  z-index: 11;
  background-color: transparent;
  color: #ffffff;
`;

export const TimerTitle = styled.span`
  text-transform: uppercase;
  font-weight: 900;
  display: block;
  font-size: 25px;
  line-height: 32px;
  ${(props) => props.theme.fonts.header};
`;

export const CancelTimer = styled.a`
  display: block;
  margin-top: 40px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
  ${(props) => props.theme.fonts.default};
`;

export const Timer = styled.span`
  font-size: 80px;
  height: 80px;
  line-height: 88px;
  margin-top: 20px;
  font-weight: 900;
  display: block;
  ${(props) => props.theme.fonts.header};
`;

export const Uploading = styled.div`
  position: absolute;
  top: 272px;
  left: 0;
  width: 100%;
  height: 25%;
  color: #ffffff;
  background-color: transparent;
  z-index: 12;
  text-align: center;
`;

export const UploadingText = styled.span`
  display: block;
  font-size: 25px;
  line-height: 32px;
  font-weight: 800;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.header};
`;

const SpinnerKeyframes = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: ${SpinnerKeyframes} 2s linear infinite;
  margin: 40px auto;
`;

export const Divider = styled.div`
  margin-top: 10px;
  background-color: ${(props) => props.theme.palette.gray.lightest};
  margin-bottom: 16px;
  width: 100%;
  height: 2px;
`;

export const FormFields = styled.div`
  margin-top: 30px;
`;

export const FieldRow = styled.div`
  margin-bottom: 20px;
`;

export const SetLiveLogo = styled(LogoSetLiveWhite)`
  height: 33px;
  width: 104px;
  margin-left: 40px;
  margin-top: 30px;
`;

export const LogoWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.black};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: 88px;
`;

export const PageHeader = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  height: 135px;
  background: linear-gradient(to right, #ff8c00, #ffb700);
`;

export const HeaderContainer = styled.div`
  margin-left: 144px;
  padding-top: 25px;
`;

export const Header = styled(H1)`
  color: ${(props) => props.theme.colors.white};
`;

export const SubHeader = styled.span`
  display: block;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  ${(props) => props.theme.fonts.header};
  font-weight: 200;
`;

export const EventTitle = styled.div`
  margin-left: 144px;
  width: 100%;
  margin-top: 40px;
  ${(props) => props.theme.fonts.default};
  font-weight: 300;
  font-size: 25px;
  line-height: 39px;
  color: #222222;
`;

export const StatusBar = styled.div`
  margin-left: 144px;
  ${(props) => props.theme.fonts.default};
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.disabledText};
  font-weight: 100;
  margin-bottom: 56px;
`;

export const EventDate = styled.span`
  display: inline-block;
  margin-right: 25px;
`;

export const MessageCount = styled.span`
  display: inline-block;
`;

export const ViewAll = styled.a`
  color: ${(props) => props.theme.colors.action};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
`;

export const NavWrapper = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 17px;
  line-height: 24px;
  width: 98%;
  max-width: 497px;
  height: 57px;
  border: 1px solid #e6e9eb;
  background-color: #fafafa;
`;

export const NavLeft = styled.span`
  display: inline-block;
  width: 21%;
  max-width: 104.5px;
`;

export const NavRight = styled(NavLeft)`
  text-align: right;
`;

export const CurrentMessage = styled.span`
  display: inline-block;
  width: 58%;
  max-width: 288px;
  text-align: center;
`;

export const MessageIndex = styled.span`
  display: block;
  font-size: 12px;
  line-height: 24px;
  color: #596266;
  ${(props) => props.theme.fonts.default};
  font-weight: 200;
`;

export const CurrentUser = styled.span`
  font-weight: bolder;
  display: inline-block;
  line-height: 22px;
  vertical-align: middle;
`;

export const Success = styled.div`
  margin: 10px 0;
  color: #6dcc6a;
  border: 1px solid #a9dda8;
  background-color: #ebf5eb;
  text-align: center;
  font-size: 17px;
  line-height: 24px;
  width: 98%;
  max-width: 497px;
  height: 57px;
`;

export const Error = styled(Success)`
  color: #e45c52;
  border: 1px solid #edb8b4;
  background-color: #faf1f0;
`;

export const Info = styled(Success)`
  color: #276f86;
  border: 1px solid #276f86;
  background-color: #f8ffff;
`;

export const StatusMessage = styled.span`
  margin-top: 1em;
  display: block;
`;

export const SuccessStatusMessage = styled.span`
  margin-top: 10px;
  display: block;
  font-size: 25px;
  line-height: 39px;
  ${(props) => props.theme.fonts.header};
  text-transform: uppercase;
  font-weight: 800;
`;

export const RecordButton = styled(ButtonPrimary)`
  width: 288px;
  border-radius: 25px;
  margin: 40px auto 0;
`;

export const RecordIcon = styled(Icon)`
  path {
    fill: ${(props) =>
      props.disabled === true
        ? props.theme.colors.disabledText
        : props.theme.colors.white};
  }
  margin-right: 0.5em;
`;

export const DeleteIcon = styled(Icon)`
  path {
    fill: #e45c52;
  }
  margin-right: 0.5em;
`;

export const DiscardButton = styled(ButtonSecondary)`
  border: none;
  color: #e45c52;
  width: 288px;
  margin: 10px auto;
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: transparent;
    color: #8f150b;
    box-shadow: none;
    ${DeleteIcon} {
      path {
        fill: #8f150b;
      }
    }
  }
`;

export const Logout = styled.a`
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.default};
  position: absolute;
  right: 40px;
  top: 33px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
`;

export const FullScreenDarkModal = styled.div`
  z-index: 500;
  background-color: ${(props) => props.theme.colors.black || "#000000"};
  color: ${(props) => props.theme.colors.white || "#fffffff"};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${() => window.screen.height}px;
  text-align: center;
  padding-top: 60px;
`;

export const CloseIcon = styled(Icon)`
  path {
    fill: ${(props) => props.theme.colors.white || "#fffffff"};
  }
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 32px;
`;

export const PlaybackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  height: 640px;
  z-index: 10;
`;

export const ViewLinkWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;

export const CenteredViewLink = styled(Link)`
  display: block;
`;

export const ViewLinkIcon = styled(Icon)`
  vertical-align: middle;
`;

export const CheckmarkBox = styled.div`
  background-color: rgb(109, 204, 106);
  display: inline-block;
  border-radius: 28px;
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  line-height: 17px;
`;
