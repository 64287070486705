import styled from "styled-components";

export const ButtonLink = styled.button.attrs(({ color }) => ({
  color: color || "black",
}))`
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: ${(props) =>
    props.color === "black"
      ? "#222222"
      : props.color === "white"
      ? "#ffffff"
      : props.theme.palette[props.color].primary};
  cursor: pointer;
  display: flex;
  font-family: ${(props) => props.theme.fonts.overpass};
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  line-height: 22px;
  outline: 0;
  padding: 0 8px;
  text-align: center;
  height: 40px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.palette.gray.lighter};
  }
`;
