import React, { useState } from "react";
import styled from "styled-components";
import { GridRow, withDefaultMedia } from "notes";
import { DeviceArtwork } from "./Artwork";
import { UsernameForm } from "./Form";
export * from "./Edit";

export const CreateUsername = withDefaultMedia(({ matches, onSuccess }) => {
  const [exampleName, setExampleName] = useState("");
  const handleChange = (value) => {
    setExampleName(value);
  };
  return (
    <Container xCenter yStart>
      {!matches.small && <DeviceArtwork username={exampleName} />}
      <UsernameForm onChange={handleChange} onSuccess={onSuccess} />
    </Container>
  );
});

const Container = styled(GridRow)`
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  margin-top: 72px;
  @media only screen and ${(props) => props.theme.media.medium} {
    justify-content: space-between;
    padding: 0 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    margin-top: 40px;
    padding: 0 24px;
    flex-shrink: 0;
  }
`;
