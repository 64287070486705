import React from "react";
import styled from "styled-components";

export const Loader = ({ active = true, color = "purple" }) => {
  return (
    <LoaderContainer active={active}>
      <LoaderAnimation color={color} />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  align-items: center;
  display: ${(props) => (props.active ? "flex" : "none")};
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
`;

const LoaderAnimation = styled.div`
  &,
  &:before,
  &:after {
    background: ${(props) => props.theme.palette[props.color].primary};
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  & {
    color: ${(props) => props.theme.palette[props.color].primary};
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: "";
  }
  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`;
