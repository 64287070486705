import React, { useContext } from "react";
import styled from "styled-components";
import { ModalContext } from "Components";
import { Modal } from "notes";
import { RemoveCard } from "Components/Payment/Components";

export const RemoveCardModal = ({ open, setOpen }) => {
  const { removeCardModal } = useContext(ModalContext);
  const { card } = removeCardModal;

  const handleClose = () => {
    setOpen({ open: false, card: {} });
  };

  return (
    <ModalStyle size="small" open={open} setOpen={handleClose}>
      <RemoveCard card={card} onComplete={handleClose} />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  margin-top: 8.589vw;
  min-height: 282px;
  & > svg {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 112px;
    max-width: 296px;
    padding: 24px;
  }
`;
