import React from "react";
import styled from "styled-components";
import { CreditCardBadge } from "Components";
import { H4, Footnote, GridRow, GridColumn, Icon } from "notes";

export const CreditCard = ({
  brand,
  last4,
  month,
  year,
  onClick,
  ...props
}) => {
  return (
    <Card yCenter {...props}>
      <CardLogo type={brand} />
      <GridColumn stretch>
        <H4>
          {brand} Ending In {last4}
        </H4>
        <Footnote>
          Expires {month}/{year}
        </Footnote>
      </GridColumn>
      {!!onClick && <Delete onClick={onClick} name="Delete" />}
    </Card>
  );
};

const Card = styled(GridRow)`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 4px;
  margin: 0 auto;
  padding: 16px 24px 16px 12px;
  width: 100%;
  & + & {
    margin-top: 8px;
  }
  ${H4} {
    font-weight: 600;
    text-transform: capitalize;
  }
  ${Footnote} {
    font-style: normal;
  }
`;

const CardLogo = styled(CreditCardBadge)`
  margin-right: 12px;
`;

const Delete = styled(Icon)`
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.gray.primary};
  }
  &:hover {
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
`;
