import React, { useContext } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useHistory } from "react-router-dom";
import { MAXTheme, GridColumn, H3 } from "notes";
import { Header, CardTicket, FooterBlock, StoreContext } from "Components";
import { TicketProvider, TicketContext } from "Providers";
import { fireAnalytics } from "services";
import { MyTickets } from "./MyTickets";
import { Hero } from "./Hero";
import { Musicians } from "./Musicians";
import { CreateAccount } from "./CreateAccount";

export const refreshTheme = {
  ...MAXTheme,
  media: {
    ...MAXTheme.media,
    xSmall: "(max-width: 500px)",
    small: "(max-width: 767px)",
    medium: "(min-width: 768px) and (max-width: 1303px)",
    large: "(min-width: 1304px)",
    extraLarge: "(min-width: 1720px)",
  },
  typography: {
    ...MAXTheme.typography,
    h3: {
      ...MAXTheme.typography.h3,
      fontFamily: MAXTheme.fonts.workSans,
    },
  },
};

export const Landing = ({ ...props }) => {
  return (
    <TicketProvider>
      <LandingInternal {...props} />
    </TicketProvider>
  );
};

export const LandingInternal = () => {
  const { user } = useContext(StoreContext);
  const history = useHistory();

  const handleAction = (event) => {
    fireAnalytics("select_content", {
      content_type: "event",
      content_id: event.id,
      items: [
        {
          id: event.id,
          name: event.title,
          brand: null,
          category: "event",
          variant: event.artist,
          list_name: event.artist,
          list_position: 1,
          quantity: 1,
          price: 0,
        },
      ],
    });
    if (event.url) {
      window.location = event.url;
    } else if (event.hasReplay) {
      history.push(`/event/${event.hasReplay}`);
    } else {
      history.push(`/event/${event.id}`);
    }
  };

  const { myTickets, recentTickets, upcomingTickets } = useContext(
    TicketContext
  );

  return (
    <ThemeProvider theme={refreshTheme}>
      <Wrapper>
        <Header />
        <Hero />
        {!!myTickets.length && (
          <MyTickets
            tickets={myTickets}
            onClick={(event) => handleAction(event)}
          />
        )}
        <Container>
          {!!upcomingTickets.length && (
            <Section>
              <H3>Upcoming Shows</H3>
              <UpcomingWrapper>
                {upcomingTickets.map((event) => (
                  <CardTicket
                    key={event.id}
                    onClick={() => handleAction(event)}
                    event={event}
                  />
                ))}
              </UpcomingWrapper>
            </Section>
          )}
          <Section>
            <H3>Past Shows Include</H3>
            <PastWrapper>
              {recentTickets.map((event) => (
                <CardTicket
                  key={event.id}
                  onClick={() => handleAction(event)}
                  past
                  event={event}
                />
              ))}
            </PastWrapper>
          </Section>
        </Container>
        <Musicians />
        {!user && <CreateAccount />}
        <StyledFooter />
      </Wrapper>
    </ThemeProvider>
  );
};

const StyledFooter = styled(FooterBlock)`
  margin-top: 96px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 72px;
  }
`;

const Wrapper = styled(GridColumn)`
  background: #000000;
  width: 100%;
`;

const Container = styled(GridColumn)`
  padding: 0 40px;
  position: relative;
  width: 100%;
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const Section = styled(GridColumn)`
  width: 100%;
  margin: 72px auto 0 auto;
  & > ${H3} {
    color: #ffffff;
    margin-bottom: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 56px auto 0 auto;
    & > ${H3} {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
`;

const UpcomingWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    grid-template-columns: 1fr 1fr;
    gap: 40px 16px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px 24px;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    grid-template-columns: repeat(auto-fit, 392px);
    gap: 24px 24px;
  }
`;

const PastWrapper = styled(UpcomingWrapper)`
  grid-template-columns: auto auto auto auto;
  @media only screen and ${(props) => props.theme.media.small} {
    grid-template-columns: 1fr 1fr;
    gap: 24px 16px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 24px 24px;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    grid-template-columns: repeat(auto-fit, 288px);
    gap: 24px 24px;
  }
`;
