import React from "react";
import styled from "styled-components";
import {
  GridColumn,
  Label,
  Icon,
  Link,
  H3,
  GridRow,
  P,
  ButtonSecondary,
} from "notes";
export * from "./RequirementModal";
export const Detail = ({ label, children }) => {
  return (
    <Column>
      <LabelStyle>{label}</LabelStyle>
      {children}
    </Column>
  );
};

const LabelStyle = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-weight: 300;
  margin-top: 24px;
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 16px;
  }
`;

const Column = styled(GridColumn)`
  width: 50%;
  margin-right: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
  }
`;

export const EditLink = ({ onClick }) => {
  return (
    <LinkStyle onClick={onClick}>
      <Icon tertiary name="Edit" /> Edit
    </LinkStyle>
  );
};

const LinkStyle = styled(Link)`
  margin-top: 16px;
  svg {
    margin-right: 8px;
    path {
      fill: ${(props) => props.theme.palette.purple.primary};
    }
  }
  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.palette.purple.darker};
      }
    }
  }
`;

export const Form = styled(GridColumn)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  & > ${H3} {
    margin-bottom: 8px;
  }
  & > ${H3} + ${P} {
    margin-bottom: 8px;
  }
`;

export const Actions = styled(GridRow)`
  margin-top: 56px;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

export const CancelButton = styled(ButtonSecondary)`
  margin-right: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    order: 2;
    margin-right: 0;
    margin-top: 8px;
  }
`;
