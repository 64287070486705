import { formatCurrency } from "Components";

export const removeDuplicates = (array, key) => {
  let lookup = new Set();
  return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
};

const renderTicketRow = (paymentItems) => {
  let tickets = [];
  let ticketTypes = removeDuplicates(paymentItems, "paymentItemId").map(
    ({ paymentItemId }) => paymentItemId
  );

  ticketTypes.forEach((id) => {
    let items = paymentItems.filter((item) => item.paymentItemId === id);
    let purchaseTotal = getTotal(items);
    tickets.push({
      quantity: items.length,
      label: items[0].paymentItem.label,
      total: purchaseTotal > 0 ? formatCurrency(purchaseTotal) : "Free",
    });
  });
  return tickets;
};

const getTotal = (items) => {
  if (!items) {
    return 0;
  }
  let total = items.reduce((accum, item) => accum + item.amount, 0);
  return total;
};

const getLabel = (type) => {
  switch (type) {
    case "donate":
      return "Show Support";
    case "song":
      return "Vote For Song";
    case "superchat":
      return "Pinned Message";
    default:
      break;
  }
};

const getUniqueTypes = (items) => {
  let flatTypes = items.map((payment) => payment.paymentItem.type);
  const uniqueTypes = [...new Set(flatTypes)];
  return uniqueTypes;
};

export const filterTransactions = (transactions, source) => {
  let tickets = [];
  let inShowPurchases = [];
  let inShowPurchasesTotal = 0;
  let types = getUniqueTypes(transactions);

  const vouchers = transactions
    .filter(({ metadata }) => metadata?.vouchers)
    .map((transaction) => {
      return {
        code: transaction.metadata?.vouchers,
        label: transaction.paymentItem.label,
      };
    });

  types.forEach((type) => {
    let transactionsByType = transactions.filter(
      (item) => item.paymentItem.type === type
    );
    if (type === "ticket" || type === "gift_ticket") {
      tickets = tickets.concat(renderTicketRow(transactionsByType));
    } else {
      let total = getTotal(transactionsByType);
      if (total > 0) inShowPurchasesTotal += total;

      inShowPurchases.push({
        label: getLabel(transactionsByType[0].paymentItem.type),
        total: total > 0 ? formatCurrency(total) : "Free",
      });
    }
  });

  return {
    vouchers: vouchers,
    tickets: tickets,
    inShowPurchases: inShowPurchases,
    inShowPurchasesTotal: inShowPurchasesTotal,
    totalCharged: getTotal(transactions),
    source: source,
  };
};
