import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const PerforatedLine = ({ purchased, ...props }) => {
  return (
    <CutLineStyle purchased={purchased} yCenter>
      <WhiteSpace purchased={purchased} yCenter>
        <Line />
      </WhiteSpace>
    </CutLineStyle>
  );
};

const WhiteSpace = styled(GridRow)`
  background-color: ${(props) =>
    props.purchased && !props.theme.preShow
      ? props.theme.palette.orange.lightest
      : "#ffffff"};
  height: 24px;
  justify-content: space-between;
  position: relative;
  margin: 0 12px;
  width: 100%;
`;

const CutLineStyle = styled(GridRow)`
  height: 24px;
  width: 100%;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    transform: rotate(-90deg);
    position: absolute;
    left: -12px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    box-shadow: 0px 32px 0px 24px
      ${(props) =>
        props.purchased && !props.theme.preShow
          ? props.theme.palette.orange.lightest
          : "#ffffff"};
  }
  &:after {
    content: "";
    transform: rotate(90deg);
    position: absolute;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    box-shadow: 0px 32px 0px 24px
      ${(props) =>
        props.purchased && !props.theme.preShow
          ? props.theme.palette.orange.lightest
          : "#ffffff"};
  }
`;

const Line = styled.div`
  background-image: linear-gradient(
    to right,
    #a6aeb2 65%,
    rgba(166, 174, 178, 0) 0%
  );
  background-position: left;
  background-size: 17px 2px;
  background-repeat: repeat-x;
  margin-left: 10px;
  margin-right: 10px;
  height: 2px;
  width: 100%;
  z-index: 2;
`;
