export const RECORDING_STATUS = {
  NONE: 0,
  PREPARING: 1,
  RECORDING: 2,
  STOPPED: 3,
  UPLOADING: 4,
  UPLOADED: 5,
  ERROR: 99,
};

export const PLAYBACK_STATUS = {
  NONE: 0,
  PLAYING: 1,
  PAUSED: 2,
  STOPPED: 3,
  ENDED: 4,
};

export const MESSAGE_STATUS = {
  PENDING: "processing",
  COMPLETED: "shipped",
};

const ua = navigator.userAgent.toLowerCase();
export const isSafari = ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
