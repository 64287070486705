import React from "react";
import styled from "styled-components";
import { GridRow, P, withDefaultMedia } from "notes";
import { Link } from "Components";

export const PackageNotification = withDefaultMedia(
  ({ matches, text, imagePath, linkText, onClick, children }) => {
    return (
      <Container yCenter>
        {!matches.small && !!imagePath && (
          <ImageContainer>
            <PackageImage
              src={`${process.env.REACT_APP_ASSETS_PATH}/${imagePath}`}
            />
          </ImageContainer>
        )}
        <TextContainer yCenter>
          <PackageText hasImage={imagePath}>
            {text} <Link onClick={onClick}>{linkText}</Link>
          </PackageText>
        </TextContainer>
        {children}
      </Container>
    );
  }
);

const PackageImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Container = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.orange.lightest};
  border: 1px solid ${(props) => props.theme.palette.orange.lighter};
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  padding: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    background-color: transparent;
    border: none;
    padding: 0;
  }
`;

const ImageContainer = styled(GridRow)`
  margin-right: 12px;
  max-width: 80px;
`;

const PackageText = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  line-height: 24px;
  text-align: ${(props) => (props.hasImage ? "left" : "center")};
  @media only screen and ${(props) => props.theme.media.small} {
    text-align: center;
  }
`;

const TextContainer = styled(GridRow)`
  @media only screen and ${(props) => props.theme.media.small} {
    min-height: 56px;
  }
`;
