import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, GridRow, ButtonPrimary, H3, P, Icon } from "notes";
import { Duration } from "luxon";
import { formatCurrency, TimeContext } from "Components";
import {
  PurchasedBadge,
  Artist,
  Image,
  EventDetails,
  Description,
  Requests,
  Divider,
} from "./Components";
import { PackageNotification, AddToCalendar } from "../Components";

export const TicketLarge = ({
  show,
  purchased,
  children,
  onActionClick,
  onScrollClick,
  calendarEvent,
  ticketsClosed,
  ...props
}) => {
  const { preShow } = useContext(ThemeContext);
  const { time: nowTime } = useContext(TimeContext);
  const history = useHistory();

  if (!show) {
    return null;
  }

  let topPackage = show?.paymentItems.reduce(
    (max, item) => (max.amount > item.amount ? max : item),
    0
  );

  const image = show.incentiveImage || topPackage.assets?.primary?.path || null;

  const timeToStart = Duration.fromMillis(show.start - nowTime).shiftTo(
    "minutes",
    "seconds",
    "milliseconds"
  );

  const showPreShowActions =
    show.start > nowTime.plus({ minutes: show.preshowTransitionMinutes || 60 });

  const isPastShow = show.end < nowTime;

  return (
    <TicketContainer {...props}>
      {purchased && (
        <PurchasedBadgeStyle
          preShow={preShow}
          text="Your Ticket to the Show!"
        />
      )}
      <BackgroundFill purchased={purchased} preShow={preShow}>
        <Container xCenter yCenter stretch>
          <Artist name={show.artist} subtitle={show.subtitle} />

          <EventDetails date={show.date} time={show.time} start={show.start} />

          {!preShow && <Description content={show.description} />}
          {isPastShow ? (
            <PaddedContainer>
              <ReturnText>Sorry, This Show Has Ended</ReturnText>
              <Purchase onClick={() => history.push("/")}>
                View Other Available Shows
              </Purchase>
            </PaddedContainer>
          ) : (
            <>
              {!ticketsClosed || purchased ? (
                <>
                  {preShow && purchased && (
                    <ReturnText>Return here at showtime to watch!</ReturnText>
                  )}
                  {preShow &&
                    purchased &&
                    (show.requests ? (
                      <ActionsContainer>
                        <PreShowActions>
                          <Requests
                            content="Vote for your show’s set list on Set The Set!"
                            buttonText="Go To Set The Set"
                            onClick={onActionClick}
                          />
                          <AddToCalendar event={calendarEvent} />
                        </PreShowActions>
                      </ActionsContainer>
                    ) : (
                      <NoRequestsAction>
                        <AddToCalendar event={calendarEvent} />
                      </NoRequestsAction>
                    ))}

                  {(!purchased || !preShow) && (
                    <PaddedContainer xCenter>
                      {!purchased &&
                        (show.paymentItems.length < 2 || !preShow) && (
                          <Price>{show.priceLabel}</Price>
                        )}
                      {purchased && !preShow && !showPreShowActions && (
                        <ReturnText>
                          {show.start > nowTime
                            ? !timeToStart.minutes < 1
                              ? `Show Starts in ${timeToStart.minutes} Minute${
                                  timeToStart.minutes === 1 ? "" : "s"
                                }`
                              : `Show Starts in ${timeToStart.seconds} Second${
                                  timeToStart.seconds === 1 ? "" : "s"
                                }`
                            : "Your Show Has Started!"}
                        </ReturnText>
                      )}
                      <Purchase
                        variant={
                          show.paymentItems.length > 1 && (preShow || purchased)
                            ? true
                            : false
                        }
                        showStarted={!showPreShowActions}
                        onClick={onActionClick}
                      >
                        {preShow ? (
                          show.paymentItems.length > 1 ? (
                            <>
                              Get Your Front Row Ticket
                              <Separator />
                              {show.priceLabel}
                            </>
                          ) : (
                            "Get Your Front Row Ticket"
                          )
                        ) : !showPreShowActions && purchased ? (
                          <>
                            <TicketIcon tertiary name="Ticket" />
                            Click Here To Join The Show!
                          </>
                        ) : (
                          "View Show Details"
                        )}
                      </Purchase>
                      {purchased && showPreShowActions && (
                        <AddToCalendar event={calendarEvent} />
                      )}
                    </PaddedContainer>
                  )}
                  {!purchased && preShow && show.paymentItems.length > 1 && (
                    <PaddedContainer>
                      <PackageNotification
                        imagePath={image}
                        eventId={show.id}
                        text={
                          show.packageIncentive ??
                          "Want exclusive show merch or a VIP experience?"
                        }
                        linkText={"Learn More"}
                        onClick={onScrollClick}
                      />
                    </PaddedContainer>
                  )}
                </>
              ) : (
                <PaddedContainer xCenter>
                  <SoldOut>Sale Has Ended</SoldOut>
                  {!preShow && (
                    <Purchase onClick={onActionClick}>
                      View Show Details
                    </Purchase>
                  )}
                </PaddedContainer>
              )}
            </>
          )}
        </Container>
        <Divider />
      </BackgroundFill>
      <Image image={show.squareImage} />
    </TicketContainer>
  );
};

export const SoldOut = styled(GridRow)`
  background-color: ${(props) => (props.theme.preShow ? "#000000" : "#ffffff")};
  padding: 8px 28px;
  ${(props) => props.theme.fonts.header};
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${(props) => (props.theme.preShow ? "#ffffff" : "#222222")};
  margin-top: 40px;
  text-align: center;
`;

const PurchasedBadgeStyle = styled(PurchasedBadge)`
  border-radius: ${(props) => (props.preShow ? "16px 0 0 16px" : 0)};
`;

export const TicketIcon = styled(Icon)`
  margin-right: 8px;
  path {
    fill: #ffffff;
  }
`;

const TicketContainer = styled(GridRow)`
  width: 100%;
  margin: 0 auto;
  & + & {
    margin-top: 24px;
  }
`;

const BackgroundFill = styled(GridRow)`
  background-color: ${(props) =>
    props.purchased && !props.preShow
      ? props.theme.palette.orange.lightest
      : "#ffffff"};
  border-top-left-radius: ${(props) =>
    props.theme.preShow && !props.purchased ? "16px" : 0};
  border-bottom-left-radius: ${(props) =>
    props.theme.preShow && !props.purchased ? "16px" : 0};
  width: 100%;
`;

const Container = styled(GridColumn)`
  text-align: center;

  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 24px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: ${(props) =>
      props.theme.preShow ? "24px 5.215vw 24px 6.135vw" : "24px"};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: ${(props) =>
      props.theme.preShow ? "24px 68px 24px 80px" : "24px"};
  }
`;

const Price = styled(H3)`
  font-weight: 900;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;

  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 1.917vw;
    line-height: 2.454vw;
    margin-top: 3.067vw;
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    font-size: 25px;
    line-height: 32px;
    margin-top: 40px;
  }
`;

export const Separator = styled.div`
  background-color: #fff;
  margin: 0 12px;
  width: 2px;
  height: 22px;
`;

const PaddedContainer = styled(GridColumn)`
  width: 100%;
  max-width: 392px;
`;

const Purchase = styled(ButtonPrimary)`
  margin-top: ${(props) =>
    !props.variant || props.showStarted ? "1.227vw" : "3.067vw"};
  width: 100%;
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    margin-top: ${(props) =>
      !props.variant || props.showStarted ? "16px" : "40px"};
  }
`;

const ActionsContainer = styled(GridColumn)`
  background-color: ${(props) => props.theme.palette.teal.lightest};
  border: 1px solid ${(props) => props.theme.palette.teal.lighter};
  margin-top: 24px;
  padding: 16px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 3.067vw;
  }
`;

const PreShowActions = styled(GridColumn)`
  margin: 0 auto;
  max-width: 392px;
  width: 100%;
`;

const NoRequestsAction = styled(PreShowActions)`
  margin-top: 24px;
  max-width: 392px;
  width: 100%;
`;

const ReturnText = styled(P)`
  font-weight: 900;
  font-size: 25px;
  margin-bottom: 8px;
  margin-top: 40px;
`;
