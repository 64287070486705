import React, { createContext } from "react";
import { useEvent } from "Components";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;
export const EventProvider = ({ children, id }) => {
  const [event, loading, error] = useEvent(id);
  if (loading) {
    return null;
  }
  return (
    <EventContext.Provider value={{ event, loading, error }}>
      {children}
    </EventContext.Provider>
  );
};
