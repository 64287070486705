import React, { useState, useContext } from "react";
import { H3, P, Input, Label, ButtonPrimary } from "notes";
import { StoreContext } from "Components";
import { Actions, Form, CancelButton } from "../../Components";
import { Api } from "modules";

export const EmailForm = ({ rewardId, userResponse, onComplete }) => {
  const { user } = useContext(StoreContext);
  const [email, setEmail] = useState(userResponse);

  const handleClose = () => {
    onComplete(false);
  };

  const handleSubmit = () => {
    if (email) {
      // Set shipping address in user_reward_items
      Api.updateRewards({
        userRewardId: `${user.uid}#${rewardId}`,
        userResponse: { email: email },
        updateAll: false,
      }).then(() => handleClose());
    }
  };

  return (
    <Form>
      <H3>Email Address</H3>
      <P>
        Please provide the email address you would like to use for
        communication.
      </P>
      <Label>Email address</Label>
      <Input onChange={setEmail} value={email} placeholder="john@email.com" />
      <Actions xEnd>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ButtonPrimary onClick={handleSubmit}>Confirm</ButtonPrimary>
      </Actions>
    </Form>
  );
};
