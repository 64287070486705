import Joi from "@hapi/joi";
export const selfSchema = Joi.object({
  targetName: Joi.string().min(1).required(),
  conversation: Joi.string().min(1).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

export const thirdPartySchema = Joi.object({
  targetName: Joi.string().min(1).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  relationship: Joi.string().min(1).required(),
  conversation: Joi.string().min(1).required(),
});
