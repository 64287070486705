import React, { useState, useContext } from "react";
import styled from "styled-components";
import { GridRow, Icon } from "notes";
import { VideoPlayer, ModalContext } from "Components";

export const BackgroundVideo = ({ path, thumbnail }) => {
  const [videoPlay, setVideoPlay] = useState(true);
  const [soundMuted, setSoundMuted] = useState(true);
  const [replayVisible, setReplayVisible] = useState(false);
  const { modal } = useContext(ModalContext);
  const pauseModal = !!modal?.videoModal;
  const handleReplay = () => {
    setReplayVisible(false);
    setVideoPlay(true);
  };
  const handleEnded = () => {
    setReplayVisible(true);
    setVideoPlay(false);
  };
  return (
    <>
      {replayVisible ? (
        <VolumeControl onClick={handleReplay}>
          <Icon tertiary name="Refresh" />
        </VolumeControl>
      ) : (
        <VolumeControl onClick={() => setSoundMuted(!soundMuted)}>
          <Icon tertiary name={soundMuted ? "VolumeOff" : "VolumeOn"} />
        </VolumeControl>
      )}
      <VideoContainer>
        <Gradient />
        <VideoPlayer
          play={videoPlay && !pauseModal}
          mute={soundMuted}
          isBackground
          path={path}
          onEnded={handleEnded}
          thumbnail={thumbnail}
        />
      </VideoContainer>
    </>
  );
};

const VolumeControl = styled(GridRow)`
  background: rgba(0, 0, 0, 0.24);
  border-radius: 4px 0 0 4px;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 76px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 138px;
  z-index: 3;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #ffffff;
    }
  }
`;

const Gradient = styled.div`
  background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 80.98%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  mask-image: linear-gradient(179deg, #ffffff 87%, rgba(255, 255, 255, 0) 98%);
`;
