import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AdminControls } from "Components/AdminControls";
import { Landing } from "./Landing";
import { Enrollment } from "./Enrollment";
import { Login } from "./Login";
import { RequestVerification } from "./RequestVerification";
import { Verify } from "./Verify";
import { Purchase } from "./Purchase";
import { Event } from "./Event";
import { Account, UpdateEmail, ConfirmEmail } from "./Account";
import { Privacy } from "./Privacy";
import { Terms } from "./Terms";
import { Contact } from "./Contact";
import { Billing } from "./Billing";
import { Rewards } from "./Rewards";
import { Redeem } from "./Redeem";
import { Gift, GiftCodes } from "./Gift";
import Reset from "./Reset";
import { default as VideoMessage } from "./VideoMessage";
import { default as FAQ } from "./FAQ";

import { ModalProvider, GlobalErrorHandler, StoreContext } from "Components";

export const Routes = () => {
  const { user, userLoading, userVerified } = useContext(StoreContext);
  if (userLoading) {
    return null;
  }
  return (
    <Router>
      <GlobalErrorHandler>
        <ModalProvider>
          <AdminControls />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/help" component={FAQ} />
            <Route
              exact
              path="/requestverification"
              component={RequestVerification}
            />
            <Route exact path="/verify" component={Verify} />
            <Route
              exact
              path="/reset/password"
              component={Reset.PasswordForm}
            />
            {user && !userVerified && (
              <>
                <Redirect to="/requestverification" />
              </>
            )}
            <Route exact path="/signup" component={Enrollment} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset" component={Reset.Main} />
            <Route exact path="/event/:event" component={Event} />
            <Route
              exact
              path={["/purchase", "/purchase/:view"]}
              component={Purchase}
            />
            <Route exact path={["/gift", "/gift/:eventId"]} component={Gift} />
            <Route
              exact
              path={"/giftcodes/:transactionId"}
              component={GiftCodes}
            />
            <Route exact path="/redeem/:voucher?" component={Redeem} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/update/email" component={UpdateEmail} />
            <Route
              exact
              path="/account/confirm/email"
              component={ConfirmEmail}
            />
            <Route
              exact
              path="/video_message/:eventId"
              component={VideoMessage}
            />
            <Route exact path="/billing" component={Billing} />
            <Route exact path="/rewards" component={Rewards} />
            <Redirect to="/" />
          </Switch>
        </ModalProvider>
      </GlobalErrorHandler>
    </Router>
  );
};
