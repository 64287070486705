import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridColumn, GridRow, H1, Card, P, ButtonPrimary } from "notes";
import {
  FormWrapper,
  StoreContext,
  TicketStub,
  formatCurrency,
  ModalContext,
  Receipt,
  Link,
} from "Components";
import {
  useCollectionOnce,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { Container } from "../../Enrollment";

export const CompleteView = ({ transaction }) => {
  console.log(transaction);
  const { eventId } = transaction.paymentItem;
  const history = useHistory();
  const { Store, user } = useContext(StoreContext);
  const [event, loadingEvent, errorEvent] = useDocumentDataOnce(
    Store.firestore().doc(`events/${eventId}`)
  );

  const goToShow = () => {
    history.push(`/event/${eventId}`);
  };

  if (loadingEvent) {
    return null;
  }

  return (
    <FormWrapper hideFooter>
      <FormFlow showTicket>
        <TicketContainer>
          <TicketStub show={event} />
        </TicketContainer>
        <div>
          <H1 style={{ marginBottom: "40px" }}>Purchase Complete</H1>
          {transaction?.metadata?.vouchers?.map((code, index) => (
            <GiftCard
              link={`https://set.live/redeem/${code}`}
              number={index + 1}
            />
          ))}
          <ButtonPrimary
            style={{ marginTop: "40px", width: "100%" }}
            onClick={goToShow}
          >
            Return To Show Page
          </ButtonPrimary>
        </div>
      </FormFlow>
      <Receipt data={transaction} />
    </FormWrapper>
  );
};

const GiftCard = ({ link, number }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(link);
  };
  return (
    <GiftContainer>
      <P style={{ fontSize: "12px", color: "#A6AEB2" }}>Gift Link {number}</P>
      <P style={{ padding: "10px 0" }}>{link}</P>
      <Link onClick={handleCopy}>Copy Link</Link>
    </GiftContainer>
  );
};

const GiftContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
`;

const FormFlow = styled(Container)`
  @media only screen and ${(props) => props.theme.media.small} {
    padding-bottom: 0;
  }
`;

const TicketContainer = styled(GridRow)`
  margin-right: 104px;
  max-width: 393px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 0;
    margin-bottom: 40px;
    max-width: inherit;
    padding: 0 24px;
  }
`;
