import React, { Fragment } from "react";
import styled from "styled-components";
import { H4, GridRow, P, Icon, GridColumn } from "notes";

const SupportListContainer = styled(GridColumn)`
  background-color: ${(props) => (props.theme.dark ? "#222222" : "#fafafa")};
  transition: background-color ease 2000ms;
  padding: 32px 12px 24px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 24px;
  }
`;

const SupportListRow = styled(GridRow)`
  padding: 9px 0;
  justify-content: space-between;
  transition: all ease 0.3s;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  &:first-of-type {
    border-top: 1px solid
      ${(props) =>
        props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 10px 0;
  }
`;

const SupportTitleText = styled(H4)`
  font-family: ${(props) => props.theme.fonts.workSans};
  font-weight: 500;
  color: ${(props) => (props.theme.dark ? "#ffeaad" : "#222222")};
  text-transform: uppercase;
  transition: color ease 0.3s;
  margin-bottom: 16px;
`;

const SupportText = styled(P)`
  color: ${(props) => (props.theme.dark ? "#ffffff" : "#222222")};
  font-weight: 600;
  transition: color ease 0.3s;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 15px;
    line-height: 20px;
  }
`;

const PlaceHolderIcon = styled(Icon)`
  transform: rotate(90deg);
  transition: all ease 0.3s;
  path {
    fill: ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lighter"]};
  }
`;

const IconContainer = styled.div`
  margin-right: 5px;
`;

export const SupportList = ({ topSupporters, user, levels }) => {
  return (
    <SupportListContainer>
      <SupportTitleText>Show Your Support</SupportTitleText>
      {topSupporters.map(({ id, user, rank, supportLevel }) => {
        return (
          <SupportListRow key={id}>
            <SupportText>
              {rank < 10 && "0"}
              {rank}. {user}
            </SupportText>
            <IconContainer>{levels[supportLevel]}</IconContainer>
          </SupportListRow>
        );
      })}
      {user && (
        <Fragment>
          <SupportListRow>
            <PlaceHolderIcon platform name="More"></PlaceHolderIcon>
          </SupportListRow>
          <SupportListRow>
            <SupportText>
              {user.rank < 9 ? "0" : ""}
              {user.rank}. {user.user}
            </SupportText>
            <IconContainer>{levels[user.level]}</IconContainer>
          </SupportListRow>
        </Fragment>
      )}
    </SupportListContainer>
  );
};
