import React, { useContext } from "react";
import { SupportLevelBar, H4, GridColumn } from "notes";
import { EventContext, StoreContext, formatCurrency } from "Components";
import { useObjectVal } from "react-firebase-hooks/database";
import styled from "styled-components";

export const SupportBar = React.memo(({ rank }) => {
  const { event } = useContext(EventContext);
  const { Store, user } = useContext(StoreContext);
  const levels = Object.values(event.supportLevels)
    .map(({ amount, title, selectedIcon, unselectedIcon }) => ({
      value: amount / 100,
      label: "",
      selectedIcon: () =>
        selectedIcon ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_PATH}/${selectedIcon}`}
            height="18"
          />
        ) : null,
      unselectedIcon: () =>
        unselectedIcon ? (
          <img
            src={`${process.env.REACT_APP_ASSETS_PATH}/${unselectedIcon}`}
            height="18"
          />
        ) : null,
    }))
    .sort((a, b) => a.value - b.value);
  const [total] = useObjectVal(
    Store.database().ref(`${event.id}/leaderboard/totals/${user.uid}`)
  );
  return (
    <Container>
      <Title style={{ fontWeight: "700" }}>Current Tier: {rank?.title}</Title>
      <SupportLevelBar value={total / 100} sections={levels} />
      <H4Custom style={{ fontSize: "14px" }}>
        You’ve shown {formatCurrency(total)} of support
      </H4Custom>
    </Container>
  );
});

const Container = styled(GridColumn)`
  background-color: ${(props) => (props.theme.dark ? "#222222" : "#ffffff")};
  transition: all ease 2000ms;
  border-bottom: 2px solid
    ${(props) =>
      props.theme.dark
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lightest};
  padding: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 16px 24px;
  }
`;

const Title = styled(H4)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
  font-weight: 700;
  text-align: center;
  transition: color ease 0.3s;
`;

const H4Custom = styled(H4)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.gray.primary};
  text-align: center;
  transition: color ease 0.3s;
`;
