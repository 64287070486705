import React, { useContext } from "react";
import styled from "styled-components";
import { DefaultView } from "./DefaultView";
import { LevelProgress } from "./LevelProgress";
import { H3, P } from "notes";
import { EventContext, parseEmoji } from "Components";
export const CompleteView = ({ items, initialTotal }) => {
  const heading = getHeading(items.type);
  return (
    <DefaultView
      heading={heading}
      style={{
        justifyContent: "space-between",
        marginBottom: "0",
        marginTop: items.type === "donate" ? "40px" : "20px",
      }}
    >
      <Message item={items} />
      <LevelProgress item={items} initialTotal={initialTotal} />
    </DefaultView>
  );
};

const getHeading = (type) => {
  switch (type) {
    case "superchat":
      return "Message Pinned!";
    case "song":
      return "Votes Submitted!";
    case "donate":
      return "Thanks for Your Support!";
    default:
      return "Payment Complete";
  }
};

const Message = ({ item }) => {
  const { event } = useContext(EventContext);
  const songs = event.encoreSongs;

  switch (item.type) {
    case "superchat":
      return (
        <>
          <P>
            Your message will receive greater visibility for the next
            {item.duration > 1 && " " + item.duration} minute
            {item.duration > 1 && "s"}.
          </P>
          <ChatMessage>{parseEmoji(item.metadata.content)}</ChatMessage>
        </>
      );
    case "song":
      const votedSongs = Object.keys(item.metadata)
        .map((songId) => {
          return {
            ...songs[songId],
            qty: item.metadata[songId],
          };
        })
        .filter((song) => song.qty);
      return (
        <>
          <P style={{ marginBottom: "24px" }}>
            Keep an eye on the rankings and vote again for the chance to get
            your song played.
          </P>
          {votedSongs.map(({ title, qty }) => (
            <SongRow>{title}</SongRow>
          ))}
        </>
      );
    case "donate":
      return null;
    default:
      return <P>Thanks for your support!</P>;
  }
};

const ChatMessage = styled(P)`
  background: #a7dbda;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  margin: 20px 12px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.text} !important;
  word-wrap: break-word;
`;

const SongRow = styled(P)`
  background: #bcafe6;
  border-radius: 4px;
  padding: 9px 12px;
  width: 100%;
  margin: 4px 12px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.text} !important;
`;
