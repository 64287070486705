import React from "react";
import styled from "styled-components";
import { Icon, H4, GridRow } from "notes";

export const IconLabel = ({ stage, type }) => {
  const { doorsOpen, live } = stage ?? {};
  switch (type) {
    case "meet_greet":
      return (
        <Label>
          <Icon tertiary name="VideoChat" />
          <H4>
            {live ? "Going On Now" : doorsOpen ? "Starting Soon" : "Up Next"}
          </H4>
        </Label>
      );
    case "replay":
      return (
        <Label>
          <Icon tertiary name="Refresh" />
          <H4>Replay</H4>
        </Label>
      );
    default:
      return (
        <Label>
          <Icon tertiary name="Music" />
          <H4>{live ? "On Stage" : doorsOpen ? "Starting Soon" : "Up Next"}</H4>
        </Label>
      );
  }
};

const Label = styled(GridRow)`
  align-items: center;
  margin-bottom: 20px;
  svg {
    margin-right: 12px;
    path {
      fill: #fff;
    }
  }
  ${H4} {
    color: #fff;
    font-family: ${(props) => props.theme.fonts.workSans};
    font-weight: 700;
    text-transform: uppercase;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 24px;
    ${H4} {
      font-size: 15px;
      line-height: 18px;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-bottom: 1.53vw;
    svg {
      margin-right: 0.92vw;
      width: 1.23vw;
      height: 1.23vw;
    }
    ${H4} {
      font-size: 1.3vw;
      line-height: 1.69vw;
    }
  }
`;
