import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  ModalContext,
  NotificationContext,
  CustomParser as parser,
} from "Components";
import _ from "lodash";
import moment from "moment";

export const NotificationModal = ({ open, setOpen }) => {
  const { toggleNotificationModal, notificationModal } = useContext(
    ModalContext
  );
  const { messages, markMessagesAsRead } = useContext(NotificationContext);

  const history = useHistory();

  const newMessageCount = messages?.filter(({ readAt }) => !readAt).length;

  const when = (createdAt) => {
    try {
      const now = moment().utc();
      const created = moment(createdAt.seconds * 1000).utc();
      const mins = now.diff(created, "minute");
      if (mins < 60) {
        return `${mins} mins ago`;
      } else if (mins < 1440) {
        const hrs = Math.floor(mins / 60);
        return `${hrs} hr${hrs > 1 ? "s" : ""} ago`;
      } else {
        const days = Math.floor(mins / 1440);
        return `${days} day${days > 1 ? "s" : ""} ago`;
      }
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    if (notificationModal.open === false) {
      markMessagesAsRead();
    }
  }, [notificationModal]);

  return (
    <>
      <PageCover
        open={open}
        onClick={(event) => {
          event.stopPropagation();
          toggleNotificationModal({ open: false });
        }}
      />
      <NotificationContainer
        id="__notifications"
        open={open}
        top={notificationModal.top}
        right={notificationModal.right}
        fixed={notificationModal.fixed}
      >
        <NotificationHeader>Messages ({newMessageCount})</NotificationHeader>
        <Scrolling>
          {messages?.map(({ subject, message, readAt, createdAt }) => {
            return (
              <MessageContainer read={!!readAt}>
                <SubjectContainer>
                  <MessageSubject>{subject}</MessageSubject>
                  {!readAt && <New>new!</New>}
                  <Time>{when(createdAt)}</Time>
                </SubjectContainer>
                <MessageContent>{parser(message)}</MessageContent>
              </MessageContainer>
            );
          })}
        </Scrolling>
        <CloseButton
          onClick={() => {
            toggleNotificationModal({ open: false });
            //markMessagesAsRead();
          }}
        >
          Close
        </CloseButton>
      </NotificationContainer>
    </>
  );
};

const PageCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all ease 0.3s;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 499;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
`;

const New = styled.span`
  display: inline-block;
  color: #ff8c00;
  font-size: 10px;
  line-height: 16px;
  ${(props) => props.theme.fonts.default};
  text-transform: uppercase;
  margin-right: 7px;
`;

const Time = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.disabledText};
  font-size: 10px;
  line-height: 16px;
  ${(props) => props.theme.fonts.default};
`;

const SubjectContainer = styled.div`
  margin-bottom: 16px;
`;

const NotificationContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: ${(props) => (props.open ? "block" : "none")};
  position: ${(props) => (props.fixed === true ? "fixed" : "absolute")};
  height: 392px;
  width: 392px;
  top: ${(props) => props.top || 49}px;
  right: ${(props) => props.right}px;
  border-radius: 10px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 0;
    width: 90vw;
    top: 5vh;
    right: 5vw;
    height: 90vh;
  }
  z-index: 500;
`;

const NotificationHeader = styled.div`
  color: ${(props) => props.theme.colors.disabledText};
  border-bottom: 1px solid ${(props) => props.theme.colors.disabledText};
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  ${(props) => props.theme.fonts.default};
  padding-left: 1em;
`;

const Scrolling = styled.div`
  height: 344px;
  overflow-y: auto;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 76vh;
  }
`;

const CloseButton = styled.div`
  display: none;
  height: 6vh;
  border-top: 1px solid ${(props) => props.theme.colors.disabledText};
  text-align: center;
  ${(props) => props.theme.fonts.default};
  color: ${(props) => props.theme.palette.purple.primary};
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  padding-top: 13px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: block;
    padding: 5px;
  }
`;

const MessageContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.disabledText};
  background-color: ${(props) => (!props.read ? "#FDF4E9" : "inherit")};
`;

const MessageSubject = styled.span`
  font-size: 17px;
  ${(props) => props.theme.fonts.header};
  display: block;
  font-weight: 600;
`;

const MessageContent = styled.div`
  font-size: 17px;
  line-height: 27px;
  ${(props) => props.theme.fonts.default};
  width: 94%;
`;

const NewMessage = styled.div`
  position: absolute;
  width: 200px;
  left: -78px;
  font-size: 12px;
  line-height: 17px;
`;

//animation: ${props => props.glow ? css`${glowShadow} 1.5s linear infinite alternate` : 'none'};
