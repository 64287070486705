import React, { useState, useEffect, useContext } from "react";
import {
  Payment,
  StoreContext,
  EventContext,
  formatCurrency,
  parseEmoji,
} from "Components";
import styled from "styled-components";
import { Modal, Icon, withDefaultMedia, P, H3 } from "notes";
import { CompleteView } from "./Components";
import { useObjectVal } from "react-firebase-hooks/database";
const views = ["empty", "payment", "complete"];

export const PaymentSidebar = withDefaultMedia(
  ({ items, onComplete, onCancel, matches, modal = false }) => {
    const [view, setView] = useState(views[0]);
    const [internalItems, setInternalItems] = useState();
    const { Store, user } = useContext(StoreContext);
    const { event } = useContext(EventContext);
    const [total] = useObjectVal(
      Store.database().ref(`${event.id}/leaderboard/totals/${user.uid}`)
    );
    const [initialTotal, setInitialTotal] = useState();
    useEffect(() => {
      if (view === views[0]) {
        setInitialTotal(total);
      }
    }, [total]);
    const handleComplete = (...args) => {
      onComplete(...args);
      setView(views[2]);
    };
    const handleClose = () => {
      if (view === views[1]) {
        onCancel();
      }
      setView(views[0]);
      setInternalItems();
    };
    useEffect(() => {
      if (!internalItems && items) {
        setInternalItems(items);
        setView(views[1]);
      }
    }, [items]);
    useEffect(() => {
      let cancel;
      if (view === views[2]) {
        cancel = setTimeout(() => {
          handleClose();
        }, 10000);
      }
      return () => {
        clearTimeout(cancel);
      };
    }, [view]);
    if (view === views[0]) {
      return null;
    }
    const renderInterior = () => (
      <Container forceLight={view === views[1]} modal={modal}>
        {(!modal || view === views[2]) && (
          <CloseIcon
            onClick={handleClose}
            platform
            name="Close"
            forceLight={view === views[1]}
            style={
              modal && view === views[2] ? { top: "32px", right: "40px" } : null
            }
          />
        )}
        {view === views[1] && (
          <Payment
            items={internalItems}
            onComplete={handleComplete}
            hideTotal
            style={{
              margin: matches.small ? "0 auto" : "0 auto",
              padding: matches.small ? "16px 12px" : "12px 24px",
              overflowX: "hidden",
            }}
            billingText={<BillingDetail items={internalItems} event={event} />}
          />
        )}
        {view === views[2] && (
          <CompleteView items={internalItems} initialTotal={initialTotal} />
        )}
      </Container>
    );
    if (modal) {
      return (
        <Modal
          open={true}
          style={matches.large ? modalStyles : smallStyle}
          setOpen={handleClose}
        >
          {renderInterior()}
        </Modal>
      );
    } else {
      return renderInterior();
    }
  }
);

const modalStyles = {
  minHeight: "unset",
  width: "unset",
  padding: "20px 0 0 0",
  borderRadius: "4px",
  overflow: "hidden",
};

const smallStyle = {
  position: "fixed",
  bottom: "0",
  left: "0",
  right: "0",
  height: "60vh",
  overflow: "scroll",
  padding: "0 0 0 0",
  maxWidth: "unset",
};

const Container = styled.div`
  padding-top: 20px;
  height: 100%;
  overflow: hidden auto;

  @media only screen and ${(props) => props.theme.media.large} {
    padding: 0;
  }
  ${(props) =>
    !props.modal
      ? `
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 56.4vw;
    background: #fff;
    z-index: 30;
    padding: 0;
    @media only screen and ${(props) => props.theme.media.large} {
      top: 0;
      position: absolute;
    }
  `
      : `
  min-width: 30vw;
  `}

  ${(props) =>
    props.theme.dark &&
    !props.forceLight &&
    `
    background: #000
  `}
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  ${(props) =>
    props.theme.dark &&
    !props.forceLight &&
    `
  path {
    fill: #fff
  }
  `}
`;

const BillingDetail = ({ items, event }) => {
  if (items.type === "superchat") {
    return (
      <BillingContainer>
        <ItemsContainer>
          <div>
            {!!items.duration
              ? `Pin Message (${items.duration} Min)`
              : items.label
              ? items.label
              : "Purchase"}
          </div>
          <Amount>{formatCurrency(items.amount * items.quantity)}</Amount>
        </ItemsContainer>
        <MessageContainer>
          <Message>Message</Message>
          <P style={{ wordWrap: "break-word" }}>
            {parseEmoji(items.metadata.content)}
          </P>
        </MessageContainer>
      </BillingContainer>
    );
  }
  if (items.type === "song") {
    return (
      <BillingContainer>
        <ItemsContainer>
          <div>{items.quantity}x Vote for Encore</div>
          <Amount>{formatCurrency(items.amount * items.quantity)}</Amount>
        </ItemsContainer>
        <MessageContainer>
          <Message>Songs</Message>
          {Object.keys(items.metadata).map(
            (songId) =>
              !!items.metadata[songId] && (
                <P>
                  {items.metadata[songId]}x {event.encoreSongs[songId]?.title}
                </P>
              )
          )}
        </MessageContainer>
      </BillingContainer>
    );
  }
  if (items.type === "donate") {
    return (
      <BillingContainer>
        <ItemsContainer>
          <div>Support Artist</div>
          <Amount>{formatCurrency(items.amount * items.quantity)}</Amount>
        </ItemsContainer>
      </BillingContainer>
    );
  }
};

const BillingContainer = styled.div``;
const ItemsContainer = styled(P)`
  background: #e6e9eb;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  background: #f6f9fc;
  align-items: center;
  margin: 0 -24px;
  border-top: 1px solid #e6e9eb;
  border-bottom: 1px solid #e6e9eb;
  height: 100%;
`;
const Amount = styled(H3)``;

const MessageContainer = styled.div`
  border-bottom: 1px solid #e6e9eb;
  margin: 0 -24px;
  padding: 0 24px 16px 24px;
`;

const Message = styled(P)`
  color: #a6aeb2;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 16px 0 8px 0;
`;
