import React, { useState } from "react";
import styled from "styled-components";
import { P, Label, GridColumn, GridRow, Link, Icon, Input } from "notes";

export const EditName = ({ defaultValue, onSubmit, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(
    defaultValue ? defaultValue : { firstName: "", lastName: "" }
  );
  const [error, setError] = useState({});

  const handleSubmit = (fields) => {
    if (fields.firstName.length > 2 && fields.lastName.length > 2) {
      onSubmit(fields);
      setEditMode(false);
    } else {
      fields.firstName.length < 2 && fields.lastName.length < 2
        ? setError({ firstName: true, lastName: true })
        : fields.firstName.length < 2
        ? setError({ firstName: true })
        : setError({ lastName: true });
    }
  };

  const handleChange = (name, value) => {
    setValue(value);
    delete error[name];
    setError(error);
  };

  return (
    <FieldContainer yCenter editMode={editMode}>
      {(!!defaultValue || editMode) && <FieldLabel>Name</FieldLabel>}
      {editMode ? (
        <EditContainer>
          <GridRow {...props}>
            <FirstName
              onChange={(nameValue) =>
                handleChange("firstName", { ...value, firstName: nameValue })
              }
              value={value.firstName}
              placeholder="First or given name..."
              error={error.firstName}
            />
            <LastName
              onChange={(nameValue) =>
                handleChange("lastName", { ...value, lastName: nameValue })
              }
              value={value.lastName}
              placeholder="Last for family name..."
              error={error.lastName}
            />
          </GridRow>
          <Actions>
            <LinkStyle
              onClick={() => {
                setError({});
                setEditMode(false);
                setValue(defaultValue);
              }}
            >
              Cancel
            </LinkStyle>
            <LinkStyle
              onClick={() => {
                handleSubmit(value);
              }}
            >
              Apply
            </LinkStyle>
          </Actions>
        </EditContainer>
      ) : !!defaultValue ? (
        <>
          <P>
            {defaultValue.firstName} {defaultValue.lastName}
          </P>
          <EditAction name="Edit" onClick={() => setEditMode(true)} />
        </>
      ) : (
        <>
          <PurpleText onClick={() => setEditMode(true)}>Add Name</PurpleText>
          {/* <InfoIcon indicator name="Information" /> */}
        </>
      )}
    </FieldContainer>
  );
};

export const FieldLabel = styled(Label)``;

export const FieldContainer = styled(GridColumn)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 0;
  position: relative;
  min-height: 56px;

  @media only screen and ${(props) => props.theme.media.small} {
    padding-right: 24px;
  }

  ${FieldLabel} {
    margin-bottom: 4px;
    color: ${(props) =>
      props.editMode
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
  }
`;

export const EditContainer = styled(GridColumn)`
  max-width: 392px;
  width: 100%;
`;

export const EditAction = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 21px;
  @media only screen and ${(props) => props.theme.media.small} {
    path {
      fill: ${(props) => props.theme.palette.gray.lighter};
    }
  }
`;

export const InfoIcon = styled(EditAction)`
  position: absolute;
  right: 24px;
`;

export const PurpleText = styled(P)`
  color: ${(props) => props.theme.palette.purple.primary};
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
`;

export const Actions = styled(GridRow)`
  margin-top: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: flex-end;
  }
`;

export const LinkStyle = styled(Link)`
  padding: 0 8px;
  height: 40px;
  & + & {
    margin-left: 24px;
  }
`;

const FirstName = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const LastName = styled(Input)`
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
