import React from "react";
import styled from "styled-components";
import { GridColumn, H2 } from "notes";
import { ButtonWhite } from "Components";
const tempImage = `${process.env.REACT_APP_ASSETS_PATH}/ab3efdfc-4d4f-47ef-b98f-1b0c3c592fdb.jpg`;

export const Musicians = ({ ...props }) => {
  return (
    <Container image={tempImage} {...props}>
      <Content>
        <H2>ARE YOU A MUSICIAN? Hold Your Next Show on Set.Live!</H2>
        <StyledButtonWhite
          onClick={() => {
            const newWindow = window.open(
              "https://about.set.live",
              "_self",
              "noopener noreferrer"
            );
            if (newWindow) newWindow.opener = null;
          }}
        >
          Get Started
        </StyledButtonWhite>
      </Content>
      <Gradient />
    </Container>
  );
};

const StyledButtonWhite = styled(ButtonWhite)`
  margin-top: 40px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 3.07vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
  }
`;

const Container = styled(GridColumn)`
  background: url(${(props) => props.image});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 1559px;
  margin-top: 96px;
  position: relative;
  padding: 162px 144px 0;
  width: 100%;
  height: 428px;
  @media only screen and ${(props) => props.theme.media.medium} {
    background-size: 119.56vw;
    height: 32.82vw;
    padding: 12.42vw 11.04vw 0;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    background-size: 651px;
    height: initial;
    margin-top: 72px;
    padding: 40px;
  }
  @media only screen and (min-width: 651px) and (max-width: 767px) {
    background-size: 767px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    background-position: left 40%;
    background-size: cover;
  }
`;

const Content = styled(GridColumn)`
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 392px;
  z-index: 2;
  ${H2} {
    color: #ffffff;
    font-weight: 500;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: 208px;
    ${H2} {
      font-size: 17px;
      line-height: 20px;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 30.06vw;
    ${H2} {
      font-size: 1.92vw;
      line-height: 2.45vw;
    }
  }
`;

const Gradient = styled.div`
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 848px;
  height: 100%;
  z-index: 1;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 288px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 65.03vw;
  }
`;
