import React from "react";
import { H1, P, Link } from "notes";
import parse, { domToReact } from "html-react-parser";
import styled from "styled-components";

const B = styled(P)`
  font-weight: 500;
`;

const options = {
  replace: ({ name, children, attribs }) => {
    if (name === "h1") {
      return <H1 {...attribs}>{domToReact(children, options)}</H1>;
    }
    if (name === "strong") {
      return <B {...attribs}>{domToReact(children, options)}</B>;
    }
    if (name === "a") {
      return (
        <Link {...attribs} target="_blank">
          {domToReact(children, options)}
        </Link>
      );
    }
  },
};

export const htmlParser = (html) => {
  return parse(html, options);
};
