import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Modal, H3, Link, P } from "notes";
import {
  ModalContext,
  Accordion,
  CustomParser as parser,
  HelpProvider,
  HelpContext,
  LoaderInline,
} from "Components";
import _ from "lodash";

export const HelpModal = ({ open, setOpen }) => {
  const { toggleHelpModal } = useContext(ModalContext);

  const history = useHistory();

  return (
    <HelpProvider>
      <HelpContext.Consumer>
        {({ helpReady, setHelpFilter, helpTopics, helpFilterInput }) => (
          <ModalStyle
            size="small"
            open={open}
            setOpen={(active) => {
              if (!active) {
                setHelpFilter("");
              }
              setOpen(active);
            }}
          >
            <H3>Help</H3>
            <HelpFilterInput>{helpFilterInput()}</HelpFilterInput>
            {helpReady ? (
              <HelpTopics>
                {helpTopics && _.keys(helpTopics).length > 0 ? (
                  _.keys(helpTopics).map((key) => (
                    <Accordion title={helpTopics[key].title}>
                      <SectionContentWrapper>
                        {parser(helpTopics[key].content)}
                      </SectionContentWrapper>
                    </Accordion>
                  ))
                ) : (
                  <NotFound>
                    We were unable to locate any matches for your search
                    criteria. Please try a new search or check out our
                    <Link
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        setHelpFilter("");
                        toggleHelpModal(false);
                        history.push("/faq");
                      }}
                    >
                      Frequently Asked Questions
                    </Link>{" "}
                    for more help.
                  </NotFound>
                )}
                <div style={{ width: "100%", height: "60px" }} />
              </HelpTopics>
            ) : (
              <div style={{ marginTop: "40px", textAlign: "center" }}>
                <P>Loading Help...</P>
                <LoaderInline />
              </div>
            )}
          </ModalStyle>
        )}
      </HelpContext.Consumer>
    </HelpProvider>
  );
};

const ModalStyle = styled(Modal)`
  position: absolute;
  top: 30px;
  right: 30px;
  padding-right: 1.5em;
  padding-bottom: 0;
  margin: 5.521vw 0;
  min-height: 80vh;
  border-radius: 10px;
  padding-left: 1.5em;
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 0;
    width: 100%;
    top: 0;
    right: 0;
    height: 100vh;
  }
`;

const NotFound = styled.div`
  margin-top: 12px;
  color: #596266;
  font-size: 17px;
  line-height: 27px;
`;

const SectionContentWrapper = styled.div`
  padding-top: 24px;
`;

const HelpFilterInput = styled.div`
  margin-top: 24px;
`;

const HelpTopics = styled.div`
  height: 60vh;
  max-height: 97%;
  overflow: auto;
  margin-bottom: 40px;
  display: block;
  position: relative;
  @media only screen and ${(props) => props.theme.media.small} {
    height: 75vh;
  }
`;
