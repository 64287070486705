import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "Components";
export const withProtectedRoute = (Wrapped) => (props) => {
  const { user } = useContext(StoreContext);
  const history = useHistory();

  if (!user) {
    localStorage.setItem("loginReturn", props?.match.url);
    history.push("/login");
    return null;
  }

  return <Wrapped {...props} />;
};
