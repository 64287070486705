import { DateTime } from "luxon";

export const formatShow = (show) => {
  const {
    assets,
    startDate,
    endDate,
    closeDate,
    closeTicketsDate,
    ...remain
  } = show;
  const start = DateTime.fromJSDate(startDate?.toDate());
  const end = DateTime.fromJSDate(endDate?.toDate());
  const close = closeDate && DateTime.fromJSDate(closeDate?.toDate());
  const closeTickets =
    closeTicketsDate && DateTime.fromJSDate(closeTicketsDate?.toDate());
  const date = start.toFormat("MMM d");
  const time = start.toFormat("t").toLowerCase();
  const length = Math.floor(end.diff(start, "minutes").minutes) + "min";
  const newImage = `${process.env.REACT_APP_ASSETS_PATH}/${assets?.preshowBanner?.path}`;
  const squareImage = `${process.env.REACT_APP_ASSETS_PATH}/${
    assets?.squareBanner
      ? assets?.squareBanner?.path
      : assets?.preshowBanner?.path
  }`;
  const checkoutImage = `${process.env.REACT_APP_ASSETS_PATH}/${
    assets?.checkoutBanner
      ? assets?.checkoutBanner?.path
      : assets?.preshowBanner?.path
  }`;
  return {
    date,
    time,
    length,
    start,
    end,
    close,
    closeTickets,
    image: newImage,
    squareImage,
    checkoutImage,
    assets,
    ...remain,
  };
};
