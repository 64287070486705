import React from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { GridRow, GridColumn, Footnote } from "notes";
import { Event, RenderTransactions } from "../";
import { removeDuplicates, filterTransactions } from "./modules";

export const Transaction = ({ eventId, paymentItems, ...props }) => {
  const paymentSources = paymentItems
    .map(({ source }) => {
      if (!source.card) {
        return null;
      }
      const { brand, last4, exp_month, exp_year, fingerprint } = source.card;
      return (
        source && {
          brand: brand,
          last4: last4,
          month: exp_month,
          year: exp_year,
          fingerprint: fingerprint,
        }
      );
    })
    .filter((item) => !!item);

  const mostRecentTransaction = paymentItems.reduce((a, b) => {
    return new Date(a.created) > new Date(b.created) ? a : b;
  });

  const transactionDate = DateTime.fromJSDate(
    mostRecentTransaction.created.toDate()
  );
  const formattedDate = transactionDate.toFormat("MMMM d, yyyy");

  const uniqueSources =
    !!paymentSources[0] && removeDuplicates(paymentSources, "fingerprint");

  const transactionsBySource = !uniqueSources
    ? [filterTransactions(paymentItems)]
    : uniqueSources.map((card) => {
        const itemizedBySource = paymentItems.filter(
          ({ source }) => source.card?.fingerprint === card.fingerprint
        );
        const filteredTransactions = filterTransactions(itemizedBySource, card);
        return filteredTransactions;
      });

  return (
    <Container {...props}>
      <Event date={formattedDate} eventId={eventId} />
      <Details stretch>
        {transactionsBySource.map((transaction) => (
          <RenderTransactions {...transaction} />
        ))}
        <MobileStatus>
          <span>Status:</span> Last charged on {formattedDate}
        </MobileStatus>
      </Details>
    </Container>
  );
};

const Container = styled(GridRow)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  width: 100%;
  & + & {
    margin-top: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

const MobileStatus = styled(Footnote)`
  display: none;
  margin: 16px 24px;
  span {
    margin-right: 6px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    display: flex;
  }
`;

const Details = styled(GridColumn)`
  background-color: ${(props) => props.theme.colors.transactionFill};
  border-radius: 0 8px 8px 0;
  padding: 24px;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.small} {
    background-color: transparent;
    border-radius: 0 0 8px 8px;
    padding: 0;
  }
`;
