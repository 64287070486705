import React, { useState } from "react";
import { Link, H4 } from "notes";
import { ShippingForm } from "./ShippingForm";
import { EditLink, RequirementModal } from "../../Components";

export const Shipping = ({ rewardId, processingStatus, userResponse }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RequirementModal open={open} setOpen={setOpen}>
        <ShippingForm
          rewardId={rewardId}
          userResponse={userResponse}
          onComplete={() => setOpen(false)}
        />
      </RequirementModal>

      {userResponse ? (
        <H4>
          {userResponse.name?.firstName} {userResponse.name?.lastName}
          {userResponse.name && <br />}
          {userResponse.addressLine1}
          <br />
          {userResponse.addressLine2}
          {userResponse.addressLine2 && <br />}
          {!!userResponse.city
            ? userResponse.city + ", " + userResponse.state
            : userResponse.state}{" "}
          {userResponse.zipCode}
          <br />
          {userResponse.country}
          {!processingStatus && <EditLink onClick={() => setOpen(true)} />}
        </H4>
      ) : (
        <Link onClick={() => setOpen(true)}>Add Shipping Address</Link>
      )}
    </>
  );
};
