import React, { useContext } from "react";
import styled from "styled-components";
import { Card, P, ButtonPrimary, H4, GridColumn } from "notes";
import { EventContext, ImageCarousel } from "Components";

export const SupportLevels = ({ onDonate, total }) => {
  const { event } = useContext(EventContext);
  const { supportLevels } = event;
  const levels = Object.values(supportLevels).sort(
    (a, b) => a.amount - b.amount
  );
  return (
    <Container>
      <Header>REWARDS</Header>
      {levels.map(
        ({
          amount,
          assets,
          selectedIcon,
          titleColor,
          titleWeight,
          title,
          description,
        }) => (
          <LevelCard>
            {assets?.primary && (
              <ImageCarousel
                primary={assets.primary}
                images={assets.carousel}
              />
            )}
            <P
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "800",
              }}
            >
              {selectedIcon && (
                <IconImage
                  src={`${process.env.REACT_APP_ASSETS_PATH}/${selectedIcon}`}
                  style={{ padding: "0 8px 0 0" }}
                />
              )}
              {title}
            </P>
            {description && (
              <P
                style={{
                  color: "#596266",
                  fontSize: "15px",
                  lineHeight: "23px",
                  textAlign: "center",
                  padding: "8px 0 8px 0",
                }}
              >
                {description}
              </P>
            )}
            {total < amount && (
              <P
                style={{
                  fontSize: "17px",
                  lineHeight: "24px",
                  textAlign: "center",
                  padding: "0 0 16px 0",
                }}
              >
                You're Only ${(amount - total) / 100} Away!
              </P>
            )}
            {total >= amount ? (
              <ButtonPrimary disabled>ACHIEVED</ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={() => onDonate((amount - total) / 100)}>
                Unlock Tier
              </ButtonPrimary>
            )}
          </LevelCard>
        )
      )}
    </Container>
  );
};

const Header = styled(H4)`
  font-family: ${(props) => props.theme.fonts.workSans};
  font-weight: 500;
  color: ${(props) => (props.theme.dark ? "#ffeaad" : "#222222")};
  text-transform: uppercase;
  transition: color ease 0.3s;
  margin-bottom: 16px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
  }
`;

const Container = styled(GridColumn)`
  justify-content: center;
  padding: 0 12px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    padding: 0 24px 24px;
  }
`;

const LevelCard = styled(Card)`
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 392px;
`;

const IconImage = styled.img`
  max-height: 18px;
`;
