import React from "react";
import styled from "styled-components";
import { Subheader, GridRow } from "notes";

export default ({ checked, disabled, onChange = () => {}, ...props }) => {
  const handleChange = () => {
    return onChange(!checked);
  };
  return (
    <Container>
      <ToggleWrapper disabled={disabled} checked={checked} yCenter>
        <Checkbox
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          {...props}
        />
        <ToggleHandle disabled={disabled} checked={checked} />
        <SwitchLabel disabled={disabled} checked={checked}>
          {checked ? "On" : "Off"}
        </SwitchLabel>
      </ToggleWrapper>
    </Container>
  );
};

const Container = styled.label`
  user-select: none;
`;

const ToggleWrapper = styled(GridRow)`
  background: ${(props) =>
    props.checked
      ? props.disabled
        ? props.theme.colors.border
        : props.theme.colors.green
      : props.disabled
      ? props.theme.colors.disabledFill
      : "#f5f5f5"};
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.disabled
          ? props.theme.colors.border
          : props.theme.colors.green
        : props.disabled
        ? props.theme.colors.disabledFill
        : props.theme.colors.border};
  border-radius: 16px;
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 56px;
`;

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
`;

const ToggleHandle = styled.span`
  background: #fff;
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0 1px 8px 0 rgba(0, 0, 0, 0.24)"};
  display: inline-block;
  height: 20px;
  transition: all 50ms linear;
  left: ${(props) => (props.checked ? "34px" : "1px")};
  position: absolute;
  width: 20px;
  z-index: 3;
`;

const SwitchLabel = styled(Subheader)`
  color: ${(props) =>
    props.checked
      ? props.disabled
        ? props.theme.colors.disabledText
        : "#FFFFFF"
      : props.disabled
      ? props.theme.colors.disabledText
      : props.theme.colors.border};
  left: ${(props) => (props.checked ? "7px" : "27px")};
  position: absolute;
`;
