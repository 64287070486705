import React, { useState, Fragment, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  ButtonPrimary,
  GridColumn,
  GridRow,
  H4,
  P,
  IncrementCounter,
} from "notes";
import { EventContext, StoreContext, TimeContext } from "Components";
import { useObject } from "react-firebase-hooks/database";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { PaymentSlideout } from "../PaymentSlideout";
import { DateTime, Duration } from "luxon";
import { PaymentSidebar } from "../PaymentSidebar";

export const Vote = () => {
  const { Store } = useContext(StoreContext);
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const [apiVotes, loading, error] = useObject(
    Store.database().ref(`${event.id}/songs/ranks`)
  );
  const [voteItems, voteLoad, voteError] = useCollectionOnce(
    Store.firestore()
      .collection(`payment_items`)
      .where("eventId", "==", event.id)
      .where("type", "==", "song")
      .where("status", "==", "public")
      .limit(1)
  );
  const [votes, setVotes] = useState(null);
  const [votingSongs, setVotingSongs] = useState([]);
  const { encoreVisible, encoreEnd } = event;
  const visibleTime = DateTime.fromJSDate(encoreVisible.toDate());
  const endTime = DateTime.fromJSDate(encoreEnd.toDate());
  const isVisible = visibleTime < time;
  const timeRemaining =
    time < endTime &&
    Duration.fromMillis(endTime - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const voteData = apiVotes?.val() || {};
  useEffect(() => {
    const songs = Object.keys(event.encoreSongs)
      .map((songId) => ({
        pendingUserVotes:
          votingSongs.find((song) => song.id === songId)?.pendingUserVotes || 0,
        id: songId,
        title: event.encoreSongs[songId].title,
        percent: voteData[songId] * 100 || 0,
      }))
      .sort((a, b) => b.percent - a.percent);
    setVotingSongs(songs);
  }, [event.encoreSongs, apiVotes]);

  if (loading || voteLoad) {
    return null;
  }

  const handleVote = () => {
    setVotes(votingSongs);
  };
  const handleVoteComplete = () => {
    setVotes(null);
    setVotingSongs(
      votingSongs.map((song) => ({ ...song, pendingUserVotes: 0 }))
    );
  };
  const handleVoteCancel = () => {
    setVotes(null);
  };

  const totalPendingUserVotes = votes?.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.pendingUserVotes || 0),
    0
  );

  let metadata = {};
  if (votes) {
    votes.map((vote) => {
      metadata[vote.id] = vote.pendingUserVotes;
    });
  }

  const payment = votes
    ? voteItems.docs.map((paymentItem) => ({
        quantity: totalPendingUserVotes,
        id: paymentItem.id,
        ...paymentItem.data(),
        metadata,
      }))[0]
    : null;

  console.log(votes, payment);

  return (
    <>
      <Voting
        votingSongs={votingSongs}
        setVotingSongs={setVotingSongs}
        voteCost={1}
        onSubmit={handleVote}
        showVoteBar={isVisible}
        timeRemaining={timeRemaining}
      />
      <PaymentSidebar
        items={payment}
        onComplete={handleVoteComplete}
        onCancel={handleVoteCancel}
      />
    </>
  );
};

const Voting = ({
  votingSongs,
  setVotingSongs,
  voteCost,
  onSubmit,
  timeRemaining = false,
  showVoteBar = false,
}) => {
  const totalPendingUserVotes = votingSongs.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.pendingUserVotes || 0),
    0
  );

  let color = "#596266";

  if (timeRemaining) {
    color = "#a9dda8";
    if (timeRemaining.hours < 1) {
      if (timeRemaining.minutes < 10) {
        color = "#FFEAAD";
        if (timeRemaining.minutes < 5) {
          color = "#EDB8B4";
        }
      }
    }
  }

  return (
    <VotingPageContainer>
      <Fragment>
        <VotingMessageContainer style={{ backgroundColor: color }} yCenter>
          {timeRemaining ? (
            <>
              <VotingClosingText>{votingMessage}</VotingClosingText>
              {!!timeRemaining.hours && (
                <VotingTime>{timeRemaining.hours} Hr</VotingTime>
              )}
              <VotingTime>{timeRemaining.minutes} Min</VotingTime>
              <VotingTime>{timeRemaining.seconds} Sec</VotingTime>
            </>
          ) : (
            <VotingClosingText style={{ color: "#FFF" }}>
              Voting Is Now Closed
            </VotingClosingText>
          )}
        </VotingMessageContainer>
        <VotingListRowTitle>
          {timeRemaining && (
            <VotingEncoreTitle>
              CAST YOUR VOTES FOR THE ENCORE
            </VotingEncoreTitle>
          )}
          <VotingEncoreText>
            The song with the most number of votes at the end of the show gets
            played!
          </VotingEncoreText>
        </VotingListRowTitle>
        {votingSongs.map((song, index) => {
          return (
            <VotingListRowItem key={index} yCenter>
              <div style={{ width: "100%" }}>
                <VoteText>{song.title}</VoteText>
                {showVoteBar && <VoteBar percent={song.percent}></VoteBar>}
              </div>

              {timeRemaining && (
                <IncrementWrapper>
                  <IncrementCounter
                    value={(
                      votingSongs[index].pendingUserVotes || 0
                    ).toString()}
                    setValue={(val) => {
                      votingSongs[index].pendingUserVotes = parseInt(val);
                      setVotingSongs([...votingSongs]);
                    }}
                  />
                </IncrementWrapper>
              )}
            </VotingListRowItem>
          );
        })}
      </Fragment>
      {timeRemaining && totalPendingUserVotes > 0 && (
        <VotingSubmitButton yCenter onClick={onSubmit}>
          <VotingSubmitButtonText>
            Submit {totalPendingUserVotes}{" "}
            {totalPendingUserVotes > 1 ? votesPlural : votesSingular}
          </VotingSubmitButtonText>

          <VotingButtonValueContainer>
            <VotingSubmitButtonDivider></VotingSubmitButtonDivider>
            <VotingSubmitButtonValue>
              ${totalPendingUserVotes * voteCost}
            </VotingSubmitButtonValue>
            <div></div>
          </VotingButtonValueContainer>
        </VotingSubmitButton>
      )}
    </VotingPageContainer>
  );
};

const IncrementWrapper = styled(GridRow)`
  width: 140px;
  margin-left: 12px;
  label > div {
    background-color: ${(props) =>
      props.theme.dark ? "transparent" : props.theme.palette.gray.primary};
  }
`;

const VotingPageContainer = styled.div`
  padding: 20px;
  background-color: ${(props) => (props.theme.dark ? "#222222" : "#fafafa")};
  transition: background-color ease 2000ms;
  margin: 0 auto;
`;

const VotingMessageContainer = styled(GridRow)`
  width: 100%;
  padding: 10px 24px;
  margin-top: 20px;
  justify-content: space-between;
`;

const VotingListRow = styled(GridRow)`
  padding: 12px 0;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.dark
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lighter};
`;

const VotingListRowItem = styled(VotingListRow)`
  justify-content: space-between;
`;

const VotingListRowTitle = styled(GridColumn)`
  margin-top: 20px;
  padding: 12px 0;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.dark
        ? props.theme.palette.gray.primary
        : props.theme.palette.gray.lighter};
`;

const VoteText = styled(P)`
  color: ${(props) =>
    props.theme.dark ? "#ffffff" : props.theme.palette.black};
`;

const VotingEncoreTitle = styled(H4)`
  color: ${(props) => (props.theme.dark ? "#ffeaad" : "#222222")};
`;

const VotingEncoreText = styled(P)`
  color: ${(props) =>
    props.theme.dark ? props.theme.palette.gray.lighter : "#222222"};
  font-size: 12px;
  line-height: 15px;
`;

const VotingSubmitButton = styled(ButtonPrimary)`
  width: 100%;
  height: 60px;
  margin-top: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 16px;
  }
`;

const VotingSubmitButtonText = styled(H4)`
  color: #ffffff;
  width: 70%;
  text-align: center;
`;

const VotingButtonValueContainer = styled(GridRow)`
  justify-content: space-between;
  width: 30%;
`;

const VotingSubmitButtonDivider = styled.div`
  width: 4px;
  border-radius: 2px;
  margin-top: -5px;
  margin-bottom: -5px;
  background-color: ${(props) => props.theme.colors.action};
  box-shadow: inset 0 0 4px 0 #46367a;
`;

const VotingSubmitButtonValue = styled(H4)`
  color: #ffffff;
  text-align: center;
`;

const VoteBar = styled.div`
  height: 8px;
  margin: 5px 0;
  background: linear-gradient(270deg, #b4ec51 0%, #429321 100%);
  border-radius: 4px;
  width: ${(props) => props.percent}%;
`;

const VotingClosingText = styled(P)`
  font-weight: 700;
`;

const VotingTime = styled(VotingClosingText)`
  font-family: "Work Sans", sans-serif;
`;

const votingMessage = "Voting Closes In:";
const votesSingular = "Vote";
const votesPlural = "Votes";
const votingMinutes = 12;
const votingSeconds = 27;
const MockSongs = [
  { title: "Brown Haired Daughter", pendingUserVotes: 0, percent: 80 },
  {
    title: "Longer Than You've Been Alive",
    pendingUserVotes: 0,
    percent: 65,
  },
  { title: "Question", pendingUserVotes: 0, percent: 10 },
];
