import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "Components";
import { RedeemForm } from "./Components";

export const Redeem = ({
  match: {
    params: { voucher },
  },
}) => {
  const { Store, user } = useContext(StoreContext);
  const lookup = Store.functions().httpsCallable("vouchers-validate");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleLookup = async (voucher) => {
    setLoading(true);
    try {
      const result = await lookup({ voucher });
      if (result?.data?.success) {
        let data = result.data;
        localStorage.setItem("voucher", voucher);
        localStorage.setItem("eventId", data.paymentItem.eventId);
        localStorage.setItem("paymentItemId", data.paymentItemId);
        if (!user) {
          history.replace("/signup");
        } else {
          history.replace("/purchase");
        }
      } else {
        setError(true);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (voucher) {
      handleLookup(voucher);
    }
  }, []);
  if (voucher && !error) {
    return null;
  } else {
    return (
      <RedeemForm onSubmit={handleLookup} error={error} loading={loading} />
    );
  }
};
