import _ from "lodash";
import { Store } from "Components";

const functions = Store.functions;

const REGISTRATION_API =
  process.env.REACT_APP_STAGE === "production"
    ? "https://9kmgnz9qbf.execute-api.us-west-2.amazonaws.com/prod/registration"
    : "https://9kmgnz9qbf.execute-api.us-west-2.amazonaws.com/dev/registration";

const POLLING_URL =
  process.env.REACT_APP_STAGE === "production"
    ? "https://vipdivebar.set.live/config/config.json"
    : "https://stage.set.live/config/config.json";

// Smarty Streets Address Verification
const ADDRESS_API = process.env.REACT_APP_SMARTY_STREETS_URL;
const ADDRESS_KEY = process.env.REACT_APP_SMARTY_STREETS_KEY;
const SMARTY_STREETS_ENDPOINTS = {
  street: `${ADDRESS_API}/street-address?key=${ADDRESS_KEY}&match=strict`,
  zip: `${ADDRESS_API}/lookup?key=${ADDRESS_KEY}`,
  autocomplete: `${ADDRESS_API}/suggest?key=${ADDRESS_KEY}`,
};
const SMARTY_STREETS_REFERER =
  process.env.REACT_APP_STAGE === "production"
    ? window.location.host
    : "localhost";

const handleResponse = (response) => {
  return response.json().then((payload) => {
    const dt = response.headers.get("date");
    payload.serverDate = dt ? new Date(dt).getTime() : Date.now();
    return payload;
  });
};

const handleError = (err) => {
  throw err;
};

const buildUrlQueryString = (paramsObject) => {
  const valuePairs = Object.keys(paramsObject)
    .map((field) => {
      return !!paramsObject[field] && [field, paramsObject[field]];
    })
    .filter((item) => !!item);
  const query = new URLSearchParams(valuePairs).toString();
  return query;
};

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
  },
};

const Api = {
  exec: (method, url, options = {}) => {
    const fetchOptions = _.merge(
      _.cloneDeep(defaultOptions),
      _.cloneDeep(options),
      { method: method }
    );
    if (window.location.hostname === "localhost") {
      fetchOptions.mode = "no-cors";
    }
    return fetch(url, fetchOptions).then(handleResponse).catch(handleError);
  },
  get: (url, options) => Api.exec("GET", url, options),
  post: (url, payload, options = {}) =>
    Api.exec("POST", url, {
      ...options,
      body: typeof payload === "string" ? payload : JSON.stringify(payload),
    }),
  patch: (url, payload, options = {}) =>
    Api.exec("PATCH", url, { ...options, body: JSON.stringify(payload) }),

  register: (data) => Api.post(REGISTRATION_API, data),
  checkToken: (token) =>
    Api.get(`${REGISTRATION_API}?filter[accessToken]=${token}`),
  poll: (token) => Api.get(`${POLLING_URL}?t=${token}_${Date.now()}`),

  // Smarty Streets
  verifyAddress: (data) =>
    Api.get(`${SMARTY_STREETS_ENDPOINTS.street}&${buildUrlQueryString(data)}`, {
      headers: {
        Referer: SMARTY_STREETS_REFERER,
      },
    }),

  sendSMSVerification: (phone) =>
    functions().httpsCallable("verify-sendSMS")({ phone }),
  confirmSMSVerification: (phone, code) =>
    functions().httpsCallable("verify-confirmSMS")({ phone, code }),
  sendVerificationEmail: () =>
    functions().httpsCallable("auth-sendVerificationEmail")(),
  verifyEmail: (id, code, action) =>
    functions().httpsCallable("auth-verifyEmail")({ id, code, action }),
  resetUser: (method, source) =>
    functions().httpsCallable("auth-resetUser")({ method, source }),
  resetPassword: (data) =>
    functions().httpsCallable("auth-resetPassword")({ ...data }),
  checkUsername: (data) =>
    functions().httpsCallable("auth-username")({ ...data }),
  flagChat: (eventId, messageId) =>
    functions().httpsCallable("chat-flag")({ eventId, messageId }),
  updateRewards: (data) =>
    functions().httpsCallable("rewards-updateUserRewards")(data),
  getVideoGreetings: (eventId) =>
    functions().httpsCallable("video-list")({ eventId }),
  changeEmail: (data) => functions().httpsCallable("auth-changeEmail")(data),
};

export default Api;
