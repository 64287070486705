import React, { useMemo } from "react";
import styled, { ThemeProvider } from "styled-components";
import { GridColumn, H3, P, Icon, H4 } from "notes";
import { Badge, Card, Details, Label, Wafer } from "./Components";
import { refreshTheme } from "Routes/Landing";

export const CardTicket = React.memo(
  ({ event, ticket = false, past = false, recent = false, ...props }) => {
    const {
      artist,
      title,
      type,
      start,
      image,
      squareImage,
      purchased = false,
      hasReplay,
    } = event;

    const date = {
      dayOfWeek: start.weekdayShort,
      month: start.toFormat("MMM"),
      day: start.toFormat("dd"),
      year: start.toFormat("y"),
    };

    const updatedTheme = useMemo(() => {
      return {
        ...refreshTheme,
        cardTicket: {
          ticket,
          past,
          purchased,
          recent,
        },
      };
    }, [ticket, past, purchased, recent]);

    const backgroundImage = squareImage ?? image;

    return (
      <ThemeProvider theme={updatedTheme}>
        <Wrapper {...props}>
          <Card image={backgroundImage}>
            {purchased &&
              (past && !ticket ? (
                <Wafer small>
                  <Icon tertiary name="Ticket2" />
                </Wafer>
              ) : (
                <Wafer past>
                  <Icon tertiary name="Ticket2" />
                </Wafer>
              ))}

            {past && purchased && !!hasReplay ? (
              <Badge>
                <Icon tertiary name="Refresh" />
                <H4>Replay</H4>
              </Badge>
            ) : (
              (!past || ticket) && <Label type={type} />
            )}
            <Details date={date} artist={artist} title={title} />
          </Card>
          <MobileText>
            <H3>{artist}</H3>
            {!past && (
              <P>
                {date.dayOfWeek} • {date.month} {date.day}, {date.year}
              </P>
            )}
          </MobileText>
        </Wrapper>
      </ThemeProvider>
    );
  }
);

const Wrapper = styled(GridColumn).attrs({ as: "div" })`
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  scroll-snap-align: end;
  scroll-padding-left: 44px;
  @media only screen and ${(props) => props.theme.media.small} {
    scroll-padding-left: 11.7%;
    scroll-snap-align: center;
    ${(props) =>
      props.theme.cardTicket.ticket &&
      `& + & {
      margin-left: 16px;
    }`};
  }
`;

const MobileText = styled(GridColumn)`
  display: none;
  overflow: hidden;
  ${H3} {
    color: #ffffff;
    font-size: 17px;
    line-height: 22px;
    margin-top: 12px;
    text-align: center;
  }
  ${P} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: 14px;
    line-height: 22px;
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    display: ${(props) => (props.theme.cardTicket.ticket ? "none" : "flex")};
  }
`;
