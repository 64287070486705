import React from "react";
import styled from "styled-components";
import { GridRow } from "notes";

export const Divider = () => {
  return (
    <DividerContainer>
      <DividerStyle yCenter noShrink>
        <Caret />
      </DividerStyle>
    </DividerContainer>
  );
};

const DividerContainer = styled(GridRow)`
  flex-shrink: 0;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 20px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    width: 36px;
  }
`;

const DividerStyle = styled(GridRow)`
  background-color: ${(props) => props.theme.palette.orange.lighter};
  margin-left: 20px;
  position: relative;
  min-height: 100%;
  width: 16px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-left: 13px;
    width: 7px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    margin-left: 20px;
    width: 16px;
  }
`;

const Caret = styled.div`
  background-color: ${(props) => props.theme.palette.orange.lighter};
  display: block;
  position: absolute;
  transform: rotate(45deg);
  height: 30px;
  width: 30px;
  @media only screen and ${(props) => props.theme.media.medium} {
    right: -3px;
    height: 18px;
    width: 18px;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    right: 0px;
    height: 30px;
    width: 30px;
  }
`;
