import React from "react";
import styled from "styled-components";
import { ButtonPrimary, Icon, GridRow } from "notes";
import frontPathYellow from "./FrontPathYellow.svg";
import frontPathYellowMobile from "./FrontPathYellowMobile.svg";

export const ButtonJoin = ({ active, ...props }) => {
  return (
    <ButtonJoinStyle active={active} {...props}>
      <LeftText yCenter>
        <Icon tertiary name="Ticket2" />
        You have a ticket
      </LeftText>
      {active && <RightText>Join Now</RightText>}
    </ButtonJoinStyle>
  );
};

const LeftText = styled(GridRow)`
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  width: 166px;
  flex-shrink: 0;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 160px;
  }
`;

const RightText = styled.span`
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
`;

const ButtonJoinStyle = styled(ButtonPrimary)`
  background: ${(props) =>
    props.active
      ? `url(${frontPathYellow}) no-repeat -8px -8px, ${props.theme.palette.orange.primary}`
      : props.theme.palette.yellow.primary};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24), 0 8px 24px 0 rgba(0, 0, 0, 0.5),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.24);
  color: ${(props) => props.theme.palette.black};
  justify-content: ${(props) => (props.active ? "space-between" : "center")};
  width: 100%;
  max-width: 392px;
  text-transform: uppercase;
  padding: 9px 0 9px 18px;
  position: relative;
  svg {
    margin-right: 8px;
    path {
      fill: ${(props) => props.theme.palette.black};
    }
  }
  &:hover,
  &:focus {
    background: ${(props) =>
      props.active
        ? `url(${frontPathYellow}) no-repeat -8px -8px, ${props.theme.palette.orange.primary}`
        : props.theme.palette.yellow.primary};
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32),
      inset 0 2px 0 0 rgba(255, 255, 255, 0.24);
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 9px 0 9px 12px;
    background: ${(props) =>
      props.active
        ? `url(${frontPathYellowMobile}) no-repeat -8px -8px, ${props.theme.palette.orange.primary}`
        : props.theme.palette.yellow.primary};
    &:hover,
    &:focus {
      background: ${(props) =>
        props.active
          ? `url(${frontPathYellowMobile}) no-repeat -8px -8px, ${props.theme.palette.orange.primary}`
          : props.theme.palette.yellow.primary};
    }
  }
`;
