import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, Icon, H2, withDefaultMedia } from "notes";
import { ButtonWhite, StoreContext } from "Components";
import { TicketContext } from "Providers";
import { Details, BackgroundVideo } from "./Components";

const noShowImages = {
  desktop: `${process.env.REACT_APP_ASSETS_PATH}/99e8c23a-f23d-41fa-b293-85518a2069af.jpg`,
  mobile: `${process.env.REACT_APP_ASSETS_PATH}/4016c074-a5b0-4f54-8231-dc7db74ed3c5.jpg`,
};

export const Hero = withDefaultMedia(({ matches }) => {
  const { user } = useContext(StoreContext);
  const { upcomingTickets } = useContext(TicketContext);
  const upNextTicket = upcomingTickets[0];
  const videoBannerSource = upNextTicket?.assets?.videoBanner?.hls;
  const videoBannerThumbnail = upNextTicket?.assets?.videoBanner?.thumbnail;
  const backgroundImageSource = !!upNextTicket?.image
    ? upNextTicket.image
    : noShowImages;
  return (
    <Wrapper isLoggedIn={user} hasUpcoming={!!upNextTicket}>
      {!!upNextTicket ? (
        <Details />
      ) : (
        <Content>
          {!user ? (
            <>
              <NoShowsTitle>
                Experience and
                <br />
                interact with your
                <br />
                favorite artists
                <br />
                from the comfort
                <br />
                of your own home.
              </NoShowsTitle>
              <StyledButtonWhite
                iconLeft={<Icon indicator name="Information" />}
                onClick={() => {
                  const newWindow = window.open(
                    "https://about.set.live",
                    "_self",
                    "noopener noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
              >
                How It Works
              </StyledButtonWhite>
            </>
          ) : (
            <NoShowsTitle isLoggedIn style={{ fontWeight: "700" }}>
              New Shows
              <br />
              Coming Soon!
            </NoShowsTitle>
          )}
        </Content>
      )}
      {!!videoBannerSource && !matches.small ? (
        <BackgroundVideo
          path={videoBannerSource}
          thumbnail={videoBannerThumbnail}
        />
      ) : (
        <ArtistImage
          image={backgroundImageSource}
          hasUpcoming={!!upNextTicket}
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled(GridColumn)`
  height: ${(props) =>
    props.isLoggedIn && !props.hasUpcoming ? "428px" : "722px"};
  padding: 0 144px 138px;
  margin-bottom: -138px;
  position: relative;
  justify-content: flex-end;
  @media only screen and ${(props) => props.theme.media.small} {
    height: ${(props) =>
      props.isLoggedIn && !props.hasUpcoming ? "376px" : "560px"};
    margin-bottom: 0;
    padding: 0 24px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    height: ${(props) =>
      props.isLoggedIn && !props.hasUpcoming ? "32.82vw" : "initial"};
    margin-bottom: -10.58vw;
    padding: 11.35vw 10.5vw 10.58vw;
  }
`;

const Content = styled(GridColumn)`
  align-items: flex-start;
  z-index: 2;
`;

const ArtistImage = styled.div`
  background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 80.98%),
    url(${(props) => props.image?.desktop ?? props.image});
  background-position: ${(props) =>
    !props.hasUpcoming ? "right 30%" : "right top"};
  mask-image: linear-gradient(179deg, #ffffff 87%, rgba(255, 255, 255, 0) 98%);
  background-repeat: repeat-y, no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  ${(props) =>
    !props.hasUpcoming &&
    `
  background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 50.85%),
    url(${props.image?.desktop ?? props.image});
  `};
  @media only screen and ${(props) => props.theme.media.small} {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
      url(${(props) => props.image?.mobile ?? props.image});
    background-position: ${(props) =>
      !props.hasUpcoming ? "center 26%" : "center top"};
    background-size: cover;
    mask-image: none;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    background-size: cover;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    background-size: 1304px, 1304px;
  }
`;

const NoShowsTitle = styled(H2)`
  color: #fff;
  font-weight: 500;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 2.45vw;
    line-height: 2.91vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 25px;
    line-height: 32px;
    text-align: ${(props) => (props.isLoggedIn ? "center" : "left")};
    width: 100%;
  }
`;

const StyledButtonWhite = styled(ButtonWhite)`
  margin-top: 40px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 3.07vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 24px;
  }
`;
