import React, { useState } from "react";
import { P, Select } from "notes";
import {
  FieldContainer,
  FieldLabel,
  EditContainer,
  EditAction,
  InfoIcon,
  PurpleText,
  LinkStyle,
  Actions,
} from "../Name";
import { shirtSizesArray } from "Data";

export const EditShirtSize = ({ defaultValue, onSubmit, ...props }) => {
  const [editMode, setEditMode] = useState(false);

  let defaultSelected =
    defaultValue &&
    shirtSizesArray.find(({ text }) => text.includes(defaultValue));
  const [value, setValue] = useState(defaultSelected);

  return (
    <FieldContainer yCenter editMode={editMode}>
      {(!!defaultValue || editMode) && <FieldLabel>Shirt size</FieldLabel>}
      {editMode ? (
        <EditContainer>
          <Select
            placeholder="Select an option..."
            selected={value}
            options={shirtSizesArray}
            onChange={setValue}
            style={{ cursor: "default" }}
          />
          <Actions>
            <LinkStyle
              onClick={() => {
                setEditMode(false);
                setValue(defaultSelected);
              }}
            >
              Cancel
            </LinkStyle>
            <LinkStyle
              onClick={() => {
                setEditMode(false);
                onSubmit(value.text);
              }}
            >
              Apply
            </LinkStyle>
          </Actions>
        </EditContainer>
      ) : !!defaultValue ? (
        <>
          <P>{defaultValue}</P>
          <EditAction name="Edit" onClick={() => setEditMode(true)} />
        </>
      ) : (
        <>
          <PurpleText onClick={() => setEditMode(true)}>
            Add Shirt Size
          </PurpleText>
          {/* <InfoIcon
            indicator
            name="Information"
            onClick={() => setEditMode(true)}
          /> */}
        </>
      )}
    </FieldContainer>
  );
};
