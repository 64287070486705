import React, { useContext, useState } from "react";
import { Chat as MAXChat } from "max-chat";
import { StoreContext, EventContext, formatCurrency } from "Components";
import { Icon, withDefaultMedia } from "notes";
import { PaymentSidebar } from "../";
import { useCollection } from "react-firebase-hooks/firestore";
import "./chat.css";

const pinnedChatHelperText =
  "Pin your message to help support the artist, increase your support tier, and have your message standout from others!";

export const Chat = withDefaultMedia(({ matches, ...props }) => {
  const {
    id,
    flaggedOnClick,
    leaderboardClickHandler,
    votingClickHandler,
  } = props;

  const { event } = useContext(EventContext);
  const { Store } = useContext(StoreContext);
  const [superchats, loading, error] = useCollection(
    Store.firestore()
      .collection("payment_items")
      .where("eventId", "==", id)
      .where("type", "==", "superchat")
      .where("status", "==", "public")
      .orderBy("amount")
  );
  const pinnedOptions = superchats?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    title: doc.data().label,
    content: formatCurrency(doc.data().amount),
    icon: <Icon form name="Clock" />,
  }));

  let levels = {};
  Object.keys(event.supportLevels).map((key) => {
    let level = event.supportLevels[key];
    levels[key] = {
      ...level,
      icon: level.selectedIcon
        ? `${process.env.REACT_APP_ASSETS_PATH}/${level.selectedIcon}`
        : null,
    };
  });

  const [pendingPayment, setPendingPayment] = useState();
  const [items, setItems] = useState();
  const handleSuperchat = async (items, content) => {
    const prom = new Promise((resolve, reject) => {
      setItems({
        amount: items.amount,
        quantity: 1,
        id: items.id,
        label: items.label,
        duration: items.duration,
        type: "superchat",
        metadata: {
          id: content.id,
          content: content.content,
        },
      });
      setPendingPayment({ resolve, reject });
    });
    return prom;
  };
  const handlePaymentComplete = () => {
    pendingPayment.resolve();
    setPendingPayment();
    setItems();
  };
  const handlePaymentCancel = () => {
    pendingPayment.reject();
    setPendingPayment();
    setItems();
  };
  return (
    <>
      <PaymentSidebar
        items={items}
        onComplete={handlePaymentComplete}
        onCancel={handlePaymentCancel}
      />
      <MAXChat
        db={Store.database}
        auth={Store.auth}
        firestore={Store.firestore}
        path={id}
        pinnedOptions={pinnedOptions}
        flaggedOnClick={flaggedOnClick}
        pinnedChatHelperText={pinnedChatHelperText}
        handleSuperchat={handleSuperchat}
        supportLevels={levels}
        leaderboardClickHandler={leaderboardClickHandler}
        votingClickHandler={votingClickHandler}
        emoji={!matches.small}
      />
    </>
  );
});
