import React from "react";
import styled from "styled-components";
import { Modal } from "notes";
import { AddCard } from "Components/Payment/Components";

export const AddCardModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalStyle size="small" open={open} setOpen={handleClose}>
      <AddCard onComplete={handleClose} />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  margin-top: 8.589vw;
  max-width: 472px;
  & > svg {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
    padding: 0;
    max-width: 100%;
    min-height: 258px;
    position: fixed;
    bottom: 0;
  }
`;
