export const shirtSizesArray = [
  { text: "Women's Small", id: "fSmall" },
  { text: "Women's Medium", id: "fMedium" },
  { text: "Women's Large", id: "fLarge" },
  { text: "Women's XL", id: "fExtraLarge" },
  { text: "Men's Small", id: "mSmall" },
  { text: "Men's Medium", id: "mMedium" },
  { text: "Men's Large", id: "mLarge" },
  { text: "Men's XL", id: "mExtraLarge" },
];
