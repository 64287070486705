export const relationshipsArray = [
  "Friend",
  "Boyfriend",
  "Girlfriend",
  "Fiance",
  "Husband",
  "Wife",
  "Partner",
  "Dad",
  "Mom",
  "Parent",
  "Brother",
  "Sister",
  "Sibling",
  "Cousin",
  "Aunt",
  "Uncle",
  "Other (describe below)",
];

export const thirdPartyConversationArray = [
  "A special hello",
  "A shoutout",
  "Birthday",
  "Wedding",
  "Anniversary",
];

export const selfConversationArray = [
  "A special hello for me",
  "A thank you message",
];
