import React, { createContext, useContext, useEffect } from "react";
import { StoreContext, formatShow, TimeContext } from "Components";
import { useCollection } from "react-firebase-hooks/firestore";

export const TicketContext = createContext();
export const TicketConsumer = TicketContext.Consumer;

export const TicketProvider = ({ children }) => {
  const { Store, tickets } = useContext(StoreContext);
  const { time } = useContext(TimeContext);

  const [onsite, onsiteLoading, onsiteError] = useCollection(
    Store.firestore().collection("events")
  );

  const [offsite, offsiteLoading, offsiteError] = useCollection(
    Store.firestore().collection("offsite_events").where("active", "==", true)
  );

  useEffect(() => {
    localStorage.clear();
  }, []);

  if (onsiteLoading || offsiteLoading) {
    return null;
  }

  const onsiteEvents =
    onsite?.docs
      .map((show) => {
        const id = show.id;
        const data = show.data();
        const purchased = !!tickets?.find(
          (tic) => tic?.paymentItem?.eventId === id
        );
        return formatShow({ ...data, id, purchased: purchased });
      })
      .filter(({ active, purchased }) => active || purchased) || [];

  const offsiteEvents =
    offsite?.docs.map((show) => {
      const id = show.id;
      const data = show.data();
      return formatShow({ ...data, id, purchased: false });
    }) || [];

  const dateSortedUpcoming = onsiteEvents
    .filter(({ end }) => end > time)
    .concat(offsiteEvents.filter(({ end }) => end > time))
    .sort((a, b) => a.end - b.end);

  const upcomingTickets = dateSortedUpcoming.sort(
    (a, b) => a.priority - b.priority
  );

  const pastTickets = onsiteEvents
    .filter(({ end }) => end < time)
    .concat(offsiteEvents.filter(({ end }) => end < time))
    .map((event) => ({ ...event, past: true }));

  const myTickets = dateSortedUpcoming
    .concat(pastTickets.sort((a, b) => b.end - a.end))
    .filter(({ purchased }) => purchased);

  const recentTickets = pastTickets
    .filter(({ type }) => type !== "replay")
    .slice(0, 10)
    .sort((a, b) => a.end - b.end)
    .sort((a, b) => a.priority - b.priority);

  return (
    <TicketContext.Provider
      value={{
        myTickets,
        upcomingTickets,
        recentTickets,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
