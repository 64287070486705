import { useContext, useMemo } from "react";
import { StoreContext } from "Components";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";

export const useEvent = (eventId) => {
  const { Store } = useContext(StoreContext);
  const [list, load, err] = useCollection(
    Store.firestore()
      .collection("events")
      .where("slugs", "array-contains", eventId)
  );
  const [single, loads, errs] = useDocument(
    Store.firestore().doc(`events/${eventId}`)
  );

  const topresult = useMemo(() => {
    if (list?.docs?.length) {
      return {
        ...list.docs[0].data(),
        id: list.docs[0].id,
      };
    }
  }, [list]);

  const singled = useMemo(() => {
    if (single?.data()) {
      return {
        ...single.data(),
        id: single.id,
      };
    }
  }, [single]);

  if (singled) {
    return [singled, load, err];
  }

  if (topresult) {
    return [topresult, loads, errs];
  }

  return [null, load || loads, err || errs];
};
