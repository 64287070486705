import React, { useContext, useReducer, useState } from "react";
import styled from "styled-components";
import { StoreContext, FieldMessage } from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  relationshipsArray,
  selfConversationArray,
  thirdPartyConversationArray,
} from "Data";
import {
  GridRow,
  H3,
  P,
  Radio,
  GridColumn,
  Label,
  Footnote,
  TextArea,
  ButtonPrimary,
  Input,
  Select,
  Icon,
} from "notes";
import { reducer, validate, Api } from "modules";
import { selfSchema, thirdPartySchema } from "./schema";
import { Actions, CancelButton, Form } from "../../Components";

const relationshipOptions = relationshipsArray.map((item, index) => {
  return { id: ++index, text: item };
});

const selfConversationStarter = selfConversationArray.map((item, index) => {
  return { id: ++index, text: item };
});

const thirdPartyConversationStarter = thirdPartyConversationArray.map(
  (item, index) => {
    return { id: ++index, text: item };
  }
);

const initState = {
  messageTarget: "self",
  name: "",
  email: "",
  targetName: "",
  relationship: "",
  conversation: "",
  userMessage: "",
};

export const VideoForm = ({ rewardId, userResponse, onComplete }) => {
  const { user, Store } = useContext(StoreContext);
  const [errors, setErrors] = useState({});

  const [details, setDetails] = useReducer(reducer, userResponse || initState);

  const [profile, loading, error] = useDocumentData(
    Store.firestore().doc(`profiles/${user?.uid}`)
  );

  let selectedRelationship =
    details.relationship &&
    relationshipOptions.find(({ text }) => text.includes(details.relationship));

  let selectedConversation = details.conversation
    ? details.messageTarget === "self"
      ? selfConversationStarter.find(({ text }) =>
          text.includes(details.conversation)
        )
      : thirdPartyConversationStarter.find(({ text }) =>
          text.includes(details.conversation)
        )
    : "";

  const charLimit = 128;

  const handleClose = () => {
    onComplete(false);
  };

  const handleSubmit = () => {
    const schema =
      details.messageTarget === "self" ? selfSchema : thirdPartySchema;

    let requirements = {
      messageTarget: details.messageTarget,
      name: `${profile?.name?.firstName} ${profile?.name?.lastName}`,
      email: details.email || "",
      targetName: details.targetName,
      relationship: details.relationship,
      conversation: details.conversation,
      userMessage: details.userMessage,
    };

    const errors = validate(requirements, schema);

    if (!errors) {
      Api.updateRewards({
        userRewardId: `${user.uid}#${rewardId}`,
        userResponse: { video: requirements },
        updateAll: false,
      }).then(() => handleClose());
    } else {
      setErrors(errors);
    }
  };

  const handleChange = (name, value) => {
    setDetails({ [name]: value });
    delete errors[name];
    setErrors(errors);
  };

  return (
    <Form>
      <H3>Video Message Details</H3>
      <P>
        Please choose a conversation starter below. You can suggest a topic for
        the artist, but it will be up to them what is contained in their
        message.
      </P>
      <Container>
        <Label>Where should we send the greeting?</Label>
        <Input
          leftIcon={<Icon form name="Email" />}
          placeholder="johndoe@email.com"
          onChange={(value) => handleChange("email", value)}
          value={details.email}
          error={!!errors.email}
        />
        <FieldMessage
          active={!!errors.email}
          content="This must be a valid email and cannot be blank."
        />

        <Label>Who's this message for?</Label>
        <Radio
          checked={details.messageTarget === "self"}
          onChange={() => {
            setDetails({ messageTarget: "self", conversation: "" });
            setErrors({});
          }}
        >
          Myself
        </Radio>
        <Radio
          checked={details.messageTarget === "thirdParty"}
          onChange={() => {
            setDetails({ messageTarget: "thirdParty", conversation: "" });
            setErrors({});
          }}
        >
          Someone Else
        </Radio>

        <Input
          style={{ marginTop: "5px" }}
          onChange={(value) => handleChange("targetName", value)}
          value={details.targetName}
          placeholder="Name..."
          error={!!errors.targetName}
        />
        <FieldMessage
          active={!!errors.targetName}
          content="This is a required field and cannot be blank."
        />

        {details.messageTarget === "thirdParty" && (
          <GridColumn>
            <Label>Relationship to the recipient</Label>
            <Select
              placeholder="Select an option..."
              onChange={(value) => handleChange("relationship", value.text)}
              selected={selectedRelationship}
              options={relationshipOptions}
              error={!!errors.relationship}
            />
            <FieldMessage
              active={!!errors.relationship}
              content="This is a required field and cannot be blank."
            />
          </GridColumn>
        )}

        <GridColumn>
          <Label>Conversation Starter</Label>
          <Select
            placeholder="Select an option..."
            onChange={(value) => handleChange("conversation", value.text)}
            selected={selectedConversation}
            options={
              details.messageTarget === "self"
                ? selfConversationStarter
                : thirdPartyConversationStarter
            }
            error={!!errors.conversation}
          />
          <FieldMessage
            active={!!errors.conversation}
            content="This is a required field and cannot be blank."
          />
        </GridColumn>
      </Container>

      <Label>Notes for the Artist</Label>
      <TextAreaStyle
        onChange={(e) => setDetails({ userMessage: e.target.value })}
        value={details.userMessage}
        placeholder="Please add a few notes here that might help the artist personalize a greeting for you. A note about pronouncing the recipient's name can also be helpful to the artist."
        maxLength={charLimit}
      />
      <Counter xEnd yCenter>
        <Footnote>
          {details.userMessage.length}/{charLimit}
        </Footnote>
      </Counter>

      <Actions xEnd>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ButtonPrimary onClick={handleSubmit}>Confirm</ButtonPrimary>
      </Actions>
    </Form>
  );
};

const Container = styled(GridColumn)`
  max-width: 392px;
  width: 100%;
`;

const TextAreaStyle = styled(TextArea)`
  border-radius: 4px 4px 0 0;
  border-bottom: 0;
`;

const Counter = styled(GridRow)`
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  border-radius: 0 0 4px 4px;
  background-color: #fafafa;
  height: 30px;
  padding: 0 16px;
`;
