import React, { useState } from "react";
import styled from "styled-components";
import { H4, Link, P } from "notes";
import { VideoForm } from "./VideoForm";
import { EditLink, RequirementModal } from "../../Components";

export const VideoDetails = ({ rewardId, processingStatus, userResponse }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RequirementModal open={open} setOpen={setOpen}>
        <VideoForm
          rewardId={rewardId}
          userResponse={userResponse}
          onComplete={() => setOpen(false)}
        />
      </RequirementModal>

      {!!userResponse?.userMessage ? (
        <>
          {userResponse.messageTarget === "thirdParty" ? (
            <>
              <ResponsiveH4>
                Email to: {userResponse.email}
                <br />
                For: {userResponse.targetName}
                <br />
                Relationship: {userResponse.relationship}
                <br />
                Conversation Starter: {userResponse.conversation}
              </ResponsiveH4>
              <Quote>
                <span>“</span>
                {userResponse.userMessage}
                <span>”</span>
              </Quote>
            </>
          ) : (
            <>
              <ResponsiveH4>
                Email to: {userResponse.email}
                <br />
                Conversation Starter: {userResponse.conversation}
              </ResponsiveH4>
              <Quote>
                <span>“</span>
                {userResponse.userMessage}
                <span>”</span>
              </Quote>
            </>
          )}
          {!processingStatus && <EditLink onClick={() => setOpen(true)} />}
        </>
      ) : (
        <Link onClick={() => setOpen(true)}>Add Message Details</Link>
      )}
    </>
  );
};

const Quote = styled(P)`
  font-style: italic;
  ${H4} + & {
    margin-top: 24px;
  }
  span {
    font-weight: 600;
  }
`;

const ResponsiveH4 = styled(H4)`
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 15px;
  }
`;
