import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridRow, GridColumn, Footnote, Link } from "notes";
import { Section } from "Components";
import { FooterCurve, LogoSetLiveWhite } from "Images";
import { fireAnalytics } from "services";

export const FooterBlock = ({ secondary, tertiary, ...props }) => {
  const history = useHistory();
  const handleClick = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
    fireAnalytics("click", {
      event_category: "footer_link",
      event_label: path.replace(/^\/|\/$/g, ""),
    });
  };
  return (
    <GridColumn {...props}>
      <Curve show={secondary} />
      <BlockDefault tertiary={tertiary}>
        <Section yCenter>
          <Row tertiary={tertiary}>
            <LogoContainer tertiary={tertiary}>
              {!tertiary && (
                <LogoSize
                  name="SetLiveReversed"
                  onClick={() => handleClick("/")}
                  style={{ cursor: "pointer" }}
                />
              )}
              <Footnote>&copy; 2021 Set.Live</Footnote>
            </LogoContainer>

            <LinkContainer tertiary={tertiary} yEnd>
              <FooterLink
                onClick={() => handleClick("/gift")}
                tertiary={tertiary}
              >
                Gift Tickets
              </FooterLink>
              <FooterLink href="https://about.set.live/" tertiary={tertiary}>
                Artists
              </FooterLink>
              <FooterLink
                onClick={() => handleClick("/privacy")}
                tertiary={tertiary}
              >
                Privacy Policy
              </FooterLink>
              <FooterLink
                onClick={() => handleClick("/terms")}
                tertiary={tertiary}
              >
                Terms of Use
              </FooterLink>
              <FooterLink
                onClick={() => handleClick("/contact")}
                tertiary={tertiary}
              >
                Contact Us
              </FooterLink>
              <FooterLink
                onClick={() => handleClick("/faq")}
                tertiary={tertiary}
              >
                FAQ
              </FooterLink>
            </LinkContainer>
          </Row>
        </Section>
      </BlockDefault>
      <LanguageContainer tertiary={tertiary} />
    </GridColumn>
  );
};

const Curve = styled(FooterCurve)`
  display: ${(props) => (props.show ? "block" : "none")};
  margin-bottom: -1px;
  width: 100%;
  height: auto;
`;

const BlockDefault = styled(GridColumn)`
  background-color: ${(props) => (props.tertiary ? "#FFFFFF" : "#000000")};
  padding: ${(props) => (props.tertiary ? "24px 40px" : "32px 40px")};
  @media only screen and ${(props) => props.theme.media.small} {
    padding: ${(props) => (props.tertiary ? "24px" : "32px 24px")};
    padding-bottom: 0;
  }
`;

const LogoContainer = styled(GridColumn)`
  align-items: ${(props) => (props.tertiary ? "center" : "flex-start")};
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: ${(props) => (props.tertiary ? "8px" : "32px")};
  }
`;

const LogoSize = styled(LogoSetLiveWhite)`
  width: 114px;
  height: 36px;
`;

const Row = styled(GridRow)`
  justify-content: space-between;
  ${(props) => props.tertiary && "flex-direction: column"};
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-style: normal;
    margin-top: ${(props) => (props.tertiary ? 0 : "7px")};
    margin-bottom: ${(props) => (props.tertiary ? "8px" : 0)};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    height: auto;
  }
`;

const FooterLink = styled(Link)`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) =>
    props.tertiary ? props.theme.palette.gray.primary : "#FFFFFF"};
  &:hover,
  &:focus {
    color: #fff;
  }
  & + & {
    margin-left: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 24px;
    width: ${(props) => (props.tertiary ? "auto" : "50%")};
    color: ${(props) =>
      props.tertiary ? props.theme.palette.gray.primary : "#FFFFFF"};
    & + & {
      margin-left: ${(props) => (props.tertiary ? "12px" : 0)};
    }
  }
`;

const LinkContainer = styled(GridRow)`
  align-content: flex-end;
  justify-content: ${(props) => (props.tertiary ? "center" : "flex-end")};
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: ${(props) => (props.tertiary ? "center" : "flex-start")};
    flex-wrap: wrap;
  }
`;

const LanguageContainer = styled(GridRow)`
  background-color: ${(props) =>
    props.tertiary ? "#ffffff" : props.theme.palette.black};
  ${(props) =>
    props.tertiary &&
    `border-top: 1px solid ${props.theme.palette.gray.lightest}`};
  height: 40px;
`;
