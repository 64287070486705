import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { googleLogin, facebookLogin } from "services";
import { GridColumn, ButtonGoogle, ButtonFacebook } from "notes";
import { StoreContext } from "Components";

export const OpenAuth = ({ onSuccess }) => {
  const { Store } = useContext(StoreContext);
  const signInWithGoogle = async () => {
    try {
      googleLogin();
    } catch (err) {
      console.log(err);
      window.scrollTo(0, 0);
    }
  };

  const signInWithFacebook = async () => {
    try {
      facebookLogin();
    } catch (err) {
      console.log(err);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    Store.auth()
      .getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        var user = result.user;
        if (user) {
          onSuccess(result, result?.credential?.providerId);
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  }, []);

  return (
    <GridColumn noShrink>
      <ButtonFacebook onClick={signInWithFacebook}>
        Continue With Facebook
      </ButtonFacebook>
      <GoogleButton onClick={signInWithGoogle}>
        Continue With Google
      </GoogleButton>
    </GridColumn>
  );
};

const GoogleButton = styled(ButtonGoogle)`
  border: 1px solid ${(props) => props.theme.palette.gray.primary};
  margin-top: 8px;
`;
