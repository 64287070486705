import React, { useState, useRef, useContext, useEffect } from "react";
import { StoreContext, ModalContext } from "Components";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FormWrapper,
  FormContainer,
  LoaderInline,
  FieldMessage,
} from "Components";
import Joi from "@hapi/joi";
import { validate } from "modules";

import {
  H3,
  Input,
  Icon,
  P,
  Label,
  GridColumn,
  Radio,
  CountrySelector,
  Footnote,
  ErrorMessage,
  ButtonPrimary,
} from "notes";

import { Api } from "modules";
import { Store } from "Components";
import { LANGUAGE_OPTIONS } from "lib";
import _ from "lodash";

const { auth } = Store;

const PhoneFieldContainer = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
`;

const PhoneInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const passwordResetMessage = `Please choose how you would like to locate your account. Your code will then be sent to the form of communication you have previously verified.`;

const messagingRatesMessage = `Standard data and messaging rates apply`;

const messageConsent = `I consent to receiving recurring automated notifications about Set.live events by text message through an automatic telephone dialing system. `;

const verificationOptions = [
  {
    method: "email",
    title: "Email address",
    label: "email address",
    alternate: "phone number",
  },
  {
    method: "sms",
    title: "Phone number",
    label: "phone number",
    alternate: "email address",
  },
];

const schemas = {
  email: Joi.object({
    source: Joi.string()
      .email({ minDomainSegments: 2, tlds: false })
      .required()
      .messages({
        "string.email": `"Email" must be a valid email address`,
        "any.required": '"Email" is a required field',
      }),
  }),
  sms: Joi.object({
    source: Joi.string().required().messages({
      "any.required": '"Phone number" is a required field',
    }),
  }),
};

export default () => {
  const { user } = useContext(StoreContext);
  const { toggleLoginModal } = useContext(ModalContext);

  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);
  const [country, setCountry] = useState("US");
  const [verificationMethod, setVerificationMethod] = useState(0);
  const [source, setSource] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  const verifier = useRef();

  useEffect(() => {
    toggleLoginModal(false);
  }, []);

  const locateAccount = async () => {
    setSubmitted(true);
    const option = verificationOptions[verificationMethod];
    const { method } = option;
    let errors = validate({ source }, schemas[method]);
    setFormErrors(errors);
    if (errors) {
      setSubmitted(false);
      return;
    }
    try {
      let value = source.replace(/\s/g, "");

      let verificationId;

      if (method === "sms") {
        // Get country code
        const countryOption = _.find(
          LANGUAGE_OPTIONS,
          ({ value }) => value === country
        );

        value = `${countryOption?.prefix ?? "+1"}${source}`;
      }
      console.log("VERIFY SOURCE", source);
      const { data } = await Api.resetUser(method, value);
      console.log("GOT RESET DATA", data);
      verificationId = data;
      let confirmationResult;

      // extra handling if SMS
      if (method === "sms") {
        if (!verifier.current) {
          verifier.current = new auth.RecaptchaVerifier("submit", {
            size: "invisible",
          });
        }

        // Get country code
        const countryOption = _.find(
          LANGUAGE_OPTIONS,
          ({ value }) => value === country
        );

        value = `${countryOption?.prefix ?? "+1"}${source}`;

        const confirmationResult = await auth().signInWithPhoneNumber(
          value,
          verifier.current
        );

        window.confirmationResult = confirmationResult;

        verificationId = confirmationResult.verificationId;
      }

      history.push("/verify", {
        method,
        source: value,
        action: "reset",
        verificationId,
      });
    } catch (err) {
      console.log(err);
      setErrorMessage(
        <p>
          No account for{" "}
          <span style={{ fontWeight: "bolder", textDecoration: "underline" }}>
            {source}
          </span>{" "}
          was found. Please revise {option.label} or locate your account by{" "}
          {option.alternate}.
        </p>
      );
    } finally {
      setSubmitted(false);
    }
  };

  const handleChange = (value, e) => {
    setSource(value);
    if (formErrors) {
      delete formErrors[e.target.name];
      setFormErrors(formErrors);
    }
    setErrorMessage(null);
  };

  console.log("LANG OPTS", LANGUAGE_OPTIONS);

  return (
    <FormWrapper>
      <FormContainerStyle>
        <H3>Forgot Password</H3>
        <P>{passwordResetMessage}</P>

        {errorMessage && <ErrorMessage content={errorMessage} />}

        <Label>Locate account by</Label>
        {verificationOptions.map(({ title, disabled }, index) => {
          return (
            <Radio
              key={index}
              disabled={disabled}
              checked={index === verificationMethod}
              onChange={(value) => {
                if (value) {
                  setVerificationMethod(index);
                  setSource("");
                }
              }}
            >
              {title}
            </Radio>
          );
        })}

        {(() => {
          switch (verificationMethod) {
            case 0:
              return (
                <FieldContainer>
                  <Label>Email address</Label>

                  <Input
                    disabled={submitted}
                    name="email"
                    leftIcon={<Icon form name="Email" />}
                    placeholder="john@email.com"
                    value={source}
                    onChange={handleChange}
                  />
                  <FieldMessage
                    active={!!formErrors?.hasOwnProperty("email")}
                    content={formErrors?.email}
                  />
                </FieldContainer>
              );
            case 1:
              return (
                <FieldContainer>
                  <Label>Phone Number</Label>
                  <PhoneFieldContainer>
                    <PhoneInput
                      name="phone"
                      disabled={submitted}
                      value={source}
                      leftIcon={<Icon form name="Phone" />}
                      placeholder="(000) 000-0000"
                      onChange={handleChange}
                    />
                    <CountrySelector
                      disabled={submitted}
                      onChange={({ value }) => {
                        setCountry(value);
                      }}
                      selected={
                        LANGUAGE_OPTIONS[
                          _.findIndex(
                            LANGUAGE_OPTIONS,
                            ({ value }) => value === country
                          )
                        ]
                      }
                      options={LANGUAGE_OPTIONS}
                      country
                    />
                  </PhoneFieldContainer>
                  <Footnote>{messagingRatesMessage}</Footnote>
                </FieldContainer>
              );
            default:
              break;
          }
        })()}
        <button id="submit" style={{ display: "none" }} />
        <ButtonPrimary disabled={submitted} onClick={locateAccount}>
          {submitted ? <LoaderInline /> : "Locate Account"}
        </ButtonPrimary>
      </FormContainerStyle>
    </FormWrapper>
  );
};

const FormContainerStyle = styled(FormContainer)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  margin: 40px 0 72px 0;
  padding: 40px;
  position: relative;
  min-height: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: 40px 24px 104px 24px;
  }
`;

const FieldContainer = styled(GridColumn)`
  margin-top: 24px;
  margin-bottom: 40px;
  ${Footnote} {
    margin-top: 8px;
  }
`;
