import React, { useContext } from "react";
import styled from "styled-components";
import { GridRow, GridColumn, H1, H4, P } from "notes";
import {
  HeaderSecondary,
  StoreContext,
  FooterBlock,
  FormMessage,
  withProtectedRoute,
} from "Components";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { EventRewards } from "./Event";

export const Rewards = withProtectedRoute(() => {
  const { user, Store } = useContext(StoreContext);

  const [rewards, loading, error] = useCollectionData(
    Store.firestore()
      .collection("user_reward_items")
      .where("uid", "==", user?.uid)
  );

  if (loading) {
    return null;
  }

  const allRewardIds = rewards.map((event) => event.eventId);
  const uniqueEvents = [...new Set(allRewardIds)];

  let rewardsByEvent = [];

  uniqueEvents.forEach((eventId) => {
    let filteredRewards = rewards.filter((item) => item.eventId === eventId);
    rewardsByEvent.push({ eventId: eventId, rewards: filteredRewards });
  });

  const allRequirements = rewards
    .filter(({ rewardItem }) => rewardItem.requires)
    .reduce((acc, it) => {
      let items = [];
      Object.keys(it.rewardItem.requires).map((item) => items.push(item));
      return acc.concat(items);
    }, []);

  const allUserResponses = rewards
    .filter(({ userResponse }) => userResponse)
    .reduce((acc, it) => {
      let items = [];
      Object.keys(it.userResponse).map((item) => items.push(item));
      return acc.concat(items);
    }, []);

  const pastRewards = rewardsByEvent.filter(
    ({ rewards }) =>
      rewards.length ===
      rewards.filter(
        (reward) =>
          reward.status === "shipped" || !!reward.rewardItem?.data?.link
      ).length
  );

  const pastRewardIds = pastRewards.map(({ eventId }) => eventId);

  const recentRewards = rewardsByEvent.filter(
    ({ eventId }) => !pastRewardIds.includes(eventId)
  );

  return (
    <Wrapper>
      <HeaderSecondary banner />
      <Container stretch>
        <PageHeader>
          <H1>My Rewards</H1>
        </PageHeader>

        <Content>
          <FormMessage
            style={{ marginBottom: "24px" }}
            type="info"
            active={allRequirements.length > allUserResponses.length}
            content="Please provide the missing information below to receive your rewards!"
          />
          <RewardLabel>Recent Rewards</RewardLabel>
          {rewards.length > 0 && recentRewards.length > 0 ? (
            recentRewards.map((rewards) => <EventRewards {...rewards} />)
          ) : (
            <P>You don't have any recent rewards.</P>
          )}
        </Content>
      </Container>
      <PastRewards stretch>
        <Container>
          <Content>
            <RewardLabel>Past Rewards</RewardLabel>
            {rewards.length > 0 && pastRewards.length > 0 ? (
              pastRewards.map((rewards) => (
                <EventRewards pastReward {...rewards} />
              ))
            ) : (
              <P>You don't have any past rewards.</P>
            )}
          </Content>
        </Container>
      </PastRewards>

      <FooterBlock />
    </Wrapper>
  );
});

const PastRewards = styled(GridColumn)`
  border-top: 2px solid ${(props) => props.theme.palette.gray.lighter};
  background-color: #ffffff;
  width: 100%;
`;

const Wrapper = styled(GridColumn)`
  background-color: #fafafa;
  width: 100%;
  min-height: 100vh;
`;

const PageHeader = styled(GridRow)`
  margin-bottom: 40px;
  h1 {
    color: ${(props) => props.theme.palette.black};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding-left: 24px;
  }
`;

const Container = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  max-width: 1016px;
  padding: 40px 0 96px 0;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding-top: 24px;
    padding-bottom: 56px;
  }
`;

const Content = styled(GridColumn)`
  flex-grow: 1;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;

const RewardLabel = styled(H4)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-weight: 700;
  margin-bottom: 8px;
`;
