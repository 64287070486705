import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext, withProtectedRoute, LoaderInline } from "Components";
import { RECORDING_STATUS, MESSAGE_STATUS, isSafari } from "./const";
import _ from "lodash";
import moment from "moment";
import {
  Grid,
  GridRow,
  GridColumn,
  P,
  Icon,
  Modal,
  H3,
  ButtonPrimaryReverse,
  Link,
} from "notes";
import {
  PageContainer,
  FieldLabel,
  CameraWrapper,
  HiddenVideo,
  VideoCanvas,
  CanvasContainer,
  StopIcon,
  Dimmer,
  CountdownContainer,
  CountdownTimer,
  TimerTitle,
  Timer,
  CancelTimer,
  Uploading,
  UploadingText,
  Spinner,
  Divider,
  FormFields,
  FieldRow,
  SetLiveLogo,
  LogoWrapper,
  PageHeader,
  HeaderContainer,
  Header,
  SubHeader,
  EventTitle,
  StatusBar,
  EventDate,
  MessageCount,
  ViewAll,
  NavWrapper,
  NavLeft,
  NavRight,
  CurrentMessage,
  MessageIndex,
  CurrentUser,
  Success,
  Error,
  Info,
  StatusMessage,
  RecordButton,
  RecordIcon,
  StopIconContainer,
  StopIconBackground,
  DiscardButton,
  DeleteIcon,
  Logout,
  FullScreenDarkModal,
  CloseIcon,
  PlaybackContainer,
  SuccessStatusMessage,
  CheckmarkBox,
  PageCover,
} from "./styled";
import { logout } from "services";
import { Toggle, Playback, Duration } from "./components";
import { Api, validate } from "modules";
import Joi from "@hapi/joi";

const schema = Joi.object({
  conversation: Joi.string().min(1).required(),
  email: Joi.string().min(1).required(),
  name: Joi.string().min(1).required(),
  userMessage: Joi.string().min(1).required(),
});

let chunks = [];

// 2Irur56TvRSYNVYzNl8R

export default withProtectedRoute(
  ({
    match: {
      params: { eventId },
    },
  }) => {
    const { username, user, Store } = useContext(StoreContext);
    const history = useHistory();

    if (!user) {
      history.push("/login");
      return;
    }

    const [recordingStatus, setRecordingStatus] = useState(
      RECORDING_STATUS.NONE
    );
    const [countdown, setCountdown] = useState();
    const [autoPlayback, setAutoPlayback] = useState(true);
    const [recordings, setRecordings] = useState([]);
    const [recordingIndex, setRecordingIndex] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [modal, toggleModal] = useState(false);
    const [recordingTimer, setRecordingTimer] = useState();
    const [videoMessageEvent, setVideoMessageEvent] = useState({});
    const [viewMessage, toggleViewMessage] = useState(false);
    const [videoUrl, setVideoUrl] = useState();
    const [playbackUrl, setPlaybackUrl] = useState();
    const [ready, setReady] = useState(false);

    const inputStreamRef = useRef();
    const videoRef = useRef();
    const mediaRecorderRef = useRef();
    const canvasRef = useRef();
    const requestAnimationRef = useRef();

    useEffect(() => {
      window.addEventListener("beforeunload", handleWindowUnload);
      getCamera()
        .then((stream) => {
          if (!videoRef.current) {
            videoRef.current = document.getElementById("video-ref");
          }
          if (!stream) {
            alert("Could not get camera");
            return;
          }
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          requestAnimationRef.current = requestAnimationFrame(updateCanvas);
        })
        .catch((err) => {
          console.log(err.message);
        });
      loadRecordings();
      return () => {
        cancelAnimationFrame(requestAnimationRef.current);
        window.removeEventListener("beforeunload", handleWindowUnload);
      };
    }, []);

    useEffect(() => {
      setRecordingIndex(0);
      loadRecordings();
    }, [eventId]);

    useEffect(() => {
      if (countdown === null) return;
      if (countdown === 0) {
        setRecordingStatus(RECORDING_STATUS.RECORDING);
        return;
      }
      const timer = window.setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }, [countdown]);

    useEffect(() => {
      if (
        recordingTimer === null ||
        recordingTimer < 0 ||
        recordingStatus === RECORDING_STATUS.STOPPED
      )
        return;
      const timer = window.setTimeout(() => {
        setRecordingTimer(recordingTimer + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }, [recordingTimer]);

    useEffect(() => {
      switch (recordingStatus) {
        case RECORDING_STATUS.NONE:
          resetPlayback();
          setCountdown(null);
          break;
        case RECORDING_STATUS.PREPARING:
          setCountdown(5);
          break;
        case RECORDING_STATUS.RECORDING:
          startRecording();
          break;
        case RECORDING_STATUS.STOPPED:
          if (
            mediaRecorderRef.current &&
            mediaRecorderRef.current.state === "recording"
          ) {
            // make sure we get the last chunk
            window.setTimeout(
              () => {
                mediaRecorderRef.current.stop();
                // Only call this if the current browser is not Safari as
                // the data handler for the recorder will call it.
                if (!isSafari) handleVideoStop();
              },
              isSafari ? 0 : 250
            );
          }
          break;
      }
    }, [recordingStatus]);

    useEffect(() => {
      const count = recordings.filter(
        ({ status }) => status === MESSAGE_STATUS.COMPLETED
      ).length;
      setCompletedCount(count);
    }, [recordings]);

    const handleLogout = async () => {
      try {
        history.push("/");
        await logout();
      } catch (err) {
        console.log(err);
      }
    };

    const loadRecordings = async () => {
      // get documents from firestore
      // first pull event document
      if (!eventId || !user) return;
      setReady(false);
      try {
        const eventRef = Store.firestore().collection("events").doc(eventId);
        const event = await eventRef.get();
        // verify the user has access
        const artistIds = _.get(event.data(), "artistIds", []);
        if (artistIds.indexOf(user.uid) < 0) {
          history.push("/");
          return;
        }
        setVideoMessageEvent(event.data());
        let firstIndex = -1;
        const result = await Api.getVideoGreetings(eventId);
        if (!result.data.success) {
          history.push("/");
          return;
        }
        const complete = [];
        const incomplete = [];
        result.data.docs.forEach((doc) => {
          const video = _.get(doc, "userResponse.video", null);
          const errors = validate(video, schema);
          if (!errors || doc.status === MESSAGE_STATUS.COMPLETED) {
            complete.push(doc);
            if (firstIndex === -1 && doc.status === MESSAGE_STATUS.PENDING) {
              firstIndex = complete.length - 1;
            }
          } else {
            incomplete.push(doc);
          }
        });
        if (firstIndex > -1) {
          setRecordingIndex(firstIndex);
        }
        setRecordings(complete.concat(incomplete));
        setReady(true);
      } catch (err) {
        setReady(true);
        alert("Error loading event information:\n\n" + err.message);
      }
    };

    const fileType = () => {
      return isSafari ? "mp4" : "webm";
    };

    const getCamera = async () => {
      let constraints = {
        audio: true,
        video: {
          width: {
            min: 640,
            ideal: 1280,
            max: 1920,
          },
          height: {
            min: 480,
            ideal: 720,
            max: 1280,
          },
          frameRate: {
            min: 30,
          },
        },
      };

      try {
        let stream = await navigator.mediaDevices.getUserMedia(constraints);
        return stream;
      } catch (err) {
        alert("Could not obtain a valid camera");
        return null;
      }
    };

    const handleRecordClick = () => {
      switch (recordingStatus) {
        case RECORDING_STATUS.NONE:
          setRecordingStatus(RECORDING_STATUS.PREPARING);
          break;
        case RECORDING_STATUS.RECORDING:
          setRecordingStatus(RECORDING_STATUS.STOPPED);
          break;
        case RECORDING_STATUS.STOPPED:
          sendRecording();
          break;
        case RECORDING_STATUS.UPLOADED:
          if (recordingIndex < recordings.length - 1) {
            setRecordingIndex(recordingIndex + 1);
          }
          setRecordingStatus(RECORDING_STATUS.NONE);
          break;
        case RECORDING_STATUS.ERROR:
          setRecordingStatus(RECORDING_STATUS.NONE);
          break;
      }
    };

    const cancelCountdown = () => {
      setRecordingStatus(RECORDING_STATUS.NONE);
    };

    const getBlob = () => {
      return new Blob(chunks, { type: `video/${fileType()}` });
    };

    // In Safari, this is only called once the MediaRecorder object is stopped.
    // For all other browsers, this is called every second.
    const handleVideoChunk = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
        if (isSafari) handleVideoStop();
      }
    };

    const handleVideoStop = () => {
      try {
        const url = window.URL.createObjectURL(getBlob());
        setPlaybackUrl(url);
      } catch (err) {
        console.log("STOP ERROR", err);
      }
    };

    const handleWindowUnload = () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === "recording"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (videoRef.current) {
        try {
          videoRef.current.stop();
        } catch (err) {}
      }
      if (inputStreamRef.current) {
        try {
          inputStreamRef.current.getTracks().forEach((track) => track.stop());
        } catch (err) {}
      }
    };

    const sendRecording = async () => {
      setRecordingStatus(RECORDING_STATUS.UPLOADING);
      let storageRef = Store.storage().ref();
      let fileRef = storageRef.child(
        `video_message_upload/${recording.id}.${fileType()}`
      );
      const blob = getBlob();
      const result = await fileRef.put(blob);
      if (result.state !== "success") {
        setRecordingStatus(RECORDING_STATUS.ERROR);
        return;
      }
      let next = _.cloneDeep(recordings);
      next[recordingIndex].status = MESSAGE_STATUS.COMPLETED;
      setRecordings(next);
      setRecordingStatus(RECORDING_STATUS.UPLOADED);
    };

    const resetPlayback = () => {
      try {
        if (playbackUrl) window.URL.revokeObjectURL(playbackUrl);
      } catch (err) {}
      setPlaybackUrl(null);
    };

    const getVideoDimensions = () => {
      const iw = window.innerWidth;
      let w = iw;
      try {
        const el = document.getElementById("video-wrapper");
        const rect = el.getBoundingClientRect();
        w = rect.width;
      } catch (err) {}
      const h = Math.ceil(w / (16 / 9));
      return { width: w, height: h };
    };

    const updateCanvas = () => {
      if (!videoRef || !videoRef.current) {
        videoRef.current = document.getElementById("video-ref");
      }

      if (
        !videoRef ||
        (videoRef && videoRef.current && videoRef.current.ended)
      ) {
        return;
      }

      if (!canvasRef || !canvasRef.current) {
        canvasRef.current = document.getElementById("canvas-ref");
      }

      const settings = videoRef.current?.srcObject
        .getVideoTracks()[0]
        .getSettings();

      canvasRef.current.width = 360;
      canvasRef.current.height = 640;

      try {
        const ctx = canvasRef.current.getContext("2d");

        ctx.drawImage(
          videoRef.current,
          videoRef.current.clientWidth / 2 - 130,
          0,
          360,
          640,
          0,
          0,
          canvasRef.current.clientWidth,
          canvasRef.current.clientHeight //settings.height
        );

        const img = document.getElementById("watermark");
        ctx.globalAlpha = 0.5;
        ctx.drawImage(img, 10, 10);

        requestAnimationRef.current = requestAnimationFrame(updateCanvas);
      } catch (err) {
        console.error(err);
      }
    };

    const startRecording = () => {
      try {
        if (
          mediaRecorderRef.current &&
          mediaRecorderRef.current.status === "recording"
        ) {
          mediaRecorderRef.current.stop();
          return;
        }

        if (!canvasRef.current) {
          canvasRef.current = document.getElementById("canvas-ref");
        }
        const settings = videoRef.current?.srcObject
          .getVideoTracks()[0]
          .getSettings();
        if (settings) {
          canvasRef.current.height = settings.height;
          canvasRef.current.width = settings.width;
        } else {
          alert("Could not set recording");
          return;
        }

        const canvasStream = canvasRef.current.captureStream(30); // FPS

        const audioStream = new MediaStream();
        const audioTracks = videoRef.current?.srcObject.getAudioTracks(); //inputStreamRef.current.getAudioTracks();
        audioTracks.forEach(function (track) {
          audioStream.addTrack(track);
        });

        const outputStream = new MediaStream();
        [audioStream, canvasStream].forEach(function (s) {
          s.getTracks().forEach(function (t) {
            outputStream.addTrack(t);
          });
        });

        mediaRecorderRef.current = new MediaRecorder(outputStream, {
          mimeType: `video/${fileType()}`,
        });

        mediaRecorderRef.current.ondataavailable = handleVideoChunk;

        //mediaRecorderRef.current.onstop = handleVideoStop;

        chunks = [];

        requestAnimationRef.current = requestAnimationFrame(updateCanvas);

        // For non-Safari browsers, this will send video data to the handler every 1 second.
        // Safari will gather one huge chunk when the record is stopped.
        mediaRecorderRef.current.start(100);

        setRecordingTimer(0);
      } catch (err) {
        console.log("StartRecording", err.message);
      }
    };

    const viewRecordedMessage = async (index) => {
      const recording = recordings[index];
      let result = recording;
      // get document
      if (!result.url || result.url === "") {
        const ref = Store.firestore()
          .collection("user_reward_items")
          .doc(recording.id);
        const snap = await ref.get();
        result = snap.data();
      }
      if (result.status === MESSAGE_STATUS.COMPLETED && result.url) {
        setVideoUrl(result.url);
        toggleViewMessage(true);
      } else {
        alert("This video message has not yet been processed.");
      }
    };

    let recording = recordings[recordingIndex];
    if (!recording) recording = {};

    const video = _.get(recording, "userResponse.video", {});
    const errors = validate(video, schema);

    return (
      <>
        <PageContainer>
          <LogoWrapper>
            <SetLiveLogo name="SetLiveReversed" />
            <Logout onClick={() => handleLogout()}>Log Out</Logout>
          </LogoWrapper>
          <PageHeader>
            <HeaderContainer>
              <Header>video messages</Header>
              <SubHeader>
                {videoMessageEvent ? videoMessageEvent.artist : ""}
              </SubHeader>
            </HeaderContainer>
          </PageHeader>
          <EventTitle>
            {videoMessageEvent ? videoMessageEvent.title : ""}
          </EventTitle>
          <StatusBar>
            <EventDate>
              <Icon form name="Calendar" style={{ verticalAlign: "bottom" }} />{" "}
              {videoMessageEvent && videoMessageEvent.startDate
                ? moment
                    .unix(videoMessageEvent.startDate.seconds)
                    .format("MMMM DD, YYYY")
                : ""}
            </EventDate>
            <MessageCount>
              <Icon form name="Audience" style={{ verticalAlign: "middle" }} />{" "}
              {completedCount}/{recordings.length} Messages Sent (
              <ViewAll onClick={() => toggleModal(true)}>view all</ViewAll>)
            </MessageCount>
          </StatusBar>
          <Grid>
            <GridRow style={{ marginLeft: "144px" }}>
              <GridColumn columns={6}>
                <NavWrapper>
                  <NavLeft>
                    {recordingIndex > 0 && (
                      <a
                        style={{ marginLeft: "35px" }}
                        onClick={() => {
                          if (recordingIndex > 0) {
                            setRecordingStatus(RECORDING_STATUS.NONE);
                            setRecordingIndex(recordingIndex - 1);
                          }
                        }}
                      >
                        <Icon
                          standalone
                          name="Left"
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                    )}
                  </NavLeft>
                  <CurrentMessage>
                    <MessageIndex>
                      Message {recordingIndex + 1} of {recordings.length}
                    </MessageIndex>
                    {(recordingStatus === RECORDING_STATUS.UPLOADED ||
                      recording.status === MESSAGE_STATUS.COMPLETED) && (
                      <CheckmarkBox>{String.fromCharCode(10003)}</CheckmarkBox>
                    )}
                    <CurrentUser>{_.get(video, "name", "TBD")}</CurrentUser>
                  </CurrentMessage>
                  <NavRight>
                    {recordingIndex < recordings.length - 1 && (
                      <a
                        style={{ marginRight: "35px" }}
                        onClick={() => {
                          if (recordingIndex < recordings.length - 1) {
                            setRecordingStatus(RECORDING_STATUS.NONE);
                            setRecordingIndex(recordingIndex + 1);
                          }
                        }}
                      >
                        <Icon
                          standalone
                          name="Right"
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                    )}
                  </NavRight>
                </NavWrapper>
                {errors && (
                  <Info>
                    <StatusMessage>
                      User has not yet provided reward details.
                    </StatusMessage>
                  </Info>
                )}
                {(recordingStatus === RECORDING_STATUS.UPLOADED ||
                  recording.status === MESSAGE_STATUS.COMPLETED) && (
                  <Success>
                    <SuccessStatusMessage>completed</SuccessStatusMessage>
                  </Success>
                )}

                {recordingStatus === RECORDING_STATUS.ERROR && (
                  <Error>
                    <StatusMessage>
                      There was an error while sending your message...Please try
                      again
                    </StatusMessage>
                  </Error>
                )}
                <FormFields>
                  <FieldRow>
                    <FieldLabel>Who's This Video Message For?</FieldLabel>
                    <P>
                      {_.get(video, "messageTarget", "self") === "self"
                        ? _.get(video, "name", "TBD")
                        : _.get(video, "targetName", "TBD")}
                    </P>
                  </FieldRow>
                  <FieldRow>
                    <FieldLabel>Relationship</FieldLabel>
                    <P>{_.get(video, "relationship", "TBD")}</P>
                  </FieldRow>
                  <FieldRow>
                    <FieldLabel>The Fan's Converstation Starter</FieldLabel>
                    <P>{_.get(video, "conversation", "None provided")}</P>
                  </FieldRow>
                  <FieldRow>
                    <FieldLabel>Details from the Fan</FieldLabel>
                    <div
                      style={{
                        position: "relative",
                        maxWidth: "92%",
                        padding: "10px",
                      }}
                    >
                      {_.get(video, "userMessage", "None provided")}
                    </div>
                  </FieldRow>
                </FormFields>
                {recording.status !== MESSAGE_STATUS.COMPLETED ? (
                  !errors && (
                    <RecordButton
                      onClick={() => handleRecordClick()}
                      disabled={
                        recordingStatus === RECORDING_STATUS.PREPARING ||
                        recordingStatus === RECORDING_STATUS.RECORDING ||
                        recordingStatus === RECORDING_STATUS.UPLOADING
                      }
                    >
                      {(() => {
                        switch (recordingStatus) {
                          case RECORDING_STATUS.PREPARING:
                            return (
                              <>
                                <RecordIcon
                                  disabled={true}
                                  tertiary
                                  name="Video"
                                />
                                Recording Is About To Start...
                              </>
                            );
                          case RECORDING_STATUS.RECORDING:
                            return (
                              <>
                                <RecordIcon
                                  disabled={true}
                                  tertiary
                                  name="Video"
                                />
                                Recording In Progress...
                              </>
                            );
                          case RECORDING_STATUS.STOPPED:
                            return (
                              <>
                                <RecordIcon tertiary name="Send" />
                                Send Recording
                              </>
                            );
                          case RECORDING_STATUS.UPLOADING:
                            return (
                              <>
                                <RecordIcon
                                  disabled={true}
                                  ertiary
                                  name="Send"
                                />
                                Sending...
                              </>
                            );
                          case RECORDING_STATUS.UPLOADED:
                            return (
                              <>
                                <RecordIcon tertiary name="Video" /> Next
                                Recording
                              </>
                            );
                          default:
                            return (
                              <>
                                <RecordIcon tertiary name="Video" /> Start
                                Recording
                              </>
                            );
                        }
                      })()}
                    </RecordButton>
                  )
                ) : recordingIndex < recordings.length - 1 ? (
                  <RecordButton
                    onClick={() => {
                      setRecordingIndex(recordingIndex + 1);
                    }}
                    disabled={
                      recordingStatus === RECORDING_STATUS.PREPARING ||
                      recordingStatus === RECORDING_STATUS.RECORDING ||
                      recordingStatus === RECORDING_STATUS.UPLOADING
                    }
                  >
                    <RecordIcon tertiary name="Video" /> Next Recording
                  </RecordButton>
                ) : null}
                {recordingStatus === RECORDING_STATUS.STOPPED && (
                  <DiscardButton
                    onClick={() => setRecordingStatus(RECORDING_STATUS.NONE)}
                    Discard
                    Recording
                  >
                    <DeleteIcon tertiary name="Delete" /> Discard Recording
                  </DiscardButton>
                )}
              </GridColumn>
              <GridColumn style={{ backgroundColor: "#ffffff" }}>
                <CameraWrapper id="camera-container">
                  <HiddenVideo
                    id="video-ref"
                    ref={videoRef}
                    muted={true}
                    playsInline
                  />
                  {!!playbackUrl && playbackUrl !== "" && (
                    <PlaybackContainer>
                      <Playback url={playbackUrl} autoPlay={autoPlayback} />
                    </PlaybackContainer>
                  )}
                  <CanvasContainer>
                    <VideoCanvas id="canvas-ref" ref={canvasRef} />
                    {recordingStatus === RECORDING_STATUS.RECORDING && (
                      <Duration value={recordingTimer} />
                    )}
                    {recordingStatus === RECORDING_STATUS.RECORDING && (
                      <StopIconContainer
                        onClick={() =>
                          setRecordingStatus(RECORDING_STATUS.STOPPED)
                        }
                      >
                        <StopIconBackground />
                        <StopIcon
                          media
                          name="Stop"
                          style={{ height: 75, width: 75 }}
                        />
                      </StopIconContainer>
                    )}
                    {recordingStatus === RECORDING_STATUS.PREPARING && (
                      <>
                        <Dimmer />
                        <CountdownContainer>
                          <CountdownTimer>
                            <TimerTitle>get ready!</TimerTitle>
                            <Timer>{!isNaN(countdown) ? countdown : ""}</Timer>
                            <CancelTimer onClick={() => cancelCountdown()}>
                              Cancel
                            </CancelTimer>
                          </CountdownTimer>
                        </CountdownContainer>
                      </>
                    )}
                    {recordingStatus === RECORDING_STATUS.UPLOADING && (
                      <>
                        <Dimmer />
                        <Uploading>
                          <UploadingText>sending message...</UploadingText>
                          <Spinner />
                        </Uploading>
                      </>
                    )}
                  </CanvasContainer>
                  <div style={{ marginTop: "10px" }}>
                    <span
                      style={{ display: "inline-block", marginRight: "1em" }}
                    >
                      Auto-play recording previews
                    </span>{" "}
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "text-bottom",
                      }}
                    >
                      <Toggle
                        onChange={(checked) => setAutoPlayback(checked)}
                        checked={autoPlayback}
                      />
                    </span>
                  </div>
                </CameraWrapper>
              </GridColumn>
            </GridRow>
          </Grid>
          <Modal size="full" open={modal} setOpen={() => toggleModal(!modal)}>
            <span style={{ fontWeight: "bolder" }}>View All</span>
            <Divider />
            <H3>Video Messages ({recordings.length})</H3>
            <div style={{ marginTop: "40px" }}>
              <GridRow>
                <GridColumn columns={10}>Name</GridColumn>
                <GridColumn columns={3}>Status</GridColumn>
                <GridColumn columns={3}></GridColumn>
              </GridRow>
              <Divider />
              {recordings.map((row, index) => {
                try {
                  const userResponse = _.get(row, "userResponse", {
                    video: {},
                  });
                  const status = _.get(row, "status", MESSAGE_STATUS.PENDING);
                  const name = _.get(userResponse, "video.name", null);
                  return (
                    <>
                      <GridRow yCenter key={`__row_${index}`}>
                        <GridColumn columns={10}>
                          {!!name ? name : "TBD"}
                        </GridColumn>
                        <GridColumn columns={3}>
                          {!!name
                            ? status === MESSAGE_STATUS.COMPLETED
                              ? "Completed"
                              : "Pending"
                            : "Waiting on fan..."}
                        </GridColumn>
                        <GridColumn columns={3}>
                          {(() => {
                            switch (status) {
                              case MESSAGE_STATUS.COMPLETED:
                                return (
                                  <Link
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      viewRecordedMessage(index);
                                    }}
                                  >
                                    View Message{" "}
                                    <Icon
                                      tertiary
                                      name="Next"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Link>
                                );
                              case MESSAGE_STATUS.PENDING:
                                return !!name ? (
                                  <ButtonPrimaryReverse
                                    style={{
                                      color: "#222222",
                                      border: "1px solid #222222",
                                    }}
                                    onClick={() => {
                                      setRecordingIndex(index);
                                      setRecordingStatus(RECORDING_STATUS.NONE);
                                      toggleModal(!modal);
                                    }}
                                  >
                                    Record Message
                                  </ButtonPrimaryReverse>
                                ) : null;
                              default:
                                return null;
                            }
                          })()}
                        </GridColumn>
                      </GridRow>
                      <Divider />
                    </>
                  );
                } catch (err) {
                  console.log(err.message);
                }
              })}
            </div>
          </Modal>
        </PageContainer>
        {viewMessage && videoUrl && (
          <FullScreenDarkModal id="preview">
            <CloseIcon
              platform
              name="Close"
              onClick={() => toggleViewMessage(false)}
            />
            <Playback url={videoUrl} />
          </FullScreenDarkModal>
        )}
        <img
          src="/favicon-32x32.png"
          id="watermark"
          style={{ visibility: "hidden" }}
        />
        {!ready && (
          <PageCover>
            <LoaderInline />
          </PageCover>
        )}
      </>
    );
  }
);
