import React from "react";
import styled from "styled-components";
import { Modal } from "notes";

export const RequirementModal = ({ children, open, setOpen }) => {
  return (
    <ModalStyle size="medium" open={open} setOpen={setOpen}>
      {children}
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  display: block;
  margin: 8.589vw auto;
  min-height: inherit;

  & > svg {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
    max-width: 100%;
    position: fixed;
    bottom: 0;
    min-height: 100%;
    top: 0;
    padding: 80px 24px 56px 24px;
    overflow-y: auto;
    & > svg {
      display: flex;
    }
  }
`;
