import React, { useState } from "react";
import { Icon, GridColumn } from "notes";
import Lightbox2 from "react-image-lightbox";
import "./style.css";
import styled from "styled-components";

export const Lightbox = ({ images, open, setOpen }) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    open && (
      <Lightbox2
        mainSrc={images[photoIndex]}
        onCloseRequest={() => setOpen({ open: false })}
        onMovePrevRequest={
          images[(photoIndex + images.length - 1) % images.length]
        }
        onMoveNextRequest={images[(photoIndex + 1) % images.length]}
        imagePadding={100}
        toolbarButtons={[
          photoIndex !== 0 && (
            <Previous
              onClick={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              xCenter
              yCenter
            >
              <Arrow name="Left" />
            </Previous>
          ),
          photoIndex !== images.length - 1 && (
            <Next
              onClick={() => setPhotoIndex((photoIndex + 1) % images.length)}
              xCenter
              yCenter
            >
              <Arrow name="Right" />
            </Next>
          ),
          <CloseIcon
            platform
            name="Close"
            onClick={() => {
              setOpen({ open: false });
              setPhotoIndex(0);
            }}
          />,
        ]}
        enableZoom={false}
      />
    )
  );
};

const Arrow = styled(Icon)`
  path {
    fill: #fff;
  }
`;

const CloseIcon = styled(Arrow)`
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 70px;
  @media only screen and ${(props) => props.theme.media.small} {
    right: 24px;
  }
`;

const Previous = styled(GridColumn)`
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 50vh;
  left: 40px;
  width: 40px;
  height: 40px;
  &:hover {
    background-color: #fff;
    ${Arrow} {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    left: 24px;
  }
`;

const Next = styled(Previous)`
  position: absolute;
  top: 50vh;
  left: inherit;
  right: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    right: 24px;
    left: inherit;
  }
`;
