import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, H3, GridRow, withDefaultMedia } from "notes";
import { useWindowSize } from "Hooks";
import { CardTicket } from "Components";

export const MyTicketsInternal = ({ matches, tickets, onClick }) => {
  const [rightExtend, setRightExtend] = useState(false);
  const [leftExtend, setLeftExtend] = useState(false);
  const [cardsRef, setCardsRef] = useState(false);
  const cardQty = tickets?.length ?? 0;
  const cardContainerRef = useRef();
  const windowSize = useWindowSize();

  const pastTickets = tickets.filter(({ past }) => past);
  const upcomingTickets = tickets.filter(({ past }) => !past);

  const isMobile = matches.small;

  useEffect(() => {
    if (cardContainerRef?.current) {
      setCardsRef(cardContainerRef);
    }
  }, [cardContainerRef]);

  const handleScroll = (evt) => {
    const target = evt.target;
    const maxScrollSpace = target.scrollWidth - target.clientWidth;
    const remainingSpace = maxScrollSpace - target.scrollLeft;
    const scrollArea = target?.clientWidth / cardQty;

    if (cardQty > 2) {
      if (remainingSpace < 164) {
        setRightExtend(false);
      } else {
        setRightExtend(true);
      }
    }

    if (
      maxScrollSpace > 250 &&
      target.scrollLeft - 70 > scrollArea &&
      cardQty > 3
    ) {
      setLeftExtend(true);
    } else {
      setLeftExtend(false);
    }
  };

  useEffect(() => {
    const element = cardsRef?.current;
    const scrollArea = element?.scrollWidth - element?.clientWidth;
    if (!!element && scrollArea > 0 && !isMobile) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  });

  useEffect(() => {
    const element = cardsRef?.current;
    const scrollArea = element?.scrollWidth - element?.clientWidth;
    if (cardQty > 2 && !isMobile) {
      if (!!element && scrollArea === 0) {
        setRightExtend(false);
      } else {
        setRightExtend(true);
      }
    } else {
      setRightExtend(false);
    }
  }, [cardsRef, windowSize, cardQty, isMobile]);

  const scrollIncrement = (next) => {
    const element = cardsRef?.current;
    const scrollArea = element?.clientWidth / cardQty;
    if (next) {
      element.scrollBy({ left: scrollArea, top: 0, behavior: "smooth" });
    } else if (element.scrollLeft < scrollArea * 3) {
      element.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    } else {
      element.scrollBy({ left: -scrollArea, top: 0, behavior: "smooth" });
    }
  };

  return (
    <Container extendLeft={leftExtend} extendRight={rightExtend}>
      <GradientCard extendLeft={leftExtend} extendRight={rightExtend}>
        {leftExtend && !isMobile && (
          <MoreArrow left onClick={() => scrollIncrement(false)}>
            <Icon tertiary name="Left" />
          </MoreArrow>
        )}
        <LeftContainer>
          <TicketIcon fade={leftExtend} form name="Ticket2" />
          {(!leftExtend || isMobile) && <SectionTitle>My Tickets</SectionTitle>}
        </LeftContainer>
        {rightExtend && !isMobile && (
          <MoreArrow right onClick={() => scrollIncrement(true)}>
            <Icon tertiary name="Right" />
          </MoreArrow>
        )}
        <Cards cardQty={cardQty} ref={cardContainerRef}>
          <CardsInner>
            {!!upcomingTickets.length &&
              upcomingTickets.map((event, i) => (
                <CardTicket
                  key={event.id}
                  onClick={() => onClick(event)}
                  ticket
                  event={event}
                />
              ))}
            {!!pastTickets.length && !!upcomingTickets.length && (
              <TicketDivider />
            )}
            {!!pastTickets.length &&
              pastTickets.map((event, i) => (
                <CardTicket
                  key={event.id}
                  onClick={() => onClick(event)}
                  ticket
                  past
                  event={event}
                />
              ))}
          </CardsInner>
        </Cards>
      </GradientCard>
    </Container>
  );
};

export const MyTickets = withDefaultMedia(MyTicketsInternal);

const TicketDivider = styled.div`
  display: block;
  background-color: #ffffff;
  border-radius: 4px;
  opacity: 0.24;
  width: 8px;
  height: 284px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 136px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 4px;
    height: 258px;
    margin-bottom: 72px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    height: 21.78vw;
    margin-left: 1.84vw;
    margin-right: 1.84vw;
    margin-bottom: 10.43vw;
  }
`;

const MoreArrow = styled(GridRow)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.72);
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  ${(props) => props.left && "left: 24px;"};
  ${(props) => props.right && "right: 24px;"};
  z-index: 4;
  width: 80px;
  height: 80px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: #ffffff;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    right: 1.84vw;
    width: 6.13vw;
    height: 6.13vw;
    svg {
      width: 2.45vw;
      height: 2.45vw;
    }
  }
`;

const Container = styled(GridRow)`
  margin: 168px auto 56px auto;
  width: 100%;
  height: 396px;
  padding-left: ${(props) => (props.extendLeft ? 0 : "40px")};
  padding-right: ${(props) => (props.extendRight ? 0 : "40px")};
  position: relative;
  z-index: 2;
  &:hover {
    ${MoreArrow} {
      opacity: 1;
      visibility: visible;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    margin: 12.88vw auto 4.29vw auto;
    height: 30.37vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    height: auto;
    margin: 56px auto 0;
    padding: 0;
  }
`;

const GradientCard = styled(GridRow)`
  align-items: center;
  border-radius: 12px;
  ${(props) =>
    props.extendRight &&
    `
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `};
  ${(props) =>
    props.extendLeft &&
    `
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `};
  background: linear-gradient(45deg, #7c60d9 0%, #39a4a1 100%);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 0;
    flex-direction: column;
    padding: 24px 0 20px;
  }
`;

const Cards = styled(GridRow).attrs({ as: "div" })`
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 0;
  top: -104px;
  overflow-x: ${(props) => (props.cardQty > 2 ? "auto" : "hidden")};
  padding-left: 336px;
  scroll-snap-type: x proximity;
  scroll-behavior: smooth;
  scroll-padding-inline-end: 144px;
  scroll-padding-inline-start: 366px;
  width: 100%;
  z-index: 3;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    top: -7.98vw;
    padding-right: 8.44vw;
    padding-left: 25.77vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    overflow-x: ${(props) => (props.cardQty > 1 ? "auto" : "hidden")};
    padding-left: 11.7%;
    padding-top: 8px;
    padding-bottom: 24px;
    position: relative;
    top: initial;
    scroll-snap-type: x mandatory;
    scroll-padding-inline-end: 11.73%;
    scroll-padding-inline-start: 11.73%;
  }
`;

const CardsInner = styled(GridRow)`
  align-items: flex-end;
  padding-right: 128px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-right: 44px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-right: 9.82vw;
  }
`;

const LeftContainer = styled(GridRow)`
  flex-shrink: 0;
  border-radius: 12px 0 0 12px;
  overflow: hidden;
  position: relative;
  width: 366px;
  height: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 28.07vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    border-radius: 0;
    margin-bottom: 16px;
    padding: 0 24px;
    width: 100%;
  }
`;

const TicketIcon = styled(Icon)`
  color: #ffffff;
  position: absolute;
  top: 22%;
  left: -34px;
  width: 392px;
  height: 392px;
  opacity: ${(props) => (props.fade ? 0.05 : 0.24)};
  transform: rotate(-40deg);
  z-index: 0;
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 30.06vw;
    height: 30.06vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-shrink: 0;
    position: relative;
    top: initial;
    left: initial;
    width: 24px;
    height: 24px;
    opacity: 1;
    transform: none;
    margin-right: 12px;
  }
`;

const SectionTitle = styled(H3)`
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  position: absolute;
  top: 56px;
  left: 56px;
  width: 148px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 3.07vw;
    line-height: 3.37vw;
    top: 4.29vw;
    left: 4.29vw;
    width: 11.35vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    position: relative;
    top: initial;
    left: initial;
    font-size: 25px;
    line-height: 32px;
    width: 100%;
  }
`;
