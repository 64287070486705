import React from "react";
import styled from "styled-components";
import { GridColumn, GridRow, H3, H4, Small, P } from "notes";

export const Details = ({ date, artist, title, ...props }) => {
  const { month, day, year } = date ?? {};
  return (
    <Container {...props}>
      <DateContainer>
        <H3>{day}</H3>
        <H4>{month}</H4>
        <Small>{year}</Small>
      </DateContainer>

      <TextContainer>
        <H3>{artist}</H3>
        <P>{title}</P>
      </TextContainer>
    </Container>
  );
};

const Container = styled(GridRow)`
  align-items: center;
  border-radius: ${(props) =>
    props.theme.cardTicket.past && !props.theme.cardTicket.ticket
      ? "4px"
      : "8px"};
  background-color: rgba(0, 0, 0, 0.72);
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.24),
    0 8px 24px 0 rgba(0, 0, 0, 0.5);
  height: ${(props) =>
    props.theme.cardTicket.past && !props.theme.cardTicket.ticket
      ? "80px"
      : "110px"};
  padding: ${(props) =>
    props.theme.cardTicket.past && !props.theme.cardTicket.ticket
      ? "0 16px"
      : "12px"};
  @media only screen and ${(props) => props.theme.media.small} {
    ${(props) => !props.theme.cardTicket.ticket && `display: none;`};
    height: 96px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    height: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "6.13vw"
        : "8.44vw"};
    padding: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? `0 1.23vw`
        : "0.92vw"};
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) =>
      !props.theme.cardTicket.ticket &&
      `
    height: ${props.theme.cardTicket.past ? "5.6vw" : "6.4vw"};
    padding: ${props.theme.cardTicket.past ? "1vw" : "0.7vw"};
    `};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    height: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "80px"
        : "110px"};
    padding: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "0 16px"
        : "12px"};
  }
`;

const DateContainer = styled(GridColumn)`
  ${(props) =>
    props.theme.cardTicket.past &&
    !props.theme.cardTicket.ticket &&
    "display: none;"};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: inset 0 -4px 0 0 #a6aeb2, 0 8px 24px 0 rgba(0, 0, 0, 0.72),
    0 0 24px 0 rgba(0, 0, 0, 0.5);
  margin-right: 24px;
  width: 68px;
  height: 86px;
  ${H3} {
    font-weight: 400;
    line-height: 29px;
  }
  ${H4} {
    font-family: ${(props) => props.theme.fonts.workSans};
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }
  ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-weight: 600;
    line-height: 15px;
    margin-bottom: 4px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 12px;
    width: 56px;
    height: 72px;
    ${H3} {
      font-size: 17px;
      line-height: 20px;
    }
    ${H4} {
      font-size: 15px;
      line-height: 18px;
    }
    ${Small} {
      font-size: 10px;
      line-height: 13px;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 5.21vw;
    height: 6.6vw;
    margin-right: 1.84vw;
    ${H3} {
      font-size: 1.92vw;
      line-height: 2.22vw;
    }
    ${H4} {
      font-size: 1.3vw;
      line-height: 1.53vw;
    }
    ${Small} {
      font-size: 0.92vw;
      line-height: 1.15vw;
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) =>
      !props.theme.cardTicket.ticket &&
      !props.theme.cardTicket.past &&
      `
    width: 3.95vw;
    height: 5vw;
    margin-right: 1.4vw;
    ${H3} {
      font-size: 1.45vw;
      line-height: 1.69vw;
    }
    ${H4} {
      font-size: 0.99vw;
      line-height: 1.16vw;
    }
    ${Small} {
      font-size: 0.7vw;
      line-height: 1.05vw;
    }
    `};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    width: 68px;
    height: 86px;
    margin-right: 24px;
    ${H3} {
      font-size: 25px;
      line-height: 29px;
    }
    ${H4} {
      font-size: 17px;
      line-height: 20px;
    }
    ${Small} {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

const TextContainer = styled(GridColumn)`
  overflow: hidden;
  ${H3} {
    color: #ffffff;
    font-size: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "17px"
        : "25px"};
    line-height: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "22px"
        : "32px"};
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => props.theme.palette.gray.lighter};
    font-size: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "12px"
        : "17px"};
    line-height: ${(props) =>
      props.theme.cardTicket.past && !props.theme.cardTicket.ticket
        ? "15px"
        : "22px"};
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${H3} {
      font-size: 17px;
      line-height: 20px;
    }
    ${P} {
      font-size: 15px;
      line-height: 19px;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${H3} {
      font-size: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "1.3vw"
          : "1.92vw"};
      line-height: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "1.69vw"
          : "2.45vw"};
      margin-bottom: 0.307vw;
    }
    ${P} {
      font-size: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "0.92vw"
          : "1.3vw"};
      line-height: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "1.15vw"
          : "1.69vw"};
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) =>
      !props.theme.cardTicket.ticket &&
      `
    ${H3} {
      font-size: ${props.theme.cardTicket.past ? "1.1vw" : "1.45vw"};
      line-height: ${props.theme.cardTicket.past ? "1.45vw" : "1.86vw"};
      margin-bottom: 0.23vw;
    }
    ${P} {
      font-size: ${props.theme.cardTicket.past ? "1vw" : ".99vw"};
      line-height: ${props.theme.cardTicket.past ? "1.34vw" : "1.28vw"};
    }
    `};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    ${H3} {
      font-size: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "17px"
          : "25px"};
      line-height: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "22px"
          : "32px"};
    }
    ${P} {
      font-size: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "12px"
          : "17px"};
      line-height: ${(props) =>
        props.theme.cardTicket.past && !props.theme.cardTicket.ticket
          ? "15px"
          : "22px"};
    }
  }
`;
