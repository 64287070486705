import React from "react";
import styled from "styled-components";
import { Footnote, P, Link } from "notes";
import { FieldMessage } from "Components";

export const Status = ({
  rewardItem,
  userResponse,
  processingStatus,
  videoUrl,
}) => {
  const requirementsLength =
    !!rewardItem?.requires && Object.keys(rewardItem.requires).length;
  const userResponseLength = !!userResponse && Object.keys(userResponse).length;

  let status = "missingItems";
  if (requirementsLength === userResponseLength) {
    status = "pending";
  }

  if (rewardItem.type === "meet_greet") {
    return rewardItem.data?.link ? (
      <>
        <LinkRight target="_blank" href={rewardItem.data.link}>
          {rewardItem.data.link}
        </LinkRight>
        <Pass>
          Password: <span>{rewardItem.data?.password}</span>
        </Pass>
      </>
    ) : (
      <Footnote>Link unavailable</Footnote>
    );
  }

  if (rewardItem.type === "video_message" && videoUrl) {
    return (
      <LinkRight target="_blank" href={videoUrl}>
        {videoUrl}
      </LinkRight>
    );
  }

  if (!!processingStatus) {
    return (
      <>
        {processingStatus === "processing" && <Bold>Processing</Bold>}
        {processingStatus === "shipped" && (
          <FieldMessage
            style={{ margin: 0 }}
            active={true}
            type="success"
            content={
              <P style={{ fontSize: "17px" }}>
                {rewardItem.type === "video_message" ? "Sent!" : "Shipped!"}
              </P>
            }
          />
        )}
      </>
    );
  }

  return (
    <>
      {status === "pending" && <Bold>Pending</Bold>}

      {status === "missingItems" && <Footnote>Missing information</Footnote>}
    </>
  );
};

const Bold = styled(P)`
  font-weight: 600;
`;

const Pass = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  span {
    color: ${(props) => props.theme.palette.black};
    font-weight: 400;
    word-break: break-word;
  }
`;

const LinkRight = styled(Link)`
  display: inline-block;
  margin-bottom: 8px;
  word-break: break-word;
`;
