import React, { useContext } from "react";
import styled from "styled-components";
import {
  useDocumentDataOnce,
  useCollection,
} from "react-firebase-hooks/firestore";
import { CreditCardBadge, StoreContext, formatCurrency } from "Components";
import {
  GridColumn,
  GridRow,
  GridCell,
  Subheader,
  H4,
  P,
  Footnote,
  Icon,
} from "notes";

export const Receipt = ({ data }) => {
  const { user } = useContext(StoreContext);

  const transaction = data;

  if (!transaction) {
    return null;
  }

  const { label, amount } = transaction.paymentItem;
  const { brand, last4, exp_month, exp_year } = transaction.source.card || {};

  return (
    <Wrapper xCenter yStart>
      <MoreTab xCenter yCenter>
        <DownIcon tertiary name="Next" />
      </MoreTab>
      <Container>
        <Subheader>{last4 ? "Payment " : ""}Confirmation</Subheader>
        {last4 && (
          <CardInfo xCenter>
            <CardLogo type={brand} />
            <GridColumn>
              <H4>
                {brand} Ending In {last4}
              </H4>
              <Footnote>
                {exp_month}/{exp_year}
              </Footnote>
            </GridColumn>
          </CardInfo>
        )}
        <Card>
          <Row>
            <GridCell stretch header columns={7}>
              {transaction.quantity}x {label}
            </GridCell>
            <GridCell xEnd columns={1}>
              {formatCurrency(amount)}
            </GridCell>
          </Row>

          {/* <Row>
            <GridCell stretch header columns={7}>
              Taxes
            </GridCell>
            <GridCell xEnd columns={1}>
              $0.00
            </GridCell>
          </Row> */}
          <Divider />
          <GridRow>
            <TotalLabel stretch columns={7}>
              Total Charged
            </TotalLabel>
            <GridCell columns={1} xEnd>
              <Total>{formatCurrency(amount)}</Total>
            </GridCell>
          </GridRow>
        </Card>
        <Notice>
          A {last4 ? "receipt and " : ""}confirmation{" "}
          {last4 ? "of your purchase " : ""}has also been sent to
          <Email>{user?.email}</Email>
        </Notice>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  background-color: #f6f9fc;
  border-top: 2px solid ${(props) => props.theme.palette.gray.lightest};
  margin-top: 56px;
  padding: 40px 24px 56px 24px;
  position: relative;
  width: 100%;

  ${GridCell} {
    font-size: 14px;
    padding: 0;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-shrink: 0;
    ${GridCell} {
      min-width: 0;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    ${GridCell} {
      min-width: 0;
    }
  }
`;

const MoreTab = styled(GridColumn)`
  background-color: #fff;
  border: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-radius: 20px;
  display: none;
  position: absolute;
  top: -22px;
  width: 40px;
  height: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: flex;
  }
`;

const DownIcon = styled(Icon)`
  transform: rotate(90deg);
  path {
    fill: ${(props) => props.theme.palette.gray.lightest};
  }
`;

const Container = styled(GridColumn)`
  width: 100%;
  max-width: 860px;

  ${Subheader} {
    margin-bottom: 16px;
  }
`;

const Row = styled(GridRow)`
  margin-bottom: 16px;
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lightest};
  margin-bottom: 16px;
  width: 100%;
  height: 2px;
`;

const Notice = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 15px;
  margin-top: 12px;
`;

const Email = styled.span`
  font-weight: 600;
  margin-left: 4px;
`;

const Card = styled(GridColumn)`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 4px;
  margin: 0 auto;
  padding: 16px 24px;
  width: 100%;
  ${H4} {
    font-weight: 600;
    text-transform: capitalize;
  }
  ${Footnote} {
    font-style: normal;
  }
`;

const CardInfo = styled(Card)`
  flex-direction: row;
  margin-bottom: 16px;
`;

const CardLogo = styled(CreditCardBadge)`
  margin-right: 24px;
`;

const Total = styled(P)`
  font-weight: 700;
`;

const TotalLabel = styled(GridCell)`
  font-weight: 700;
`;
