import React from "react";
import styled from "styled-components";

const formatDuration = (value) => {
  if (!value) return "00:00";
  let mins = 0;
  let secs = value;
  if (value > 59) {
    mins = Math.floor(value / 60);
    secs = value - mins * 60;
  }
  return (
    (mins < 1 ? "00" : mins < 10 ? "0" + mins : "" + mins) +
    ":" +
    (secs < 1 ? "00" : secs < 10 ? "0" + secs : "" + secs)
  );
};

export default ({ value = 0 }) => {
  return <DurationReadout>{formatDuration(value)}</DurationReadout>;
};

const DurationReadout = styled.div`
  width: 66px;
  top: 20px;
  height: 30px;
  z-index: 12;
  position: absolute;
  left: 50%;
  margin-left: -33px;
  text-align: center;
  background-color: #000000;
  opacity: 0.6;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  line-height: 30px;
  ${(props) => props.theme.fonts.default};
`;
