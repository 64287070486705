import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  GridRow,
  GridColumn,
  P,
  H1,
  Label,
  ButtonSecondary,
  Icon,
} from "notes";
import {
  HeaderSecondary,
  StoreContext,
  FooterBlock,
  withProtectedRoute,
  ModalContext,
} from "Components";
import { logout } from "services";
import {
  EditName,
  EditShirtSize,
  EditShipping,
  EditUsername,
  EditAction,
  PurpleText,
} from "./Components";
import { PageHeader } from "../Privacy";
export * from "./Components";
export * from "./UpdateEmail";
export * from "./ConfirmEmail";

export const Account = withProtectedRoute(() => {
  const { username, user, Store } = useContext(StoreContext);
  const { toggleUpdateEmail } = useContext(ModalContext);

  const history = useHistory();
  const [profile, loading, error] = useDocumentData(
    Store.firestore().doc(`profiles/${user?.uid}`)
  );

  if (loading || !user) {
    return null;
  }

  const handleSubmit = (value, name) => {
    Store.firestore()
      .collection("profiles")
      .doc(user.uid)
      .update({ [name]: value });
  };

  const handleLogout = async () => {
    try {
      history.push("/");
      await logout();
    } catch (err) {
      console.log(err);
    }
  };

  const { email, phoneNumber } = user;

  return (
    <Wrapper>
      <HeaderSecondary banner />
      <PageHeader>
        <H1>My Account</H1>
      </PageHeader>
      <Container stretch>
        <Fields>
          <EditUsername defaultValue={username} />
          <EditName
            defaultValue={profile?.name}
            onSubmit={(value) => handleSubmit(value, "name")}
          />
          <FieldContainer yCenter>
            <FieldLabel>Email address</FieldLabel>
            <GridRow>
              <Ellipsis>{email}</Ellipsis>
              {profile.verifiedEmail && (
                <VerifiedBadge yCenter>Verified</VerifiedBadge>
              )}
            </GridRow>
            {!profile.verifiedEmail && (
              <EditAction
                name="Edit"
                onClick={() => toggleUpdateEmail({ open: true, email: email })}
              />
            )}
          </FieldContainer>
          {phoneNumber && (
            <FieldContainer>
              <FieldLabel>Phone number</FieldLabel>
              <P>{phoneNumber}</P>
            </FieldContainer>
          )}
        </Fields>
        <Divider />

        <Fields>
          <EditShipping
            defaultValue={profile?.shippingAddress}
            onSubmit={(value) => handleSubmit(value, "shippingAddress")}
          />
          <EditShirtSize
            defaultValue={profile?.shirtSize}
            onSubmit={(value) => handleSubmit(value, "shirtSize")}
          />
        </Fields>
        <Divider />
        <LinkContainer xStart>
          <LinkStyle to="/billing">
            Billing and Transactions <NextIcon name="Next" />
          </LinkStyle>
        </LinkContainer>
        <ButtonContainer columns={2}>
          <ButtonSecondary onClick={() => handleLogout()}>
            Sign Out
          </ButtonSecondary>
        </ButtonContainer>
      </Container>
      <FooterBlock />
    </Wrapper>
  );
});

const Ellipsis = styled(P)`
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-right: 56px;
  }
`;

const VerifiedBadge = styled(GridRow)`
  border: 1px solid ${(props) => props.theme.palette.green.primary};
  border-radius: 2px;
  color: ${(props) => props.theme.palette.green.primary};
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 16px;
  height: 22px;
  padding: 0 8px;
`;

const NextIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-left: 8px;
  path {
    fill: ${(props) => props.theme.palette.purple.primary};
  }
`;

const LinkStyle = styled(PurpleText).attrs({ as: Link })`
  align-items: center;
  display: flex;
  margin-top: 33px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.purple.darker};
    ${NextIcon} {
      path {
        fill: ${(props) => props.theme.palette.purple.darker};
      }
    }
  }
`;

const Divider = styled(GridRow)`
  background-color: #fafafa;
  border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  width: 100%;
  max-width: 600px;
  height: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    max-width: 100%;
    border-width: 2px;
  }
`;

const Wrapper = styled(GridColumn)`
  width: 100%;
  min-height: 100vh;
`;

const Container = styled(GridColumn)`
  width: 100%;
  padding-bottom: 96px;
  margin: 0 auto;
  max-width: 1016px;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 40px;
  }
`;

const Fields = styled(GridColumn)`
  max-width: 600px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-left: 24px;
    max-width: 100%;
  }
  & > div:last-child {
    border-bottom: none;
  }
`;

const FieldLabel = styled(Label)``;

const FieldContainer = styled(GridColumn)`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.lightest};
  padding: 16px 0;
  position: relative;
  min-height: 56px;

  ${FieldLabel} {
    margin-bottom: 4px;
    color: ${(props) =>
      props.editMode
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin-top: 24px;
  max-width: 184px;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
    max-width: 100%;
  }
`;

const LinkContainer = styled(GridColumn)`
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: 0 24px;
  }
`;
