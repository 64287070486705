import React from "react";
import styled from "styled-components";
import { Icon, H4, GridRow } from "notes";

export const Label = ({ type, ...props }) => {
  if (type === "meet_greet") {
    return (
      <Badge {...props}>
        <Icon form name="VideoChat" />
        <H4>Meet &amp; Greet</H4>
      </Badge>
    );
  }

  if (type === "replay") {
    return (
      <Badge {...props}>
        <Icon tertiary name="Refresh" />
        <H4>Replay</H4>
      </Badge>
    );
  }

  return (
    <Badge {...props}>
      <Icon tertiary name="Music" />
      <H4>Concert</H4>
    </Badge>
  );
};

export const Badge = styled(GridRow)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  padding: 0 12px;
  svg {
    width: 14px;
    height: 14px;
    margin-right: 9px;
    path {
      fill: #ffffff;
    }
  }
  ${H4} {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
    text-transform: uppercase;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    svg {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      path {
        fill: #ffffff;
      }
    }
    ${H4} {
      color: #ffffff;
      font-size: ${(props) =>
        props.theme.cardTicket.ticket ? "12px" : "10px"};
      line-height: ${(props) =>
        props.theme.cardTicket.ticket ? "15px" : "13px"};
    }
    height: ${(props) => (props.theme.cardTicket.ticket ? "32px" : "24px")};
    padding: 0 8px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    height: 3.07vw;
    padding: 0 0.92vw;
    svg {
      width: 1.07vw;
      height: 1.07vw;
      margin-right: 0.69vw;
    }
    ${H4} {
      font-size: 1.15vw;
      line-height: 1.46vw;
    }
  }
  @media only screen and ${(props) => props.theme.media.large} {
    ${(props) =>
      !props.theme.cardTicket.ticket &&
      `
    height: 2.33vw;
    padding: 0 0.7vw;
    svg {
      width: 0.81vw;
      height: 0.81vw;
      margin-right: 0.52vw;
    }
    ${H4} {
      font-size: 0.87vw;
      line-height: 1.1vw;
    }
    `};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    height: 40px;
    padding: 0 12px;
    svg {
      width: 14px;
      height: 14px;
      margin-right: 9px;
    }
    ${H4} {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;
