import React, { useState } from "react";
import { Link, H4 } from "notes";
import { EmailForm } from "./EmailForm";
import { EditLink, RequirementModal } from "../../Components";

export const Email = ({ rewardId, processingStatus, userResponse }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RequirementModal open={open} setOpen={setOpen}>
        <EmailForm
          rewardId={rewardId}
          userResponse={userResponse}
          onComplete={() => setOpen(false)}
        />
      </RequirementModal>

      {userResponse ? (
        <>
          <H4>{userResponse}</H4>
          {!processingStatus && <EditLink onClick={() => setOpen(true)} />}
        </>
      ) : (
        <Link onClick={() => setOpen(true)}>Add Email Address</Link>
      )}
    </>
  );
};
