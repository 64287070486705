import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useStripe } from "@stripe/react-stripe-js";
import { Payments, ErrorIcon } from "Components";
import {
  StoreContext,
  MessageContext,
  PaymentContext,
  LoaderInline,
} from "Components";
import {
  GridColumn,
  GridRow,
  H3,
  P,
  Footnote,
  ButtonPrimary,
  ButtonSecondary,
} from "notes";

export const RemoveCard = ({ onComplete, card, ...props }) => {
  const { Store } = useContext(StoreContext);
  const { updatePaymentMethods } = useContext(PaymentContext);
  const { displayMessage } = useContext(MessageContext);

  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const [api] = useState(new Payments(stripe, Store));

  const handleSubmit = () => {
    setProcessing(true);
    api
      .removeSavedCards()
      .then((result) => {
        if (result.success) {
          updatePaymentMethods();
          onComplete();
          displayMessage({
            color: "green",
            children: <P>Card successfully removed from your account!</P>,
            timeout: 5000,
          });
        } else {
          setProcessing(false);
          displayMessage({
            color: "red",
            children: (
              <GridRow yCenter>
                <ErrorIcon form name="Error" />
                <P style={{ fontWeight: "400" }}>{result.context.message}</P>
              </GridRow>
            ),
            timeout: 5000,
          });
        }
      })
      .catch((err) => {
        onComplete();
        displayMessage({
          color: "red",
          children: (
            <GridRow yCenter>
              <ErrorIcon form name="Error" />
              <P style={{ fontWeight: "400" }}>
                There was an error when removing your card... Please try again.
              </P>
            </GridRow>
          ),
          timeout: 5000,
        });
      });
  };

  return (
    <Container xCenter {...props}>
      <H3>Remove Saved Card</H3>
      <Footnote>
        {card.brand?.charAt(0).toUpperCase()}
        {card.brand?.slice(1)} ending in {card.last4} (expires {card.exp_month}/
        {card.exp_year})
      </Footnote>
      <P>Are you sure you want to remove this card from your account?</P>
      <ButtonContainer yEnd>
        <CancelButton onClick={onComplete}>Cancel</CancelButton>
        <RemoveButton disabled={processing} onClick={handleSubmit}>
          {processing ? <LoaderInline /> : "Remove Card"}
        </RemoveButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled(GridColumn)`
  height: 100%;
  ${H3} {
    margin-bottom: 8px;
  }
  ${Footnote} {
    margin-bottom: 24px;
  }
  ${P} {
    text-align: center;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    ${H3} {
      font-size: 17px;
      line-height: 22px;
    }
    ${Footnote} {
      margin-bottom: 8px;
    }
  }
`;

const ButtonContainer = styled(GridRow)`
  margin-top: 24px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const CancelButton = styled(ButtonSecondary)`
  @media only screen and ${(props) => props.theme.media.small} {
    order: 2;
  }
`;

const RemoveButton = styled(ButtonPrimary)`
  @media only screen and ${(props) => props.theme.media.small} {
    order: 1;
    margin-bottom: 8px;
  }
`;
