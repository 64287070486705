import React, { useContext } from "react";
import { Duration } from "luxon";
import styled from "styled-components";
import { GridColumn, GridRow, H3, P, withDefaultMedia } from "notes";
import { TimeContext } from "Components";

export const Countdown = withDefaultMedia(({ matches, start, ...props }) => {
  const { time } = useContext(TimeContext);
  const timeToStart = Duration.fromMillis(start - time).shiftTo(
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );
  const { hours, minutes, seconds } = timeToStart;
  return (
    <GridRow {...props}>
      <IncrementContainer>
        <Increment>
          {hours < 10 && `0`}
          {hours}
        </Increment>
        <Label>{matches.small ? "hrs" : "hours"}</Label>
      </IncrementContainer>

      <IncrementContainer>
        <Increment>
          {minutes < 10 && `0`}
          {minutes}
        </Increment>
        <Label>{matches.small ? "min" : "minutes"}</Label>
      </IncrementContainer>

      <IncrementContainer>
        <Increment>
          {seconds < 10 && `0`}
          {seconds}
        </Increment>
        <Label>{matches.small ? "sec" : "seconds"}</Label>
      </IncrementContainer>
    </GridRow>
  );
});

const IncrementContainer = styled(GridColumn)`
  align-items: center;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 38.89%,
      rgba(0, 0, 0, 0.08) 50%,
      #ffffff 50%
    ),
    linear-gradient(
      180deg,
      rgba(230, 233, 235, 0) 16.67%,
      #e6e9eb 50%,
      #ffffff 50%
    ),
    #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  position: relative;
  padding-top: 14px;
  width: 80px;
  height: 72px;
  & + & {
    margin-left: 12px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 4px;
    padding-top: 11px;
    width: 40px;
    height: 40px;
  }
`;

const Increment = styled(H3)`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 29.85%,
      rgba(0, 0, 0, 1) 61%,
      #222222 62%
    ),
    #222222;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  line-height: 36px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  @media only screen and ${(props) => props.theme.media.small} {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 29.85%,
        rgba(0, 0, 0, 1) 56%,
        #222222 57%
      ),
      #222222;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 17px;
    line-height: 16px;
  }
`;

const Label = styled(P)`
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
  font-style: italic;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 8px;
    line-height: 10px;
  }
`;
