import React, { useState, useRef } from "react";
import styled from "styled-components";
import { H1, H3, P, GridColumn, Label, withDefaultMedia } from "notes";
import { Link } from "Components";
import { DeviceArtwork } from "./Artwork";
import { EditUsername } from "./Edit";

const detailText = `All that’s left to do is choose a display name. Your display name will show up in the concert chat as well as the leaderboard for as well as any other set.live show you attend. The display name can use only numbers, letters or an underscore (no spaces or other punctuation). In the space below, let us know what you would like yours to be. Your display name will be uniquely yours so we’ll check to make sure no one else already has it.`;

export const UsernameForm = withDefaultMedia(
  ({ matches, onChange, onSuccess }) => {
    const [name, setName] = useState("");
    const scrollRef = useRef();

    const scrollToField = () => {
      var element = document.getElementById("scroll-container");
      let scrollPosition = scrollRef.current.offsetHeight + 172;
      element.scrollTo({
        behavior: "smooth",
        top: scrollPosition - window.innerHeight,
      });
    };

    const handleChange = (value) => {
      onChange(value);
      setName(value);
    };

    return (
      <Content yStart ref={scrollRef}>
        <H1>Reservation Complete!</H1>
        <H3>
          {!matches.large
            ? "Get Ready For The Show!"
            : "Let's Get You Ready For The Show"}
        </H3>
        <P>
          {detailText}
          {matches.small && (
            <Link onClick={scrollToField}>Set your display name now!</Link>
          )}
        </P>
        {matches.small && <DeviceArtwork username={name} />}
        <Label>Display name</Label>
        <EditUsername onSuccess={onSuccess} onChange={handleChange} />
      </Content>
    );
  }
);

const Content = styled(GridColumn).attrs({ as: "div" })`
  display: flex;
  width: 100%;
  margin-left: 72px;
  max-width: 392px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-left: 0;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-shrink: 0;
    margin-left: 0;
    max-width: 100%;
  }
  ${H1} {
    margin-bottom: 24px;
  }
  ${H3} {
    margin-bottom: 10px;
  }
  ${Label} {
    margin-top: 40px;
    margin-bottom: 4px;
  }
`;
