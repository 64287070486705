import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { GridColumn } from "notes";
import { TicketSVG } from "./TicketSVG";
import { TicketRippedSVG } from "./TicketRippedSVG";

export const Card = ({ children, image, ...props }) => {
  const theme = useContext(ThemeContext);
  return (
    <Container image={image} {...props}>
      {!theme.cardTicket?.ticket && <TopBorder />}
      <Inner>
        <Content>{children}</Content>
      </Inner>
      {!theme.cardTicket?.ticket && <BottomBorder />}
      {theme.cardTicket?.ticket &&
        (theme.cardTicket?.past ? (
          <TicketRippedSVG image={image} />
        ) : (
          <TicketSVG image={image} />
        ))}
    </Container>
  );
};

const Container = styled(GridColumn)`
  ${(props) =>
    props.image &&
    !props.theme.cardTicket.ticket &&
    `background-color: #202230;
    background-image: url(${props.image});`};
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24), 0 8px 16px 0 rgba(0, 0, 0, 0.32);
  padding-bottom: ${(props) =>
    props.theme.cardTicket.ticket
      ? 0
      : props.theme.cardTicket.past
      ? "100%"
      : "139.796%"};
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.theme.cardTicket.ticket &&
    `box-shadow: none; border-radius: 0; width: 424px;`};
  @media only screen and ${(props) => props.theme.media.medium} {
    ${(props) => props.theme.cardTicket.ticket && `width: 32.52vw`};
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 8px;
    ${(props) =>
      props.theme.cardTicket.ticket &&
      `
    width: 320px;
    height: 434px;
    margin: -8px -16px -24px;
    `};
  }
`;

const Inner = styled(GridColumn)`
  justify-content: flex-end;
  padding: ${(props) =>
    props.theme.cardTicket.ticket
      ? "32px 40px 48px 40px"
      : props.theme.cardTicket.past
      ? "16px"
      : "24px"};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media only screen and ${(props) => props.theme.media.small} {
    padding: ${(props) =>
      props.theme.cardTicket.ticket ? "25px 32px 40px" : "10px 8px"};
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: ${(props) =>
      props.theme.cardTicket.ticket
        ? `2.45vw 3.07vw 3.68vw 3.07vw`
        : props.theme.cardTicket.past
        ? "1.227vw"
        : "1.84vw"};
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: ${(props) =>
      props.theme.cardTicket.ticket
        ? `32px 40px 48px 40px`
        : props.theme.cardTicket.past
        ? "0.93vw"
        : "1.4vw"};
  }
  @media only screen and ${(props) => props.theme.media.extraLarge} {
    padding: ${(props) =>
      props.theme.cardTicket.ticket
        ? "32px 40px 48px 40px"
        : props.theme.cardTicket.past
        ? "16px"
        : "24px"};
  }
`;

const Content = styled(GridColumn)`
  justify-content: flex-end;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const TopBorder = styled.div`
  border-radius: 12px 12px 0 0;
  box-shadow: inset 0 4px 0 0 rgb(255 255 255 / 50%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 16px;
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 8px 8px 0 0;
    box-shadow: inset 0 2px 0 0 rgb(255 255 255 / 50%);
    height: 12px;
  }
`;

export const BottomBorder = styled.div`
  border-radius: 0 0 12px 12px;
  box-shadow: inset 0 -4px 0 0 rgb(0 0 0 / 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 16px;
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 0 0 8px 8px;
    box-shadow: inset 0 -2px 0 0 rgb(0 0 0 / 50%);
    height: 12px;
  }
`;
