import React, { useState, Fragment, useContext } from "react";
import { StoreContext, FieldMessage } from "Components";
import { useHistory, Redirect } from "react-router-dom";
import styled from "styled-components";
import { FormWrapper, FormContainer, LoaderInline } from "Components";
import Joi from "@hapi/joi";
import { validate } from "modules";
import { emailLogin } from "services";

import {
  P,
  H3,
  Input,
  Icon,
  ErrorMessage,
  GridColumn,
  Footnote,
  Label,
  ButtonPrimary,
} from "notes";

import { Api } from "modules";
import { Store } from "Components";
import { LANGUAGE_OPTIONS } from "lib";
import _ from "lodash";

const { auth } = Store;

const passwordResetMessage = `Please enter a new password. If any of the contact information below needs to be updated you can do so on the "My Account" page.`;

const messagingRatesMessage = `Standard data and messaging rates apply`;

const messageConsent = `I consent to receiving recurring automated notifications about Set.live events by text message through an automatic telephone dialing system. `;

const passwordInfo = `Password must contain at least 8 characters`;

const verificationOptions = [
  {
    title: "Email address",
  },
  {
    title: "Phone number",
  },
];

const password_schema = Joi.object({
  password: Joi.string().min(8).max(25).required().messages({
    "string.min": `"Password" should have a minimum length of {#limit} characters`,
    "string.max": `"Password" should have a maximum length of {#limit} characters`,
    "any.required": `"Password" is a required field`,
  }),
});

const phone_schema = Joi.object({});

export default () => {
  const { user } = useContext(StoreContext);

  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [state] = useState(
    history.location && history.location.state ? history.location.state : {}
  );

  let { source, url, method, verificationId } = state;

  if (!source || !method || !verificationId) {
    history.push("/reset");
    return null;
  }

  const handleSubmit = async () => {
    setErrorMessage(null);
    let errors = validate({ password }, password_schema);
    setFormErrors(errors);
    if (errors) {
      return;
    }
    setSubmitted(true);
    try {
      const { data } = await Api.resetPassword({ ...state, password });
      if (method === "email") {
        const { user } = await emailLogin(state.source, password);
      }
      if (data) {
        if (url && url !== "") {
          history.push(url);
        } else {
          history.push("/");
        }
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
    } finally {
      setSubmitted(false);
    }
  };

  const handleChange = (value, e) => {
    setPassword(value);
    delete formErrors[e.target.name];
    setFormErrors(formErrors);
  };

  return (
    <FormWrapper>
      <FormContainerStyle>
        <H3>Choose A New Password</H3>
        <P>{passwordResetMessage}</P>
        {errorMessage && <ErrorMessage content={errorMessage} />}
        <FieldContainer>
          <Label>New Password</Label>
          <Input
            disabled={submitted}
            name="password"
            leftIcon={<Icon form name="Key" />}
            placeholder="Password..."
            value={password}
            onChange={handleChange}
            rightIcon={
              <TogglePassword
                onClick={() => setHidePassword(!hidePassword)}
                name={hidePassword ? "Hide" : "Show"}
              />
            }
            type={hidePassword ? "password" : "text"}
          />

          <Footnote>{passwordInfo}</Footnote>
          <FieldMessage
            active={!!formErrors?.hasOwnProperty("password")}
            content={formErrors?.password}
          />
        </FieldContainer>

        <ButtonPrimary disabled={submitted} onClick={() => handleSubmit()}>
          {submitted ? <LoaderInline /> : "Update Password and Log In"}
        </ButtonPrimary>
      </FormContainerStyle>
    </FormWrapper>
  );
};

const FormContainerStyle = styled(FormContainer)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  margin: 40px 0 72px 0;
  padding: 40px;
  position: relative;
  min-height: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: 40px 24px 104px 24px;
  }
`;

const FieldContainer = styled(GridColumn)`
  margin-top: 24px;
  margin-bottom: 40px;
  ${Footnote} {
    margin-top: 8px;
  }
`;

const TogglePassword = styled(Icon)`
  margin-top: -4px;
`;
