import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import "fast-text-encoding";
import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import LogRocket from "logrocket";
if (process.env.NODE_ENV === "production") {
  LogRocket.init("fyqxo2/setlive");
}

const gtmInit = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayer: {
    event: "set_environment",
    environment:
      process.env.REACT_APP_STAGE === "production" ? "production" : "stage",
  },
};

TagManager.initialize(gtmInit);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
