import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn } from "notes";
import { formatCurrency } from "Components";
import { CreditCard } from "../";

export const RenderTransactions = ({
  tickets,
  inShowPurchases,
  inShowPurchasesTotal,
  totalCharged,
  source,
  vouchers,
}) => {
  return (
    <TransactionContainer>
      <MobileContainer>
        {tickets.map(({ quantity, label, total }) => (
          <Row>
            <SubtotalLabel stretch>
              <Ellipses>
                {quantity}x {label}
              </Ellipses>
            </SubtotalLabel>
            <SubtotalPrice xEnd>{total}</SubtotalPrice>
          </Row>
        ))}

        <Divider />

        {inShowPurchases.map(({ label, total }) => (
          <Row>
            <ItemizedLabel stretch>{label}</ItemizedLabel>
            <ItemizedPrice xEnd>{total}</ItemizedPrice>
          </Row>
        ))}

        {inShowPurchasesTotal > 0 && (
          <>
            <Divider />
            <Row>
              <SubtotalLabel stretch>In Show Purchases</SubtotalLabel>
              <SubtotalPrice xEnd>
                {formatCurrency(inShowPurchasesTotal)}
              </SubtotalPrice>
            </Row>
            <Divider />
          </>
        )}

        <Row>
          <ItemizedLabel stretch>Taxes</ItemizedLabel>
          <ItemizedPrice xEnd>$0.00</ItemizedPrice>
        </Row>

        <GridRow>
          <SubtotalLabel stretch>Total Charged</SubtotalLabel>
          <SubtotalPrice xEnd>
            {totalCharged > 0 ? formatCurrency(totalCharged) : "Free"}
          </SubtotalPrice>
        </GridRow>

        {!!vouchers?.length && (
          <>
            <Divider style={{ marginTop: "16px", display: "block" }} />
            <SubtotalLabel stretch style={{ marginBottom: "16px" }}>
              Gift Codes
            </SubtotalLabel>
            {vouchers.map(({ code, label }) => (
              <Row>
                <ItemizedLabel stretch style={{ fontSize: "14px" }}>
                  {label}
                </ItemizedLabel>
                <GridColumn>
                  {code.map((item) => (
                    <ItemizedPrice xEnd style={{ fontSize: "14px" }}>
                      https://set.live/redeem/{item}
                    </ItemizedPrice>
                  ))}
                </GridColumn>
              </Row>
            ))}
          </>
        )}
      </MobileContainer>

      {totalCharged > 0 && <CreditCardStyle {...source} />}
    </TransactionContainer>
  );
};

const TransactionContainer = styled(GridColumn)`
  width: 100%;
  & + & {
    margin-top: 40px;
  }
`;

const MobileContainer = styled(GridColumn)`
  @media only screen and ${(props) => props.theme.media.small} {
    background-color: ${(props) => props.theme.colors.transactionFill};
    border-top: 1px solid ${(props) => props.theme.palette.gray.lighter};
    padding: 16px 24px;
  }
`;

const CreditCardStyle = styled(CreditCard)`
  margin-top: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 0;
    border-left: none;
    border-right: none;
    margin-top: 0;
  }
`;

const Divider = styled.div`
  display: none;
  background-color: ${(props) => props.theme.palette.gray.lightest};
  margin-bottom: 16px;
  width: 100%;
  height: 2px;
`;

const Row = styled(GridRow)`
  margin-bottom: 16px;
  width: 100%;
  & + ${Divider} {
    display: block;
  }
`;

const SubtotalLabel = styled(GridRow)`
  font-weight: 600;
  overflow: hidden;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const SubtotalPrice = styled(GridRow)`
  font-weight: 900;
`;

const ItemizedPrice = styled(GridRow)`
  color: ${(props) => props.theme.palette.gray.primary};
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 15px;
    line-height: 22px;
  }
`;

const ItemizedLabel = styled(ItemizedPrice)`
  font-weight: 400;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Ellipses = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
