import React from "react";
import styled from "styled-components";
import { Label, GridColumn } from "notes";
import { Shipping } from "./Shipping";
import { VideoDetails } from "./Video";
import { Shirt } from "./Shirt";
import { Email } from "./Email";

export * from "./Shipping";
export * from "./Video";
export * from "./Shirt";
export * from "./Email";

export const Requirement = ({
  rewardItemId,
  rewardItem,
  userResponse,
  status,
}) => {
  const requiresArray = Object.keys(rewardItem.requires)
    .map((item) => item)
    .sort()
    .reverse();

  const renderRequirement = (type) => {
    switch (type) {
      case "userFeedback":
        return (
          <>
            <LabelStyle>Message details</LabelStyle>
            <VideoDetails
              rewardId={rewardItemId}
              userResponse={userResponse?.video}
              processingStatus={status}
            />
          </>
        );
      case "email":
        return (
          <>
            <LabelStyle>Email details to</LabelStyle>
            <Email
              rewardId={rewardItemId}
              userResponse={userResponse?.email}
              processingStatus={status}
            />
          </>
        );

      case "shirtSize":
        return (
          <>
            <LabelStyle>Shirt size</LabelStyle>
            <Shirt
              rewardId={rewardItemId}
              userResponse={userResponse?.shirtSize}
              processingStatus={status}
            />
          </>
        );

      case "shipping":
        return (
          <>
            <LabelStyle>
              {status === "shipped" ? "Sent to" : "Send to"}
            </LabelStyle>
            <Shipping
              rewardId={rewardItemId}
              userResponse={userResponse?.shippingAddress}
              processingStatus={status}
            />
          </>
        );
      default:
        break;
    }
  };
  return (
    <Column>{requiresArray.map((item) => renderRequirement(item))}</Column>
  );
};

const LabelStyle = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-weight: 300;
  margin-top: 24px;
  margin-bottom: 4px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 16px;
  }
`;

const Column = styled(GridColumn)`
  width: 50%;
  margin-right: 24px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
    margin-right: 0;
  }
`;
