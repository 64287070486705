import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isSafari, PLAYBACK_STATUS } from "../const";
import { PlayIcon } from "../styled";
import { Icon } from "notes";
import Duration from "./Duration";

// default ref if needed
const ref = React.createRef();

const Playback = ({ url, autoPlay = false }) => {
  const [playbackStatus, setPlaybackStatus] = useState(PLAYBACK_STATUS.NONE);
  const [playbackTime, setPlaybackTime] = useState();

  const updateTime = () => {
    if (!ref.current) return;
    const t = Math.floor(ref.current.currentTime);
    if (t !== playbackTime) {
      setPlaybackTime(t);
    }
  };

  useEffect(() => {
    switch (playbackStatus) {
      case PLAYBACK_STATUS.PLAYING:
        if (typeof ref.current.onended !== "function") {
          ref.current.onended = () => {
            setPlaybackStatus(PLAYBACK_STATUS.ENDED);
          };
        }
        if (typeof ref.current.ontimeupdate !== "function") {
          ref.current.ontimeupdate = updateTime;
        }
        ref.current.play();
        ref.current.muted = false;
        break;
      case PLAYBACK_STATUS.STOPPED:
        ref.current.stop();
        break;
      case PLAYBACK_STATUS.PAUSED:
        ref.current.pause();
        break;
    }
  }, [playbackStatus]);

  useEffect(() => {
    if (autoPlay) {
      setPlaybackStatus(PLAYBACK_STATUS.PLAYING);
    }
  }, [url]);

  return (
    <VideoWrapper>
      <PlaybackVideo
        id="playback-video"
        ref={ref}
        src={url}
        playsInline
        muted={isSafari}
      />
      <Duration value={playbackTime} />
      {playbackStatus !== PLAYBACK_STATUS.PLAYING && (
        <PlayIcon onClick={() => setPlaybackStatus(PLAYBACK_STATUS.PLAYING)}>
          <PlayIconBackground />
          <StyledPlayIcon
            media
            name="Play"
            style={{ height: "75", width: "75" }}
          />
        </PlayIcon>
      )}
    </VideoWrapper>
  );
};

const isEqual = (prev, next) =>
  prev.url === next.url && prev.autoPlay === next.autoPlay;

export default React.memo(Playback, isEqual);

const VideoWrapper = styled.div`
  position: relative;
  height: 640px;
  width: 360px;
  margin: 0 auto;
`;

export const PlaybackVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 640px;
  width: 360px;
  object-fit: fill;
`;

const PlayIconBackground = styled.div`
  z-index: 1;
  top: 18px;
  left: 25px;
  width: 30px;
  height: 40px;
  background-color: #ffffff;
  position: absolute;
`;

const StyledPlayIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
