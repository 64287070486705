import React, { useContext } from "react";
import styled from "styled-components";
import { GridRow, H3, Icon } from "notes";
import { TicketContext } from "Providers";
import { Countdown } from "Components";
import { ActionLink, ActionText } from "../Details";

export const Date = ({
  tomorrow,
  startCountdown,
  doorsOpen,
  live,
  onClick,
}) => {
  const { upcomingTickets } = useContext(TicketContext);
  const { start, time, type } = upcomingTickets[0] ?? {};

  if (live) {
    return (
      <GridRow yCenter>
        <ActionText>
          {type === "meet_greet" ? "Calls are starting" : "Stream is LIVE!"}
        </ActionText>
        <ActionLink onClick={onClick}>
          {type === "meet_greet" ? "Get in line" : "Join Show"}
          <Icon tertiary name="Next" />
        </ActionLink>
      </GridRow>
    );
  }

  if (doorsOpen) {
    return (
      <GridRow yCenter>
        <ActionText>Doors are now</ActionText>
        <ActionLink onClick={onClick}>
          Open
          <Icon tertiary name="Next" />
        </ActionLink>
      </GridRow>
    );
  }

  if (startCountdown) {
    return <Countdown start={start} />;
  }

  return (
    <Container>
      <Icon form name="Calendar" />
      <H3>
        {tomorrow ? (
          "Tomorrow"
        ) : (
          <Uppercase>{start.toFormat("MMM d, y")}</Uppercase>
        )}{" "}
        @ {time.replace(/\s+/g, "")}{" "}
        <Uppercase>{start.offsetNameShort}</Uppercase>
      </H3>
    </Container>
  );
};

const Uppercase = styled.span`
  text-transform: uppercase;
`;

const Container = styled(GridRow)`
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    path {
      fill: #fff;
    }
  }
  ${H3} {
    color: #fff;
    font-family: ${(props) => props.theme.fonts.overpass};
    font-weight: 700;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    svg {
      width: 1.84vw;
      height: 1.84vw;
      margin-right: 0.92vw;
    }
    ${H3} {
      font-size: 1.92vw;
      line-height: 2.45vw;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
    }
    ${H3} {
      font-size: 17px;
      line-height: 22px;
    }
  }
`;
