import React from "react";
import styled from "styled-components";
import { H3, H4, P } from "notes";
export const DefaultView = ({ heading, children, ...props }) => {
  return (
    <Container {...props}>
      <H3 style={{ paddingBottom: "12px" }}>{heading}</H3>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 12px;
  justify-content: flex-start;
  align-items: center;
  min-height: 300px;
  flex-direction: column;
  margin: 40px 0;
  height: calc(100% - 40px);
  ${(props) =>
    props.theme.dark &&
    `
  ${P}, ${H3}, ${H4} {
    color: #fff;
  }`}
`;
