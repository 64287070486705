import React, { useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  HeaderSecondary,
  FooterBlock,
  SplashBlock,
  Ticket,
  Packages,
  TimeContext,
} from "Components";
import { GridColumn, withDefaultMedia } from "notes";
import { Stage, ArtistDescription, ShowStatus, SetList } from "./Blocks";
import desktopGift from "./desktopgift.png";
import mobileGift from "./mobilegift.png";

export const PreShow = withDefaultMedia(
  ({ matches, show, live, tickets, ticketsClosed }) => {
    const history = useHistory();
    const { time: nowTime } = useContext(TimeContext);
    const { timeToStart } = show;
    const handleActionClick = (show, purchased) => {
      if (purchased) {
        window.open(show.requests);
      } else {
        localStorage.setItem("eventId", show.id);
        history.push(`/purchase`);
      }
    };
    const isPastShow = show.end < nowTime;
    const packageRef = useRef();

    const handleScroll = () => {
      let scrollPosition = packageRef.current;
      scrollPosition.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    };

    return (
      <GridColumn>
        <HeaderSecondary banner />
        <Stage>
          {!isPastShow && (
            <ShowStatus
              days={timeToStart.days}
              hours={timeToStart.hours}
              minutes={timeToStart.minutes}
              seconds={timeToStart.seconds}
              live={live}
              ticketsClosed={!!tickets ? false : ticketsClosed ? true : false}
            />
          )}

          <Ticket
            onActionClick={() => handleActionClick(show, !!tickets)}
            show={show}
            purchased={!!tickets}
            onScrollClick={handleScroll}
            ticketsClosed={ticketsClosed}
          />
        </Stage>
        <Container xCenter>
          <Content ref={packageRef}>
            {!ticketsClosed && !tickets && <Packages eventId={show.id} />}
            {!ticketsClosed && !!show.requests && (
              <SetList link={show.requests} />
            )}
            <ArtistDescription show={show} />
            {!ticketsClosed && !show.hideGiftBanner && (
              <img
                style={{ width: "100%", marginTop: "16px" }}
                src={matches.small ? mobileGift : desktopGift}
                onClick={() => history.push(`/gift/${show.id}`)}
              />
            )}
          </Content>
        </Container>
        <FooterBlock secondary />
      </GridColumn>
    );
  }
);

const Container = styled(SplashBlock)`
  background-color: #fff;
  padding: 0 24px 90px 24px;
  position: relative;
  @media only screen and ${(props) => props.theme.media.small} {
    padding-bottom: 72px;
  }
`;

const Content = styled(GridColumn).attrs({ as: "div" })`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1016px;
`;
