import React, { useContext } from "react";
import styled from "styled-components";
import { GridColumn, Subheader, H3, GridRow, Footnote } from "notes";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { StoreContext } from "Components";

export const Event = ({ eventId, date, ...props }) => {
  const { user, Store } = useContext(StoreContext);
  const [event, loading, error] = useDocumentDataOnce(
    Store.firestore().doc(`events/${eventId}`)
  );

  if (loading || !user) {
    return null;
  }

  return (
    <Container columns={4} noShrink {...props}>
      <ArtistImage
        background={`${process.env.REACT_APP_ASSETS_PATH}/${event.assets?.preshowBanner?.path}`}
      />
      <H3>{event.title}</H3>
      <Subheader>{event.subtitle}</Subheader>
      <Footnote>
        <span>Status:</span> Last charged on {date}
      </Footnote>
    </Container>
  );
};

const Container = styled(GridColumn)`
  border-right: 1px solid ${(props) => props.theme.palette.gray.lighter};
  padding: 24px;
  ${H3} {
    margin-bottom: 8px;
    font-weight: 900;
  }
  ${Subheader} {
    margin-bottom: 24px;
  }
  ${Footnote} span {
    margin-right: 6px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    border-right: none;
    padding-bottom: 0;
    ${H3} {
      text-align: center;
      font-size: 17px;
      line-height: 22px;
    }
    ${Subheader} {
      margin-bottom: 16px;
      text-align: center;
    }
    ${Footnote} {
      display: none;
    }
  }
`;

const ArtistImage = styled(GridRow)`
  background-image: url(${(props) => props.background});
  background-position: center center;
  background-size: cover;
  border-radius: 40px;
  margin-bottom: 16px;
  width: 80px;
  height: 80px;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 35px;
    width: 70px;
    height: 70px;
  }
`;
