import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GridColumn, H3, P, ButtonPrimary } from "notes";

export const CreateAccount = () => {
  const history = useHistory();
  return (
    <Container xCenter>
      <Divider />
      <H3>New Shows Are Always Being Added!</H3>
      <P>
        Set.Live is an interactive, live music streaming experience where you
        can watch artists do what they love best... play shows! So why not grab
        a friend, get comfy on your couch, and make a watch party of it!
      </P>
      <Button onClick={() => history.push("/signup")}>
        Get a Free Set.Live Account
      </Button>
    </Container>
  );
};

const Container = styled(GridColumn)`
  margin: 96px auto 0;
  width: 100%;
  max-width: 600px;
  ${H3}, ${P} {
    color: #ffffff;
    text-align: center;
  }
  ${P} {
    margin-top: 16px;
    margin-bottom: 56px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin: 72px auto 0;
    padding: 0 24px;
    ${H3} {
      font-size: 17px;
      line-height: 22px;
    }
    ${P} {
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
      margin-bottom: 40px;
    }
  }
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.primary};
  border-radius: 1px;
  margin-bottom: 56px;
  width: 100%;
  height: 2px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 40px;
  }
`;

const Button = styled(ButtonPrimary)`
  width: 100%;
  max-width: 392px;
`;
