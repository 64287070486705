import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, H4, Icon, P, Label } from "notes";
import { Requirement } from "./Requirements";
import { Status } from "./Status";
import { Detail } from "./Components";
import { VideoIcon, ShirtIcon, GiftIcon } from "Images";

export const Reward = ({ item }) => {
  const {
    rewardItem,
    supportLevel,
    rewardItemId,
    userResponse,
    status,
    url,
  } = item;

  const rewardImage =
    rewardItem.assets?.primary?.path || supportLevel?.assets?.primary?.path;

  const getDefaultIcon = (type) => {
    switch (type) {
      case "tshirt":
        return <ShirtIcon />;
      case "music":
        return <Icon form name="Record" />;
      case "poster":
        return <Icon form name="Picture" />;
      case "video_message":
        return <VideoIcon />;
      case "meet_greet":
        return <VideoIcon />;
      default:
        return <GiftIcon />;
    }
  };
  return (
    <Container>
      {!!rewardImage ? (
        <ImageContainer noShrink xCenter yCenter>
          <RewardImage
            src={`${process.env.REACT_APP_ASSETS_PATH}/${rewardImage}`}
          />
        </ImageContainer>
      ) : (
        <IconContainer noShrink xCenter yCenter>
          {getDefaultIcon(rewardItem.type)}
        </IconContainer>
      )}
      <Content>
        <Title>{rewardItem.name}</Title>
        <Details>
          {!!rewardItem.requires && (
            <Requirement
              rewardItemId={rewardItemId}
              rewardItem={rewardItem}
              userResponse={userResponse}
              status={status}
            />
          )}

          {rewardItem.type === "meet_greet" && (
            <Detail label="Event Details">
              <P>
                {!!rewardItem.data?.link
                  ? rewardItem.data.activeMessage
                  : rewardItem.data.pendingMessage}
              </P>
            </Detail>
          )}
          <StatusContainer>
            <LabelStyle>Status</LabelStyle>
            <Status
              processingStatus={status}
              rewardItem={rewardItem}
              userResponse={userResponse}
              videoUrl={url}
            />
          </StatusContainer>
        </Details>
      </Content>
    </Container>
  );
};

const Container = styled(GridRow)`
  width: 100%;
  text-overflow: ellipsis;
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
    margin-top: 40px;
    padding-top: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

const Details = styled(GridRow)`
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
  }
`;

const Content = styled(GridColumn)`
  margin-left: 24px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 0;
    margin-top: 24px;
  }
`;

const IconContainer = styled(GridColumn)`
  background-color: #fafafa;
  width: 144px;
  height: 144px;
  svg {
    width: 40px;
    height: 40px;
    path {
      fill: ${(props) => props.theme.palette.gray.lighter};
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    align-self: center;
  }
`;

const ImageContainer = styled(GridRow)`
  width: 144px;
  height: 144px;
  @media only screen and ${(props) => props.theme.media.small} {
    align-self: center;
  }
`;

const RewardImage = styled.img`
  height: 100%;
`;

const Title = styled(H4)`
  color: ${(props) => props.theme.palette.black};
  font-weight: 700;
`;

const LabelStyle = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  font-weight: 300;
  margin-top: 24px;
  margin-bottom: 4px;

  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 16px;
  }
`;

const StatusContainer = styled(GridColumn)`
  width: 50%;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
  }
`;
