import React, { createContext, useEffect, useState } from "react";
import { Store } from "Components";
import { useStripe } from "@stripe/react-stripe-js";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { assignUserToAnalytics } from "services";
import LogRocket from "logrocket";

export const StoreContext = createContext();
export const StoreConsumer = StoreContext.Consumer;
const { auth } = Store;
export const StoreProvider = ({ children, ...props }) => {
  const [user, setUser] = useState();
  const [tempVerify, setTempVerify] = useState(false); //it can take some time for the verification to be updated in the db, this gives us a local override
  const stripe = useStripe();
  const handleAuthStateChange = (newUser) => {
    if (user && !newUser) {
      setTempVerify(false);
    }
    setUser(newUser);
    assignUserToAnalytics(newUser?.uid);

    //setup cross app auth token
    if (auth().currentUser) {
      auth()
        .currentUser.getIdToken()
        .then((token) => {
          const options = {
            method: "POST",
            cache: "no-cache",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              idToken: token,
            }),
          };
          return fetch("/auth/initSession", options);
        });
    }
  };
  useEffect(() => {
    return auth().onAuthStateChanged(handleAuthStateChange);
  }, [user]);
  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);
  const userRefresh = () => {
    setUser(Store.auth().currentUser);
  };
  const [tickets, ticketsLoading, error] = useCollectionData(
    Store.firestore()
      .collection("transactions")
      .where("uid", "==", user?.uid || null)
      .where("paymentItem.type", "==", "ticket")
  );
  const [username, unloading, unerror] = useDocumentData(
    Store.firestore().doc(`usernames/${user?.uid}`)
  );
  if (!stripe) {
    return null;
  }
  return (
    <StoreContext.Provider
      value={{
        Store,
        user,
        userLoading: user === undefined,
        userRefresh,
        userVerified: user?.emailVerified || user?.phoneNumber || tempVerify,
        setTempVerify,
        username: username?.value,
        tickets,
        ticketsLoading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
