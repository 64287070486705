import React from "react";
import styled from "styled-components";
import { GridRow, P } from "notes";

export const Divider = ({ purchased, ...props }) => {
  return (
    <DividerContainer variant={purchased} {...props}>
      <DividerStyle variant={purchased} xCenter>
        {purchased && <Text>Your Ticket to the Show</Text>}
        <Caret variant={purchased} />
      </DividerStyle>
    </DividerContainer>
  );
};

const DividerContainer = styled(GridRow)`
  background-color: ${(props) =>
    props.variant && !props.theme.preShow
      ? props.theme.palette.orange.lightest
      : "#ffffff"};
  height: ${(props) => (props.variant ? "50px" : "20px")};
  position: relative;
  overflow: hidden;
`;

const Text = styled(P)`
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  z-index: 3;
`;

const DividerStyle = styled(GridRow)`
  align-items: center;
  background-color: ${(props) =>
    props.variant
      ? props.theme.palette.orange.primary
      : props.theme.palette.orange.lighter};
  margin-bottom: 12px;
  height: ${(props) => (props.variant ? "38px" : "7px")};
  width: 100%;
`;

const Caret = styled.div`
  background-color: ${(props) =>
    props.variant
      ? props.theme.palette.orange.primary
      : props.theme.palette.orange.lighter};
  display: block;
  position: absolute;
  transform: rotate(45deg);
  height: 18px;
  width: 18px;
  z-index: 2;
  bottom: ${(props) => (props.variant ? "3px" : "4px")};
`;
