import React from "react";
import styled from "styled-components";
import {
  Reminder,
  ExpandingText,
  Link,
  Share,
  htmlParser as parse,
} from "Components";
import {
  GridColumn,
  GridRow,
  H3,
  Subheader,
  P,
  Icon,
  withDefaultMedia,
  Footnote,
} from "notes";

export const ArtistDescription = withDefaultMedia(
  ({ matches, show, showView, ...props }) => {
    return (
      <Container showView={showView} {...props}>
        <Share showView={showView} />
        {!matches.small && (
          <ShowHeader yStart={matches.small} yCenter={!matches.small}>
            <Subtitle>{show.subtitle}</Subtitle>
          </ShowHeader>
        )}
        {show.description && (
          <Description>
            <ArtistInfo yCenter={!matches.small}>
              <ArtistImage noShrink background={show.image} />
              {!matches.small && <H3>{show.artist}</H3>}
              {matches.small && (
                <MobileInfo>
                  <H3>{show.subtitle}</H3>
                  <Footnote>{show.artist}</Footnote>
                </MobileInfo>
              )}
            </ArtistInfo>
            {matches.small || showView ? (
              <ExpandingText
                count={showView ? 300 : 125}
                text={show.description}
              />
            ) : (
              <P>{show.description}</P>
            )}
          </Description>
        )}
        {show.biography && (
          <Biography>
            <BioHeader>Artist Biography</BioHeader>
            <P>{parse(show.biography)}</P>
          </Biography>
        )}
        {show.social && (
          <Social>
            <LinkContainer stretch>
              <Link href={show.artistUrl} target="_blank">
                {show.artistUrl}
              </Link>
            </LinkContainer>
            <IconGroup>
              {show.social.facebook && (
                <IconLink href={show.social.facebook} target="_blank">
                  <SocialIcon color="facebook" name="Facebook" />
                </IconLink>
              )}
              {show.social.twitter && (
                <IconLink href={show.social.twitter} target="_blank">
                  <SocialIcon color="twitter" name="Twitter" />
                </IconLink>
              )}
              {show.social.instagram && (
                <IconLink href={show.social.instagram} target="_blank">
                  <SocialIcon color="instagram" name="Instagram" />
                </IconLink>
              )}
              {show.social.snapchat && (
                <IconLink href={show.social.snapchat} target="_blank">
                  <SocialIcon color="snapchat" name="Snapchat" />
                </IconLink>
              )}
              {show.social.youtube && (
                <IconLink href={show.social.youtube} target="_blank">
                  <SocialIcon color="youtube" name="YouTube" />
                </IconLink>
              )}
              {show.social.spotify && (
                <IconLink href={show.social.spotify} target="_blank">
                  <SocialIcon color="spotify" name="Spotify" />
                </IconLink>
              )}
              {show.social.soundcloud && (
                <IconLink href={show.social.soundcloud} target="_blank">
                  <SocialIcon color="soundcloud" name="SoundCloud" />
                </IconLink>
              )}
            </IconGroup>
          </Social>
        )}
      </Container>
    );
  }
);

const Container = styled(GridColumn)`
  width: 100%;
  padding-top: ${(props) => (props.showView ? 0 : "40px")};
  @media only screen and ${(props) => props.theme.media.small} {
    padding-top: ${(props) => (props.showView ? 0 : "24px")};
  }
`;

const ArtistInfo = styled(GridRow)`
  margin-bottom: 16px;
  h3 {
    color: ${(props) =>
      props.theme.dark ? "#ffffff" : props.theme.palette.black};
    font-weight: 700;
    transition: color ease 0.3s;
  }
`;

const LinkContainer = styled(GridRow)`
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
    ${Link} {
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

const darkIconStyles = `
  svg path {
    fill: #fff;
  }
`;

const IconGroup = styled(GridRow)`
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
    margin-top: 40px;
  }
  ${(props) => props.theme.dark && darkIconStyles};
`;

const ShowHeader = styled(GridRow)`
  border-bottom: 2px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  padding-bottom: 24px;
  transition: all ease 0.3s;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 16px;
    padding-bottom: 16px;
  }
`;

const Subtitle = styled(P)`
  color: ${(props) => (props.theme.dark ? "#fff" : props.theme.palette.black)};
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  transition: color ease 0.3s;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
  }
`;

const ArtistImage = styled(GridRow)`
  background-image: url(${(props) => props.background});
  background-position: center center;
  background-size: cover;
  border: ${(props) => (props.theme.dark ? "1px solid #fff" : "none")};
  border-radius: 28px;
  margin-right: 24px;
  width: 56px;
  height: 56px;
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 20px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }
`;

const Description = styled(GridColumn)`
  width: 100%;
  border-bottom: 2px solid
    ${(props) =>
      props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
  margin-top: 24px;
  padding-bottom: 40px;
  ${P} {
    color: ${(props) =>
      props.theme.dark ? "#fff" : props.theme.palette.black};
    font-size: 17px;
    line-height: 27px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    padding-bottom: 24px;
    ${P} {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const BioHeader = styled(Subheader)`
  margin-bottom: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Biography = styled(GridColumn)`
  margin-top: 40px;
  ${P} {
    color: ${(props) =>
      props.theme.dark ? "#fff" : props.theme.palette.black};
    font-size: 17px;
    line-height: 27px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-bottom: 2px solid
      ${(props) =>
        props.theme.palette.gray[props.theme.dark ? "primary" : "lightest"]};
    padding-bottom: 24px;

    ${P} {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const Social = styled(GridRow)`
  margin-top: 40px;
  @media only screen and ${(props) => props.theme.media.small} {
    flex-direction: column;
    align-content: center;
    margin-top: 24px;
  }
`;

const IconLink = styled.a`
  text-decoration: none;
  outline: none;
  & + & {
    margin-left: 24px;
  }
`;

const SocialIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  path {
    fill: #000;
  }
  &:hover {
    path {
      fill: ${(props) => props.theme.colors[props.color]};
    }
  }
`;

const MobileInfo = styled(GridColumn)`
  ${H3} {
    font-size: 17px;
    line-height: 22px;
  }
  ${Footnote} {
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
  }
`;
