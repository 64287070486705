import React, { useState, Fragment, useContext, useRef } from "react";
import { StoreContext, LoaderInline, PurchaseTicket } from "Components";
import { useHistory, Redirect } from "react-router-dom";
import styled from "styled-components";
import { FormWrapper, Link } from "Components";
import {
  H3,
  P,
  Input,
  Icon,
  Label,
  Radio,
  CountrySelector,
  Checkbox,
  ErrorMessage,
  GridColumn,
  ButtonPrimary,
  Subheader,
  Footnote,
  GridRow,
} from "notes";

import { Api } from "modules";
import { Store, FormContainer } from "Components";
import { Container } from "../Enrollment";
import _ from "lodash";

const { auth } = Store;

var LANGUAGE_OPTIONS = require("./languages.json");

const PhoneInput = styled(Input)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const MessageSuccess = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.green.lighter};
  background-color: ${(props) => props.theme.palette.green.lightest};
  padding: 12px 24px;
`;

const accountVerificationMessage = `Please choose how you would like to validate your account. You can either use a mobile phone number or your email address.`;

const messagingRatesMessage = `Standard data and messaging rates apply`;

const messageConsent = `I consent to receiving recurring automated notifications about Set.live events by text message through an automatic telephone dialing system. `;

const emailSuccessMessage =
  "An e-mail has been sent to the address provided.  Please confirm your account by clicking the link contained in the e-mail.";

const emailResentMessage =
  "We have sent another verification e-mail. Please confirm your account by clicking the link contained in the e-mail.";

const verificationOptions = [
  {
    title: "Send me a text message",
  },
  {
    title: "Send me an email",
  },
];

export const RequestVerification = ({ location: { state: rawState } }) => {
  const { Store, user, userLoading, username } = useContext(StoreContext);
  const eventId = localStorage.getItem("eventId");

  const history = useHistory();

  const [country, setCountry] = useState("US");
  const [verificationMethod, setVerificationMethod] = useState(
    rawState?.type === "email" ? 1 : 0
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [agree, setAgree] = useState(true);
  const [modal, toggleModal] = useState(false);
  const [status, setStatus] = useState({});
  const [retries, setRetries] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const verifier = useRef();

  const handleRestart = () => {
    Store.auth().signOut();
  };

  const sendVerification = async () => {
    if (submitted) return;

    setSubmitted(true);

    setRetries(retries + 1);

    switch (verificationMethod) {
      case 0:
        try {
          if (!verifier.current) {
            verifier.current = new auth.RecaptchaVerifier("submit", {
              size: "invisible",
            });
          }

          // Get country code
          const countryOption = _.find(
            LANGUAGE_OPTIONS,
            ({ value }) => value === country
          );

          const source = `${countryOption?.prefix ?? "+1"}${phoneNumber}`;

          const verification = await auth().signInWithPhoneNumber(
            source,
            verifier.current
          );

          history.push("/verify", {
            method: "sms",
            source: source,
            verificationId: verification.verificationId,
          });
        } catch (err) {
          console.log(err);
          setStatus({ code: "error", message: err.message });
        } finally {
          setSubmitted(false);
        }
        break;
      case 1:
        try {
          setSubmitted(true);
          /*
          const result = auth().currentUser.sendEmailVerification({
            url: process.env.REACT_APP_VERIFY_REDIRECT,
          });
          */
          const { data } = await Api.sendVerificationEmail(
            auth().currentUser.email
          );

          history.push("/verify", {
            method: "email",
            source: auth().currentUser.email,
            verificationId: data,
          });
        } catch (err) {
          console.log(err);
          setStatus({ code: "error", message: err.message });
        } finally {
          setSubmitted(false);
        }
        break;
      default:
        break;
    }
  };

  if (userLoading) {
    return null;
  }

  return user?.uid ? (
    <FormWrapper hideFooter>
      <Container showTicket={!!eventId}>
        {!!eventId && <PurchaseTicket eventId={eventId} />}
        <FormContainer>
          <Link
            style={{ position: "absolute", top: "24px", right: "24px" }}
            onClick={() => Store.auth().signOut()}
          >
            Sign Out
          </Link>
          <Content showTicket={!!eventId}>
            <Subheader>Step 1 of 2</Subheader>
            <H3>Account Verification</H3>
            <P>{accountVerificationMessage}</P>

            {status.code === "error" && !submitted && (
              <ErrorMessage
                style={{ marginBottom: "24px" }}
                content={status.message}
              />
            )}

            <AccountLabel>Account</AccountLabel>
            <P>{user.email}</P>
            <Footnote>
              Wrong email?{" "}
              <Link
                style={{
                  display: "inline-block",
                  fontSize: "12px",
                  fontStyle: "normal",
                  marginLeft: "8px",
                }}
                onClick={handleRestart}
              >
                Change it
              </Link>
            </Footnote>

            {status.code === "success" && !submitted && (
              <>
                <MessageSuccess>{status.message}</MessageSuccess>
                <ButtonPrimary
                  disabled={submitted}
                  style={{ marginTop: "20px" }}
                  onClick={sendVerification}
                >
                  {submitted ? <LoaderInline /> : "Resend Verification E-mail"}
                </ButtonPrimary>
              </>
            )}
            <button id="submit" style={{ display: "none" }} />

            {status.code !== "success" && (
              <Form>
                <Label>Verification Method</Label>
                {verificationOptions.map(({ title, disabled }, index) => {
                  return (
                    <Radio
                      key={index}
                      disabled={submitted}
                      checked={index === verificationMethod}
                      onChange={(value) => {
                        if (value) {
                          setVerificationMethod(index);
                        }
                      }}
                      title={title}
                    />
                  );
                })}

                {verificationMethod === 0 && (
                  <Fragment>
                    <Label>Phone Number</Label>
                    <GridRow style={{ position: "relative" }}>
                      <PhoneInput
                        disabled={submitted}
                        value={phoneNumber}
                        leftIcon={<Icon form name="Phone" />}
                        placeholder="(000) 000-0000"
                        onChange={(value) => setPhoneNumber(value)}
                      />
                      <CountrySelector
                        disabled={submitted}
                        onChange={({ value }) => {
                          setCountry(value);
                        }}
                        selected={
                          LANGUAGE_OPTIONS[
                            _.findIndex(
                              LANGUAGE_OPTIONS,
                              ({ value }) => value === country
                            )
                          ]
                        }
                        options={LANGUAGE_OPTIONS}
                        country
                      />
                    </GridRow>
                    <Footnote>{messagingRatesMessage}</Footnote>

                    <Checkbox
                      disabled={submitted}
                      alignTop
                      checked={agree}
                      onChange={(value) => setAgree(value)}
                    >
                      {messageConsent}{" "}
                      <Link target="_blank" href="/privacy">
                        Privacy Policy
                      </Link>
                      .
                    </Checkbox>
                  </Fragment>
                )}
              </Form>
            )}
            {status.code !== "success" && (
              <ButtonPrimary
                disabled={submitted}
                onClick={sendVerification}
                style={{ marginTop: "29px" }}
              >
                {submitted ? <LoaderInline /> : "Send Code"}
              </ButtonPrimary>
            )}
          </Content>
        </FormContainer>
      </Container>
    </FormWrapper>
  ) : (
    <Redirect to="/signup" />
  );
};

const Content = styled(GridColumn)`
  border: ${(props) =>
    props.showTicket
      ? "none"
      : `1px solid ${props.theme.palette.gray.lighter}`};
  border-radius: 8px;
  margin: ${(props) => (props.showTicket ? 0 : "40px 0 72px 0")};
  padding: ${(props) => (props.showTicket ? 0 : "40px")};
  ${Subheader} {
    margin-bottom: 4px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    margin: 0;
    padding: ${(props) => (props.showTicket ? "0 24px" : "0 24px 40px 24px")};
  }
`;

const Form = styled(GridColumn)`
  & > ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  ${Footnote} {
    margin-top: 8px;
    margin-bottom: 5px;
  }
`;

const AccountLabel = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-bottom: 4px;
`;
