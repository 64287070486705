import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import {
  ButtonJoin,
  ButtonTransparent,
  ButtonLink,
  TimeContext,
  ButtonSecondaryWhite,
  ModalContext,
} from "Components";
import { TicketContext } from "Providers";
import { fireAnalytics } from "services";
import { Date } from "../Date";
import { IconLabel } from "../IconLabel";
import {
  GridColumn,
  GridRow,
  Icon,
  H4,
  H2,
  H3,
  P,
  Logo,
  ButtonPrimary,
} from "notes";

export const Details = () => {
  const { upcomingTickets } = useContext(TicketContext);
  const { time } = useContext(TimeContext);
  const { displayModal } = useContext(ModalContext);
  const history = useHistory();

  const {
    id,
    artist,
    title,
    description,
    purchased,
    requests,
    type,
    start,
    preshowTransitionMinutes,
    url,
    assets,
  } = upcomingTickets[0] ?? {};

  const live = time > start;
  const tomorrow = time > start.minus({ hours: 36 });
  const startCountdown = time > start.startOf("day");
  const doorsOpen =
    time > start.minus({ minutes: preshowTransitionMinutes || 60 });

  const openLink = (url) => {
    fireAnalytics("click", {
      event_category: "set-the-set",
      event_label: artist,
    });
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleEventClick = () => {
    fireAnalytics("select_content", {
      content_type: "event",
      content_id: id,
      items: [
        {
          id: id,
          name: title,
          brand: null,
          category: "event",
          variant: artist,
          list_name: artist,
          list_position: 1,
          quantity: 1,
          price: 0,
        },
      ],
    });
    if (url) {
      window.location = url;
    } else {
      history.push(`/event/${id}`);
    }
  };

  const handlePurchaseClick = () => {
    fireAnalytics("click", {
      event_category: "purchase-intent",
      event_label: artist,
    });

    if (url) {
      window.location = url;
    } else {
      localStorage.setItem("eventId", id);
      history.push("/purchase");
    }
  };

  const showRequests = !startCountdown && !!requests && type !== "replay";

  return (
    <Container>
      <IconLabel
        stage={{ tomorrow, startCountdown, doorsOpen, live }}
        type={type}
      />
      <Margin>
        {type === "replay" ? (
          <GridRow yCenter>
            <ActionText>Replay available</ActionText>
            <ActionLink>
              Watch Now
              <Icon tertiary name="Next" />
            </ActionLink>
          </GridRow>
        ) : (
          <Date
            onClick={handleEventClick}
            {...{ tomorrow, startCountdown, doorsOpen, live }}
          />
        )}
      </Margin>
      <Title>{title}</Title>
      <Divider />
      <Artist>{artist}</Artist>
      <Description>{description}</Description>
      <LearnMore onClick={handleEventClick}>
        <Icon indicator name="Information" />
        <H4>Learn More</H4>
      </LearnMore>
      <Actions>
        {type === "replay" ? (
          <Primary stretch>
            <Icon tertiary name="Refresh" /> Watch Replay
          </Primary>
        ) : purchased ? (
          <ButtonJoin active={doorsOpen} onClick={handleEventClick} />
        ) : (
          <Primary onClick={handlePurchaseClick}>
            <Icon tertiary name="Ticket2" /> Get Tickets
          </Primary>
        )}
        {!!assets?.videoBanner?.hls && (
          <TrailerButton
            iconLeft={<Icon tertiary name="Video" />}
            onClick={() =>
              displayModal({
                videoModal: { path: assets?.videoBanner?.hls, autoplay: true },
              })
            }
          >
            {" "}
            Watch Trailer
          </TrailerButton>
        )}
        {!!showRequests && (
          <RequestsButton onClick={() => openLink(requests)}>
            <Logo name="SetTheSetReversed" />
            Accepting Requests
          </RequestsButton>
        )}
      </Actions>
    </Container>
  );
};

const Container = styled(GridColumn)`
  align-items: flex-start;
  z-index: 2;
`;

const Title = styled(H2)`
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
  max-width: 392px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 25px;
    line-height: 32px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 2.45vw;
    line-height: 3.07vw;
    max-width: 30.06vw;
  }
`;

const Divider = styled.div`
  background: ${(props) => props.theme.palette.orange.primary};
  width: 288px;
  height: 4px;
  margin-bottom: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 156px;
    height: 2px;
    margin-bottom: 8px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 22.09vw;
    height: 0.31vw;
    margin-bottom: 1.23vw;
  }
`;

const Artist = styled(H2)`
  color: #fff;
  margin-bottom: 8px;
  text-transform: none;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 4px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 2.45vw;
    line-height: 2.9vw;
  }
`;

const Description = styled(P)`
  color: #fff;
  width: 100%;
  max-width: 392px;
  margin-bottom: 8px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 12px;
    line-height: 15px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.3vw;
    line-height: 1.69vw;
    max-width: 30.06vw;
  }
`;

const LearnMore = styled(ButtonLink)`
  align-items: center;
  cursor: pointer;
  padding: 0;
  svg {
    margin-right: 8px;
    path {
      fill: #fff;
    }
  }
  ${H4} {
    color: #fff;
    font-weight: 600;
  }
  &:hover {
    ${H4} {
      text-decoration: underline;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    svg {
      width: 14px;
      height: 14px;
    }
    ${H4} {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    svg {
      width: 1.23vw;
      height: 1.23vw;
    }
    ${H4} {
      font-size: 1.3vw;
      line-height: 1.69vw;
    }
  }
`;

const Actions = styled(GridRow)`
  margin-top: 24px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and ${(props) => props.theme.media.xSmall} {
    align-items: stretch;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-top: 1.04vw;
  }
`;

const Primary = styled(ButtonPrimary)`
  svg {
    margin-right: 8px;
    path {
      fill: #fff;
    }
  }
  @media only screen and ${(props) => props.theme.media.xSmall} {
    min-width: initial;
    width: 100%;
    max-width: 100%;
  }
`;

const TrailerButton = styled(ButtonSecondaryWhite)`
  margin-left: 24px;
  white-space: nowrap;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 0;
    margin-top: 8px;
  }
`;

const RequestsButton = styled(ButtonTransparent)`
  margin-left: 24px;
  white-space: nowrap;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: #fff;
    }
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-left: 0;
    margin-top: 24px;
    background-color: transparent;
    height: initial;
    font-size: 12px;
    line-height: 15px;
    padding: 0;
  }
  @media only screen and ${(props) => props.theme.media.xSmall} {
    width: 100%;
    max-width: 100%;
  }
`;

const Margin = styled(GridRow)`
  margin-bottom: 12px;
  @media only screen and ${(props) => props.theme.media.medium} {
    margin-bottom: 0.92vw;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    margin-bottom: 8px;
  }
`;

export const ActionText = styled(H3)`
  color: #fff;
  font-weight: 400;
  line-height: 44px;
  margin-right: 12px;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 17px;
    line-height: 22px;
    margin-right: 8px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.92vw;
    line-height: 3.37vw;
    margin-right: 0.92vw;
  }
`;

export const ActionLink = styled(H3)`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.overpass};
  font-weight: 900;
  line-height: 44px;
  text-transform: uppercase;
  svg {
    margin-left: 8px;
    path {
      fill: #ffffff;
    }
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: 17px;
    line-height: 22px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.92vw;
    line-height: 3.37vw;
    svg {
      width: 1.5vw;
      height: 1.5vw;
      margin-left: 0.61vw;
    }
  }
`;
