import React, { useState } from "react";
import Joi from "@hapi/joi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridColumn,
  Input,
  Icon,
  ButtonPrimary,
  ButtonSecondary,
  Label,
  Footnote,
  Subheader,
  H3,
  P,
} from "notes";
import {
  FormWrapper,
  FormContainer,
  LoaderInline,
  FieldMessage,
} from "Components";
import { validate, Api } from "modules";

export const UpdateEmail = ({ location: { state }, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState("");
  const history = useHistory();

  const handleSubmit = () => {
    const isError = validate(
      { email: value },
      Joi.object({
        email: Joi.string().email({ tlds: false }).required(),
      })
    );
    if (!isError) {
      setLoading(true);
      Api.changeEmail({ action: "verify", email: value })
        .then(({ data }) => {
          if (data.success) {
            history.push("/account/confirm/email", {
              ...state,
              newEmail: value,
              verification: data.verification,
            });
          }
        })
        .catch((error) => {
          setErrors({ email: error.message });
          setLoading(false);
        });
    } else {
      setLoading(false);
      setErrors({
        email: "This must be a valid email and cannot be left blank.",
      });
    }
  };

  return (
    <FormWrapper hideFooter>
      <Container stretch>
        <FormContainer stretch>
          <Content>
            <Subheader>Step 1 of 2</Subheader>
            <H3 style={{ marginBottom: "24px" }}>Update Account Information</H3>
            {/* <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              sed semper justo. Suspendisse lacinia rutrum maximus. Donec varius
              ullamcorper nisl ut dictum. Aliquam mi.
            </P> */}
            {!!state?.email && (
              <>
                <AccountLabel>Original email address</AccountLabel>
                <P>{state?.email}</P>
              </>
            )}
            <Form>
              <Label>New email address</Label>
              <Input
                leftIcon={<Icon form name="Email" />}
                placeholder="john@email.com"
                onChange={(value) => {
                  setValue(value);
                  setErrors("");
                }}
                value={value}
                error={!!errors?.email}
              />
              <FieldMessage active={!!errors?.email} content={errors?.email} />
              <Actions>
                <ButtonPrimary
                  style={{ marginBottom: "8px" }}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? <LoaderInline /> : "Send Verification Code"}
                </ButtonPrimary>
                <ButtonSecondary onClick={() => history.goBack()}>
                  Cancel
                </ButtonSecondary>
              </Actions>
            </Form>
          </Content>
        </FormContainer>
      </Container>
    </FormWrapper>
  );
};

const Container = styled(GridColumn)`
  padding-top: 40px;
  width: 100%;
`;

const Content = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  margin: 40px 0 72px 0;
  padding: 40px;
  position: relative;
  width: 100%;
  max-width: 472px;
  ${Subheader} {
    margin-bottom: 4px;
  }

  ${(props) =>
    props.showTicket &&
    `
    border: none;
    padding: 0;
    margin: 0;
  `};

  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: ${(props) =>
      props.showTicket ? "0px 24px 104px 24px" : "40px 24px 104px 24px"};
  }
`;

const AccountLabel = styled(Label)`
  color: ${(props) => props.theme.palette.gray.lighter};
  margin-bottom: 4px;
`;

const Form = styled(GridColumn)`
  & > ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  ${Footnote} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-style: normal;
    margin-top: 8px;
    margin-bottom: 5px;
  }
`;

const Actions = styled(GridColumn)`
  margin-top: 40px;
  justify-content: space-between;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    position: absolute;
    left: 0;
    bottom: 24px;
    padding: 0 24px;
    button {
      min-width: 156px;
    }
  }
`;
