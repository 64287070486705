import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "notes";
import { SignUp, ModalContext, StoreContext } from "Components";
import { formatName } from "modules";
import { fireAnalytics } from "services";

export const SignUpModal = ({ open, setOpen }) => {
  const { toggleLoginModal } = useContext(ModalContext);
  const { Store } = useContext(StoreContext);
  const history = useHistory();

  const handleOAuth = (success, method) => {
    let name = formatName(success.user.displayName);
    Store.firestore().collection("profiles").doc(success.user.uid).set(name);
    if (!!success) {
      setOpen(false);
      fireAnalytics("sign_up", { method });
    }
  };

  const handleManual = (success) => {
    setOpen(false);
    if (!!success) {
      history.push("/requestverification");
      fireAnalytics("sign_up", { method: "Email" });
    }
  };

  return (
    <ModalStyle size="small" open={open} setOpen={setOpen}>
      <SignUp
        onOAuth={handleOAuth}
        onManual={handleManual}
        onLoginClick={() => {
          setOpen(false);
          toggleLoginModal(true);
        }}
      />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin: 5.521vw 0;
  min-height: 830px;
  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: 0;
  }
`;
